import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import Popover from 'react-popover';
import { CompanyPropType } from './../common/proptypes';

import CompanyLink from '../companies/CompanyLink';
import { getCompanies } from '../selectors';
import { createCompany } from '../actions';
import Card from '../components/Card';
import ActionLink from '../components/ActionLink';
import TextField from '../components/formfields/TextField';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';

const CompaniesPage = ({ companies, doCreateCompany, history }) => {
  const [showPop, setShowPop] = useState(false);

  const submit = (values) => {
    const { companyName } = values;
    if (companyName) {
      doCreateCompany({ name: companyName, browserHistory: history });
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Card
            title="Admin.Companies"
            fullWidth
            action={
              <Popover
                isOpen={showPop}
                onOuterAction={() => setShowPop(false)}
                place="below"
                body={
                  <Card>
                    <Form onSubmit={submit}>
                      {({ handleSubmit }) => (
                        <>
                          <Field
                            component={TextField}
                            name="companyName"
                            placeholder="Account.EnterCompanyName"
                            label="Account.CompanyName"
                          />
                          <button type="button" onClick={handleSubmit}>
                            <FormattedMessage id="Forms.Submit" />
                          </button>
                        </>
                      )}
                    </Form>
                  </Card>
                }
              >
                <ActionLink onClick={() => setShowPop(true)}>
                  <FormattedMessage id="Company.NewCompany" />
                </ActionLink>
              </Popover>
            }
          >
            {companies && (
              <React.Fragment>
                {companies.map((company) => (
                  <CompanyLink key={company._id} company={company} />
                ))}
              </React.Fragment>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

CompaniesPage.propTypes = {
  companies: PropTypes.arrayOf(CompanyPropType).isRequired,
  doCreateCompany: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  companies: getCompanies(state),
});

const actionsToProps = {
  doCreateCompany: createCompany,
};

export default connect(stateToProps, actionsToProps)(CompaniesPage);
