import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import Json from 'react-json-view';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getLanguageCouples,
  getSettings,
  getNewLanguageCouples,
  getSpecialities,
  getNewSpecialities,
  getProfile,
} from '../selectors';
import {
  loadSettings,
  createLanguageCouple,
  createSpeciality,
  createSettings,
  updateSettings,
} from '../actions';
import Card from '../components/Card';
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { CoupleFactorList } from '../components/CoupleFactorList';
import SpecialitiesList from '../components/SpecialitiesList';
import UpdateSettings from '../components/UpdateSettings';

const AppSettings = ({
  settings,
  doLoadSettings,
  doCreateSettings,
  doUpdateSettings,
  languageCouples,
  doCreateLanguageCouple,
  specialities,
  doCreateSpeciality,
  adminLang,
  settingsSpecialities,
}) => {
  useEffect(() => {
    doLoadSettings();
  }, [doLoadSettings]);
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState('5');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [choice, setChoice] = useState('specialities');
  const compare = (a, b) => {
    if (a.couple < b.couple) {
      return -1;
    }
    if (a.couple > b.couple) {
      return 1;
    }
    return 0;
  };

  const handleCreateLanguageCouple = (couple) => {
    doCreateLanguageCouple(couple);
  };

  /**
   * create id for new speciality, create or update in settings collection,
   * create speciality in specialities collection for factor history
   * @param {Object} speciality
   */
  const handleCreateSpeciality = (speciality) => {
    const newKey = new Date().getTime().toString();
    const speExist =
      settingsSpecialities &&
      settingsSpecialities.value.find((spe) => spe.key === speciality.key);

    const speForSettings = {
      key: speExist ? speciality.key : newKey,
      speciality: {
        fr: speciality.speciality.fr,
        en: speciality.speciality.en,
      },
      status: 'VALIDATED',
    };

    const speForFactor = {
      key: speExist ? speciality.key : newKey,
      factor: speciality.factor,
    };

    if (!settingsSpecialities) {
      doCreateSettings({
        key: 'specialities',
        description: 'List of all specialities',
        value: [speForSettings],
      });
    } else {
      doUpdateSettings({
        item: settingsSpecialities,
        data: speForSettings,
        type: 'array',
      });
    }
    if (
      specialities.find((spe) => spe.key === speciality.key)?.factor !==
      speForFactor.factor
    ) {
      doCreateSpeciality(speForFactor);
    }
  };

  const handleUpdateSettingsBack = (mySettings) => {
    let updateSettings = false;
    mySettings.map((item) => {
      if (!Array.isArray(item)) {
        if (item.value !== settings.find((set) => set.key === item.key).value) {
          updateSettings = true;
          doUpdateSettings({
            item: item,
            type: 'simple',
          });
        } else {
          updateSettings = true;
          doUpdateSettings({
            item: item,
            type: 'simple',
          });
        }
      }
    });

    if (updateSettings) {
      toast.success(intl.formatMessage({ id: 'Admin.Toast.Update' }));
    } else {
      toast.success(intl.formatMessage({ id: 'Admin.Toast.NoUpdate' }));
    }
  };

  /**
   * update settings with status REMOVED for the speciality
   * @param {Object} speciality
   */
  const handleRemoveSpeciality = (speciality) => {
    const settingSpe = settingsSpecialities.value.find(
      (spe) => spe.key === speciality.key
    );
    const speForSettings = {
      key: speciality.key,
      speciality: {
        fr: settingSpe.speciality.fr,
        en: settingSpe.speciality.en,
      },
      status: speciality.status,
    };
    doUpdateSettings({
      item: settingsSpecialities,
      data: speForSettings,
      type: 'array',
    });
  };

  return (
    <Container>
      <ToastContainer />
      <Card title="Admin.Settings">
        <Row>
          <Col>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: choice === 'languagecouples',
                  })}
                  onClick={() => {
                    toggle('1');
                    setChoice('languagecouples');
                  }}
                >
                  <FormattedMessage id="Admin.Languages" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: choice === 'specialities' })}
                  onClick={() => {
                    toggle('2');
                    setChoice('specialities');
                  }}
                >
                  <FormattedMessage id="Admin.Specialities" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: choice === 'translation' })}
                  onClick={() => {
                    toggle('3');
                    setChoice('translation');
                  }}
                >
                  <FormattedMessage id="Admin.Settings.Translation" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: choice === 'proofreading' })}
                  onClick={() => {
                    toggle('4');
                    setChoice('proofreading');
                  }}
                >
                  <FormattedMessage id="Admin.Settings.Proofreading" />
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: choice === 'priceBreaks' })}
                  onClick={() => {
                    toggle('5');
                    setChoice('priceBreaks');
                  }}
                >
                  <FormattedMessage id="Admin.Settings.PriceBreak" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: choice === 'priceBreaksAskProofreading' })}
                  onClick={() => {
                    toggle('5');
                    setChoice('priceBreaksAskProofreading');
                  }}
                >
                  <FormattedMessage id="Admin.Settings.PriceBreaksAskProofreading" />
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: choice === 'settings' })}
                  onClick={() => {
                    toggle('6');
                    setChoice('settings');
                  }}
                >
                  <FormattedMessage id="Settings" />
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <Card>
                <TabPane tabId="1">
                  <Row>
                    <Col>
                      {choice === 'languagecouples' && (
                        <CoupleFactorList
                          languageCouples={languageCouples.sort(compare)}
                          onCreate={handleCreateLanguageCouple}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col>
                      {choice === 'specialities' && (
                        <SpecialitiesList
                          specialities={specialities}
                          settingsSpecialities={settings
                            .find((setting) => setting.key === 'specialities')
                            ?.value.filter((spe) => spe.status === 'VALIDATED')}
                          adminLang={adminLang}
                          handeCreateSpe={handleCreateSpeciality}
                          handleRemoveSpe={handleRemoveSpeciality}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col>
                      {choice === 'translation' && (
                        <UpdateSettings
                          settingsType="single"
                          settingsToUpdate={[
                            {
                              ...settings.find(
                                (item) => item.key === 'basePrice'
                              ),
                              type: 'number',
                            },
                            {
                              ...settings.find(
                                (item) => item.key === 'extendBy'
                              ),
                              type: 'number',
                            },
                            {
                              ...settings.find(
                                (item) => item.key === 'searchTime'
                              ),
                              type: 'number',
                            },
                            {
                              ...settings.find(
                                (item) => item.key === 'minFastPrice'
                              ),
                              type: 'number',
                            },
                            {
                              ...settings.find(
                                (item) => item.key === 'minSlowPrice'
                              ),
                              type: 'number',
                            },
                          ]}
                          handleUpdateSettings={handleUpdateSettingsBack}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col>
                      {choice === 'proofreading' && (
                        <UpdateSettings
                          settingsType="single"
                          settingsToUpdate={[
                            {
                              ...settings.find(
                                (item) => item.key === 'proofreadingBasePrice'
                              ),
                              type: 'number',
                            },
                            {
                              ...settings.find(
                                (item) => item.key === 'proofreadingExtendBy'
                              ),
                              type: 'number',
                            },
                            {
                              ...settings.find(
                                (item) => item.key === 'proofreadingSearchTime'
                              ),
                              type: 'number',
                            },
                            {
                              ...settings.find(
                                (item) =>
                                  item.key === 'proofreadingMinFastPrice'
                              ),
                              type: 'number',
                            },
                            {
                              ...settings.find(
                                (item) =>
                                  item.key === 'proofreadingMinSlowPrice'
                              ),
                              type: 'number',
                            },
                            {
                              ...settings.find(
                                (item) => item.key === 'proofreadingVSTranslationDelay'
                              ),
                              type: 'number',
                            },
                          ]}
                          handleUpdateSettings={handleUpdateSettingsBack}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="5">
                  <Row>
                    <Col>
                      {choice === 'priceBreaks' && (
                        <UpdateSettings
                          settingsType="arrayOfObjects"
                          settingsToUpdate={[
                            {
                              ...settings.find(
                                (item) => item.key === 'priceBreaks'
                              ),
                              type: 'object',
                            },
                          ]}
                          handleUpdateSettings={handleUpdateSettingsBack}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="6">
                  <Row>
                    <Col>
                      {choice === 'priceBreaksAskProofreading' && (
                        <UpdateSettings
                          settingsType="arrayOfObjects"
                          settingsToUpdate={[
                            {
                              ...settings.find(
                                (item) => item.key === 'priceBreaksAskProofreading'
                              ),
                              type: 'object',
                            },
                          ]}
                          handleUpdateSettings={handleUpdateSettingsBack}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="7">
                  <Row>
                    <Col>
                      {choice === 'settings' && <Json src={settings} />}
                    </Col>
                  </Row>
                </TabPane>
              </Card>
            </TabContent>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

AppSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  settings: PropTypes.array.isRequired,
  languageCouples: PropTypes.array.isRequired,
  doLoadSettings: PropTypes.func.isRequired,
  doCreateLanguageCouple: PropTypes.func.isRequired,
  doCreateSpeciality: PropTypes.func.isRequired,
  doUpdateSettings: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  settingsSpecialities:
    state.settings.data?.key === 'speciality'
      ? getSettings(state).data.value
      : getSettings(state).find((setting) => setting.key === 'specialities'),
  settings: getSettings(state),
  languageCouples: state.languagecouples.data
    ? getNewLanguageCouples(state)
    : getLanguageCouples(state),
  specialities: state.specialities.data
    ? getNewSpecialities(state)
    : getSpecialities(state),
  adminLang: getProfile(state).lang,
});

const actionsToProps = (dispatch) => ({
  doLoadSettings: (query = {}) => dispatch(loadSettings(query)),
  doCreateSettings: (data) => dispatch(createSettings(data)),
  doUpdateSettings: (data) => dispatch(updateSettings(data)),
  doCreateLanguageCouple: (data) => dispatch(createLanguageCouple(data)),
  doCreateSpeciality: (data) => dispatch(createSpeciality(data)),
});

export default connect(stateToProps, actionsToProps)(AppSettings);
