import { find, filter, get, some } from "lodash";
import { getHasUnreadMessages } from "../chat/selectors";
export const getProfile = state => get(state, "auth.user", null);

export const findAccountById = (state, _id) =>
  find(get(state, "users.store.records", []), { _id });

export const ordersForAccount = (state, account) => {
  if (account) {
    const searchField = account.role === "client" ? "user" : "translator";
    return filter(get(state, "orders.store.records", []), [
      searchField,
      account._id
    ]);
  }
  return [];
};

/**
 *
 * Checks to see if any of the users with a given role have unread messages.
 * Used to display a notification dot in the admin menu
 *
 * @param {object} state the redux state object
 * @param {string} role either "client" or "translator"
 */
export const roleHasUnreadMessages = (state, role) => {
  const usersWithRole = filter(get(state, "users.store.records", []), { role });
  return some(usersWithRole, user => getHasUnreadMessages(state, user._id));
};
