/**
 * Created by Al on 30/03/2017.
 *
 * These sagas describe actions that take more than just one step to perform a function
 *
 * for example, after a login, user details must be fetched
 * or after submitting a form, details must be extracted and sent via the api followed by a redirect
 */
import jwt from 'jsonwebtoken';
import moment from 'moment-timezone';
import { updateIntl } from 'react-intl-redux';
import {
  all,
  call,
  delay,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  CANCEL_ORDER,
  CLEAR_OFFERS,
  CLEAR_REJECTIONS,
  CONVERT_TO_TRANSLATOR,
  CREATE_COMPANY,
  CREATE_COMPANY_FULFILLED,
  CREATE_LANGUAGE_COUPLE,
  CREATE_ORDER,
  CREATE_ORDER_FULFILLED,
  CREATE_ORDER_RECEIVED,
  CREATE_SETTINGS,
  CREATE_SPECIALITY,
  GET_COMPANY,
  GET_ORDER,
  GET_ORDER_FULFILLED,
  LOAD_COMPANIES,
  LOAD_LANGUAGE_COUPLES,
  LOAD_SPECIALITIES,
  LOAD_TRANSLATORS,
  RELOAD_ALL_ORDERS_AND_INVOICES,
  REMOVE_ORDER_RECEIVED,
  SET_TRANSLATOR_AVAILABLE,
  SET_TRANSLATOR_LANGUAGES,
  SET_TRANSLATOR_PROOFREADING,
  SUBMIT_INVOICE,
  UPDATE_COMPANY,
  UPDATE_COMPANY_FULFILLED,
  UPDATE_SETTINGS,
} from './actions';
import lang from './common/lang';
import {
  AUTH_FULFILLED,
  LOAD_ORDERS,
  LOAD_SETTINGS,
  LOGOUT,
  NOTIFY_VIEWING_CHAT,
  REMOVE_FILE,
  REMOVE_ORDER,
  UPDATE_ORDER,
  UPDATE_USER,
  UPDATE_USER_FULFILLED,
} from './common/redux/actions';
import * as settings from './constants';
import cookie from './cookie';
import {
  connect,
  connectOne,
  disconnect,
  feathersAuthentication,
  feathersServices,
} from './feathers';
import { getOrderPagination } from './orders/selectors';
import { getProfile } from './selectors';
import paramsForServer from './utils/params-for-server';

function* login() {
  try {
    const profile = yield select(getProfile);
    if (profile.role === 'admin') {
      yield call(updateLocale, { payload: profile.lang });
      yield connect();
    } else {
      yield call(logout);
    }
  } catch (e) {
    // console.log(e)
  }
}

function* updateLocale(data) {
  try {
    const locale = data.payload;
    const { format } = settings.localeFormatTweaks[locale] || undefined;
    moment.locale(locale, format);
    yield put(updateIntl({ locale, messages: lang[locale] }));
  } catch (e) {
    // console.log(e)
  }
}

function* loadOrders(data) {
  try {
    const query = data.payload;
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
    yield put(
      feathersServices.orders.find({
        query: {
          status: ['Order.Status.Unassigned', 'Order.Status.InProgress'],
          ...query,
        },
      })
    );
  } catch (e) {
    console.log(e);
  }
}

function* loadLanguageCouples() {
  try {
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
    yield put(feathersServices.languagescouples.find());
  } catch (e) {
    //console.log(e);
  }
}

function* createLanguageCouple(data) {
  try {
    const couple = data.payload;
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
    yield put(feathersServices.languagecouples.create(couple));
  } catch (e) {
    // console.log(e)
  }
}

function* loadSpecialities() {
  try {
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
    yield put(feathersServices.specialities.find());
  } catch (e) {
    //console.log(e);
  }
}

function* createSpeciality(data) {
  try {
    const speciality = data.payload;
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
    yield put(feathersServices.specialities.create(speciality));
  } catch (e) {
    // console.log(e)
  }
}

function* createCompany(data) {
  try {
    const { name, browserHistory } = data.payload;
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
    yield put(feathersServices.companies.create({ name }));
    const result = yield take(CREATE_COMPANY_FULFILLED);
    yield call(browserHistory.push, `/companies/${result.payload._id}`);
  } catch (e) {
    // console.log(e)
  }
}

function* loadCompanies(data) {
  try {
    const query = data.payload;
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
    yield put(
      feathersServices.companies.find({
        query: {
          ...query,
        },
      })
    );
  } catch (e) {
    // console.log(e)
  }
}

function* reloadOrders() {
  try {
    yield delay(500); // debounce
    const query = yield select(getOrderPagination);
    yield put(
      feathersServices.orders.find({
        query: {
          $skip: query.skip,
        },
      })
    );
  } catch (e) {
    // console.log(e)
  }
}

/**
 * Reload All Orders : on recharge le store settings et le store orders.
 * Pas trouvé mieux pour recharger après modif du param sur la selection des orders
 *
 */
function* reloadAllOrdersAndInvoices(data) {
  try {
    yield connectOne('settings'); // reload store settings
    yield delay(500);
    yield connectOne('orders'); // reload store orders
    yield connectOne('invoices'); // reload store invoices
    // const query = data.payload;
    // yield put(
    //   feathersServices.orders.find({
    //     query: query,
    //   })
    // );
  } catch (e) {
    console.log(e);
  }
}

function* getCompany(data) {
  try {
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
    yield put(feathersServices.companies.get(data.payload));
  } catch (e) {
    // console.log(e)
  }
}

function* createQuote(data) {
  const { history } = data.payload;
  const profile = yield select(getProfile);
  if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
  yield put(
    feathersServices.orders.create({ status: 'Order.Status.DraftQuote' })
  );
  const { payload } = yield take(CREATE_ORDER_FULFILLED);
  yield call(history.push, `/orders/${payload._id}`);
}

function* getOrder(data) {
  try {
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
    if (data.payload === 'new') {
      yield put({
        type: GET_ORDER_FULFILLED,
        payload: {
          _id: 'new',
        },
      });
    } else {
      yield put(feathersServices.orders.get(data.payload));
    }
  } catch (e) {
    // console.log(e)
  }
}

function* updateOrder(data) {
  try {
    yield put(feathersServices.orders.patch(data.payload._id, data.payload));
  } catch (e) {
    // console.log({ e });
  }
}

function* updateCompany(data) {
  try {
    const { company, browserHistory, redirect } = data.payload;

    yield put(feathersServices.companies.update(company._id, company));
    yield take(UPDATE_COMPANY_FULFILLED);
    if (redirect) {
      yield call(browserHistory.push, '/companies');
    }
  } catch (e) {
    // console.log({ e });
  }
}

function* logout() {
  try {
    yield feathersAuthentication.logout();

    const token = yield cookie.getItem('feathers-jwt');
    if (token) {
      const decoded = jwt.decode(token);
      if (decoded.role === 'admin') {
        cookie.removeItem('feathers-jwt');
      }
    }

    yield disconnect();
    yield localStorage.clear();
    window.location.replace(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${process.env.REACT_APP_ADMIN_URL}&&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`
    );
  } catch (e) {
    // console.log({ e });
  }
}

function* loadTranslators() {
  try {
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED);
    yield put(feathersServices.translators.find({ query: { online: true } }));
  } catch (e) {
    // console.log({ e });
  }
}

function* loadSettings() {
  try {
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED);
    yield put(feathersServices.settings.find());
  } catch (e) {
    // console.log({ e });
  }
}

function* createSettings({ payload }) {
  const newSetting = payload;
  try {
    const profile = yield select(getProfile);
    if (!profile) yield take(AUTH_FULFILLED);
    yield put(feathersServices.settings.create(newSetting));
  } catch (e) {
    // console.log({ e });
  }
}

/**
 * 2 possibilities for updating :
 *  update item of an array of object in a parameter if item.type === array
 *  update simple parameter (description, value),  if item.type === other
 */
function* updateSettings({ payload }) {
  let index = 0;
  // If update a value of array of objects :
  if (payload.type === 'array') {
    index = payload.item.value.findIndex((spe) => spe.key === payload.data.key);

    try {
      const profile = yield select(getProfile);
      if (!profile) yield take(AUTH_FULFILLED);
      if (index === -1) {
        yield put(
          feathersServices.settings.patch(payload.item._id, {
            value: payload.item.value.concat([payload.data]),
          })
        );
      } else {
        payload.item.value[index] = payload.data;
        yield put(
          feathersServices.settings.patch(payload.item._id, {
            value: payload.item.value,
          })
        );
      }
    } catch (e) {
      // console.log({ e });
    }
  } else {
    // If update a simple value :
    try {
      const profile = yield select(getProfile);
      if (!profile) yield take(AUTH_FULFILLED);
      if (payload.data) {
        payload.item.value = payload.data;
      }
      yield put(
        feathersServices.settings.patch(payload.item._id, {
          value: payload.item.value,
        })
      );
    } catch (e) {
      console.log({ e });
    }
  }
}

function* clearRejections(data) {
  try {
    const order = Object.assign({}, data.payload, {
      $set: { rejected: [] },
      rejected: [],
      rejectionsCleared: true,
      updateRequired: new Date(),
    });

    yield put(feathersServices.orders.patch(order._id, order));
  } catch (e) {
    // console.log({ e });
  }
}

function* clearOffers(data) {
  try {
    const order = Object.assign({}, data.payload, {
      $set: { offeredTo: [] },
      offeredTo: [],
      offersCleared: true,
      updateRequired: null,
    });

    yield put(feathersServices.orders.patch(order._id, order));
  } catch (e) {
    // console.log({ e });
  }
}

function* cancelOrder(data) {
  try {
    const { orderId, browserHistory } = data.payload;
    yield put(
      feathersServices.orders.update(
        orderId,
        {},
        paramsForServer({ action: CANCEL_ORDER })
      )
    );
    yield call(browserHistory.push, '/');
  } catch (e) {
    // console.log({ e });
  }
}

function* submitInvoice(data) {
  try {
    const invoice = data.payload;

    yield put(
      feathersServices.invoices.patch(invoice._id, { status: 'Sending' })
    );
  } catch (e) {
    // console.log({ e });
  }
}

function* updateAvailable(data) {
  try {
    const { id, available } = data.payload;

    yield put(feathersServices.users.patch(id, { available }));
  } catch (e) {
    // console.log({ e });
  }
}

function* setLanguages(data) {
  try {
    const { id, canTranslate } = data.payload;
    yield put(feathersServices.users.patch(id, { canTranslate }));
  } catch (e) {
    // console.log({ e });
  }
}

function* setProofreading(data) {
  try {
    const { id, proofreading } = data.payload;
    yield put(feathersServices.users.patch(id, { proofreading }));
  } catch (e) {
    // console.log({ e });
  }
}

function* convertToTranslator(data) {
  const { id, browserHistory } = data.payload;
  yield put(feathersServices.users.patch(id, { role: 'translator' }));
  yield call(browserHistory.push, `/translators/${id}`);
}

function* notifyViewingChat(data) {
  const recipient = data.payload;
  const profile = yield select(getProfile);
  if (profile) {
    yield put(
      feathersServices.users.patch(profile._id, {
        currentlyChatting: { recipient },
      })
    );
  }
}

function* removeFile(data) {
  const { orderId, file } = data.payload;

  yield put(
    feathersServices.uploads.remove(file, paramsForServer({ orderId }))
  );
}

function* removeOrder(data) {
  const { orderId, history } = data.payload;
  yield put(feathersServices.orders.remove(orderId));
  yield call(history.push, '/orders');
}

function* updateUser(data) {
  const { user, browserHistory } = data.payload;
  yield put(feathersServices.users.update(user._id, user));
  yield take(UPDATE_USER_FULFILLED);
  browserHistory.goBack();
}

export default function* rootSaga() {
  yield all([
    takeEvery(AUTH_FULFILLED, login),
    takeEvery(LOGOUT, logout),
    takeEvery(LOAD_ORDERS, loadOrders),
    takeEvery(LOAD_COMPANIES, loadCompanies),
    takeEvery(CREATE_COMPANY, createCompany),
    takeEvery(LOAD_TRANSLATORS, loadTranslators),
    takeLatest(CREATE_ORDER_RECEIVED, reloadOrders),
    takeLatest(REMOVE_ORDER_RECEIVED, reloadOrders),
    takeLatest(RELOAD_ALL_ORDERS_AND_INVOICES, reloadAllOrdersAndInvoices),
    takeLatest(GET_COMPANY, getCompany),
    takeLatest(UPDATE_COMPANY, updateCompany),
    takeLatest(LOAD_SETTINGS, loadSettings),
    takeLatest(CREATE_SETTINGS, createSettings),
    takeLatest(UPDATE_SETTINGS, updateSettings),
    takeLatest(GET_ORDER, getOrder),
    takeLatest(CLEAR_REJECTIONS, clearRejections),
    takeLatest(CLEAR_OFFERS, clearOffers),
    takeLatest(UPDATE_ORDER, updateOrder),
    takeLatest(SUBMIT_INVOICE, submitInvoice),
    takeLatest(CANCEL_ORDER, cancelOrder),
    takeEvery(SET_TRANSLATOR_AVAILABLE, updateAvailable),
    takeEvery(SET_TRANSLATOR_LANGUAGES, setLanguages),
    takeEvery(SET_TRANSLATOR_PROOFREADING, setProofreading),
    takeEvery(CONVERT_TO_TRANSLATOR, convertToTranslator),
    takeEvery(NOTIFY_VIEWING_CHAT, notifyViewingChat),
    takeLatest(CREATE_ORDER, createQuote),
    takeEvery(REMOVE_FILE, removeFile),
    takeLatest(REMOVE_ORDER, removeOrder),
    takeLatest(UPDATE_USER, updateUser),
    takeLatest(LOAD_LANGUAGE_COUPLES, loadLanguageCouples),
    takeLatest(CREATE_LANGUAGE_COUPLE, createLanguageCouple),
    takeLatest(LOAD_SPECIALITIES, loadSpecialities),
    takeLatest(CREATE_SPECIALITY, createSpeciality),
  ]);
}
