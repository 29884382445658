import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Speciality.module.scss';
import classnames from 'classnames';
import { Row, Col } from 'reactstrap';
import ConfirmationTrigger from './ConfirmationTrigger';

const Speciality = ({
  speciality,
  openModal,
  removefromSpeciality,
  specialityName,
}) => {
  const removeSpe = () => {
    removefromSpeciality('remove', { ...speciality, status: 'REMOVED' });
  };
  return (
    <Row>
      <Col>
        <Row
          onClick={() => openModal()}
          className={classnames(styles.spe, {
            'mr-auto': true,
          })}
        >
          <Col>{specialityName}</Col>
          <Col>
            <FormattedMessage id="Admin.Settings.Factor" />
            <span> : {speciality.factor}</span>
          </Col>
        </Row>
      </Col>
      <Col>
        <ConfirmationTrigger
          title={<FormattedMessage id="Admin.Settings.removeSpeciality" />}
          text={<FormattedMessage id="Admin.Settings.AreYouSure" />}
          yes={<FormattedMessage id="Admin.Settings.Yes" />}
          no={<FormattedMessage id="Admin.Settings.No" />}
          actionYes={removeSpe}
        />
      </Col>
    </Row>
  );
};

export default Speciality;
