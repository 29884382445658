import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { Button, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { isEqual, uniqWith } from 'lodash';
import { Field, Form } from 'react-final-form';
import SelectField from '../components/formfields/SelectField';
import { OnChange } from 'react-final-form-listeners';

export const AddCoupleModal = ({
  account,
  languageListWebsite,
  modalIsOpen,
  closeModal,
  languageCouples,
  fromAddCoupleModal,
}) => {
  const [showFactorAdd, setShowFactorAdd] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    origin: null,
    destination: null,
  });
  const [coupleToUpdate, setCoupleToUpdate] = useState({
    couple: '',
    factor: 1,
  });

  const required = (value) =>
    value ? undefined : 'Account.LanguageForm.Error';

  /**
   *  Return all languages formatted list
   */
  const allLanguagesList = () => {
    const table = languageListWebsite
      .map((lang) => ({
        value: lang.key,
        text: lang.name,
      }))
      .sort((a, b) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));
    return uniqWith(table, isEqual);
  };

  /**
   * Return all languages list but origin/destination if chosen
   * and existing couples' origin/destination already in CanTranslate
   * @param {string} name
   */
  const allLanguagesFilteredList = (name) => {
    const already = {
      origin: account.canTranslate
        .filter(
          (couple) => couple.couple.split('-')[0] === selectedLanguage.origin
        )
        .map((couple) => couple.couple.split('-')[1]),
      destination: account.canTranslate
        .filter(
          (couple) =>
            couple.couple.split('-')[1] === selectedLanguage.destination
        )
        .map((couple) => couple.couple.split('-')[0]),
    };

    if (selectedLanguage.origin && selectedLanguage.destination) {
      const selectedCouple = `${selectedLanguage.origin}-${selectedLanguage.destination}`;
      const index = languageCouples.findIndex(
        (lang) => lang.couple === selectedCouple
      );
      if (index === -1) {
        setShowFactorAdd(true);
      } else {
        setShowFactorAdd(false);
      }
    }
    return allLanguagesList()
      .filter((lang) => lang.value !== selectedLanguage[`${name}`])
      .filter((lang) => !already[`${name}`].includes(lang.value));
  };

  /**
   * handle submit and pass validated couple (+/- factor)
   * re-init selectedlanguage
   */
  const chooseLanguageItem = () => {
    fromAddCoupleModal({
      couple: selectedLanguage.origin + '-' + selectedLanguage.destination,
      status: 'VALIDATED',
      coupleToUpdate: coupleToUpdate,
    });
    setSelectedLanguage({
      origin: null,
      destination: null,
    });
  };

  return (
    <Modal isOpen={modalIsOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        <FormattedMessage id="Admin.Translator.AddACouple" />
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={() => chooseLanguageItem()}>
          {({ handleSubmit, submitting, hasValidationErrors }) => (
            <form onSubmit={handleSubmit}>
              <OnChange name="origin">
                {(value) => {
                  setSelectedLanguage({
                    ...selectedLanguage,
                    origin: value,
                  });
                  if (!value) setShowFactorAdd(false);
                }}
              </OnChange>
              <Field
                component={SelectField}
                name="origin"
                validate={required}
                options={
                  selectedLanguage.destination
                    ? allLanguagesFilteredList('destination')
                    : allLanguagesList()
                }
                placeholder="Account.LanguageForm.OriginChoice"
              />
              <OnChange name="destination">
                {(value) => {
                  setSelectedLanguage({
                    ...selectedLanguage,
                    destination: value,
                  });
                  if (!value) setShowFactorAdd(false);
                }}
              </OnChange>
              <Field
                component={SelectField}
                name="destination"
                validate={required}
                options={
                  selectedLanguage.origin
                    ? allLanguagesFilteredList('origin')
                    : allLanguagesList()
                }
                placeholder="Account.LanguageForm.DestinationChoice"
              />
              {showFactorAdd && (
                <>
                  <Row>
                    <Col>
                      <h3>
                        <FormattedMessage id="Admin.Translators.NewLanguageCouple" />
                      </h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <p className="mt-3">
                        <FormattedMessage id="Admin.Translators.AddFactor" />
                      </p>
                    </Col>
                    <Col className="my-3">
                      <input
                        type="number"
                        step="0.1"
                        min="0"
                        value={coupleToUpdate.factor}
                        className="form-control"
                        onChange={(e) =>
                          setCoupleToUpdate({
                            couple: `${selectedLanguage.origin}-${selectedLanguage.destination}`,
                            factor: e.target.value,
                          })
                        }
                      ></input>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="justify-content-center">
                <Button
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                >
                  <FormattedMessage id="Forms.Submit" />
                </Button>
              </Row>
            </form>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};
