import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import OrderLink from './OrderLink';
import { createOrder } from '../actions';
import { getQuotedOrders } from './selectors';
import Card from '../components/Card';
import { useHistory } from 'react-router-dom';
import ActionLink from '../components/ActionLink';

const OrdersPageSubpageQuoted = ({ quotedOrders }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const createNew = () => dispatch(createOrder({ history }));
  return (
    <Card
      title="Admin.Quotes"
      fullWidth
      action={
        <ActionLink onClick={createNew}>
          <FormattedMessage id="Admin.Quote.New" />
        </ActionLink>
      }
    >
      {quotedOrders &&
        quotedOrders.map((order) => (
          <OrderLink key={order._id} order={order} />
        ))}
    </Card>
  );
};

OrdersPageSubpageQuoted.propTypes = {
  quotedOrders: PropTypes.array,
};

OrdersPageSubpageQuoted.defaultProps = {
  quotedOrders: null,
};

const stateToProps = (state) => ({
  quotedOrders: getQuotedOrders(state),
});

export default connect(stateToProps)(OrdersPageSubpageQuoted);
