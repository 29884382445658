import React from "react";
import { FormattedMessage } from "react-intl";
import { useRouteMatch, Link } from "react-router-dom";
import styles from "./ProfileView.module.scss";
import Flag from "../components/Flag";
import StarRating from "../components/StarRating";
import { Button } from "reactstrap";

const ProfileView = ({ profile, editable = null }) => {
  const { url } = useRouteMatch();
  return (
    <div className={styles.Container}>
      <img
        className={styles.Picture}
        width={100}
        src={profile.picture}
        alt={profile.givenName}
      />
      {editable && (
        <p className={styles.Gravatar}>
          <FormattedMessage
            id="Account.Gravatar"
            values={{
              linkToGravatar: (
                <a
                  href="https://gravatar.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gravatar
                </a>
              ),
            }}
          />
        </p>
      )}
      <div className={styles.NameNationality}>
        <p className={styles.Name}>
          {profile.givenName} {profile.familyName}
        </p>
        {profile.nationality && profile.nationality.length === 2 && (
          <Flag
            country={profile.nationality}
            className={styles.Nationality}
            includeText={false}
          />
        )}
        {profile.dualNationality && (
          <Flag
            country={profile.dualNationality}
            className={styles.Nationality}
            includeText={false}
          />
        )}
      </div>
      <p>{profile.accountId}</p>
      <p>
        <a href={`mailto:${profile.email}`}>{profile.email}</a>
      </p>
      {profile.role === "translator" && (
        <>
          <StarRating stars={profile.averageRating} />
          <div className={styles.PublicDetails}>
            <dl>
              {profile.residence && (
                <>
                  <dt>
                    <FormattedMessage id="Account.Residence" />
                  </dt>
                  <dd>
                    <FormattedMessage id={`Country.${profile.residence}`} />
                  </dd>
                </>
              )}
              {profile.yearsOfExperience && (
                <>
                  <dt>
                    <FormattedMessage id="Account.YearsOfExperience" />
                  </dt>
                  <dd>{profile.yearsOfExperience}</dd>
                </>
              )}
            </dl>
          </div>

          <div className={styles.Bio}>{profile.bio}</div>
        </>
      )}

      <p>
        <Link to={`${url}/edit`}>
          <Button>
            <FormattedMessage id="Settings.Account" />
          </Button>
        </Link>
      </p>
    </div>
  );
};

export default ProfileView;
