import { get, find, filter, orderBy, omit } from "lodash";

export const getOrders = (state) => get(state, "orders.store.records", []);
export const getCurrentOrder = (state) => get(state, "orders.data", null);

export const getCurrentOrderLive = (state) => {
  const _id = get(state, "orders.data._id", null);
  if (_id) {
    return find(get(state, "orders.store.records", []), { _id });
  }
  return null;
};

export const getOrderById = (state, _id) => {
  if (_id === "new") {
    return state.orders.data;
  }
  return find(get(state, "orders.store.records", []), { _id });
};

export const getCurrentUnassignedOrder = (state) => {
  const _id = get(state, "unassignedOrders.data._id", null);
  if (_id) {
    return find(get(state, "unassignedOrders.store.records", []), { _id });
  }
  return undefined;
};

const quotedStatuses = ["Order.Status.Quoted", "Order.Status.DraftQuote"];

export const getQuotedOrders = (state) =>
  orderBy(
    filter(getOrders(state), (order) => quotedStatuses.includes(order.status)),
    ["createdAt"],
    ["desc"]
  );

const inProgressStatuses = [
  "Order.Status.Unassigned",
  "Order.Status.InProgress",
  "Order.Status.Suspended",
];
export const getInProgressOrders = (state) =>
  orderBy(
    filter(getOrders(state), (order) =>
      inProgressStatuses.includes(order.status)
    ),
    ["createdAt"],
    ["desc"]
  );

export const getCompletedOrders = (state) =>
  orderBy(
    filter(
      getOrders(state),
      (order) => order.status === "Order.Status.Complete"
    ),
    ["completedAt"],
    ["desc"]
  );

export const getCancelledOrders = (state) =>
  orderBy(
    filter(
      getOrders(state),
      (order) => order.status === "Order.Status.Cancelled"
    ),
    ["updatedAt"],
    ["desc"]
  );
export const getOrderCount = (state) => getInProgressOrders(state).length;
export const getOrderPagination = (state) =>
  omit(state.orders.queryResult, "data");

export const isLoading = (state) =>
  state.orders.isLoading ||
  state.orders.isSaving ||
  state.unassignedOrders.isLoading ||
  state.unassignedOrders.isSaving;
