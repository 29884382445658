import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useIntl } from 'react-intl';

import { useSelector } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';
import SelectField from '../components/formfields/SelectField';

import PropTypes from 'prop-types';
import { getOrdersLimitInDays } from '../selectors';
import { updateSettings, reloadAllOrdersAndInvoices } from '../actions';
import { connect } from 'react-redux';
import styles from './LimitOnCreatedAt.module.scss';
/**
 * Set limit in days (in settings) for list of orders
 */

const LimitOnCreatedAt = ({
  doUpdateSettings,
  doReloadAllOrdersAndInvoices,
}) => {
  const initLimit = useSelector(getOrdersLimitInDays);
  const [newLimit, setNewLimit] = useState(initLimit.value);
  const required = (value) => (value ? undefined : 'Forms.Required');
  const intl = useIntl();
  const limitOptions = [
    {
      value: 30,
      text: intl.formatMessage({ id: 'Admin.Settings.FilterOneMonth' }),
    },
    {
      value: 91,
      text: intl.formatMessage({ id: 'Admin.Settings.FilterThreeMonths' }),
    },
    {
      value: 183,
      text: intl.formatMessage({ id: 'Admin.Settings.FilterSixMonths' }),
    },
    {
      value: 365,
      text: intl.formatMessage({ id: 'Admin.Settings.FilterOneYear' }),
    },
    {
      value: 730,
      text: intl.formatMessage({ id: 'Admin.Settings.FilterTwoYears' }),
    },
    {
      value: 36500,
      text: intl.formatMessage({ id: 'Admin.Settings.FilterAll' }),
    },
  ];
  /**
   * Handle submit on change
   */
  const submitSetting = (limitInDay) => {
    setNewLimit(limitInDay);
    doUpdateSettings({ item: initLimit, data: limitInDay, type: 'simple' });
    const query = {
      status: [
        'Order.Status.Unassigned',
        'Order.Status.Unpaid',
        'Order.Status.Suspended',
        'Order.Status.Quoted',
        'Order.Status.InProgress',
        'Order.Status.DraftQuote',
        'Order.Status.Complete',
        'Order.Status.Cancelled',
      ],
    };
    doReloadAllOrdersAndInvoices(query);
  };

  return (
    <Form onSubmit={(limitInDay) => submitSetting(limitInDay)}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.SmallSelect}>
            <OnChange name="limit">
              {(value) => {
                submitSetting(value);
              }}
            </OnChange>
            <Field
              component={SelectField}
              name="limit"
              options={limitOptions}
              label="Admin.Settings.FilterOrder"
              validate={required}
              showErrorBeforeTouched
              initialValue={newLimit}
            />
          </div>
        </form>
      )}
    </Form>
  );
};
LimitOnCreatedAt.propTypes = {
  doUpdateSettings: PropTypes.func.isRequired,
  doReloadAllOrdersAndInvoices: PropTypes.func.isRequired,
};

const actionsToProps = (dispatch) => ({
  doUpdateSettings: (data) => dispatch(updateSettings(data)),
  doReloadAllOrdersAndInvoices: (data) =>
    dispatch(reloadAllOrdersAndInvoices(data)),
});
export default connect(null, actionsToProps)(LimitOnCreatedAt);
