import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './CanTranslate.module.scss';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHourglassHalf,
  faMinusCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';

import { coupleOfLanguagesStatus } from '../constants';
import { LanguageCouple } from './LanguageCouple';

const CanTranslate = ({
  languageCouple,
  fromCanTranslate,
  languageCouples,
}) => {
  const intl = useIntl();
  // for the modal:
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setShowFactorAdd(false);
    setStatus(languageCouple.status);
  };
  const [showFactorAdd, setShowFactorAdd] = useState(false);
  const [coupleToUpdate, setCoupleToUpdate] = useState({
    couple: '',
    factor: '',
  });
  const [status, setStatus] = useState(languageCouple.status);
  const statusOptions = () => {
    return coupleOfLanguagesStatus.map((to) => ({
      value: to,
      text: intl.formatMessage({ id: `Admin.Translators.${to}` }),
    }));
  };

  const onChangeAction = () => {
    closeModal();
    if (status !== languageCouple.status) {
      fromCanTranslate({ ...languageCouple, status }, coupleToUpdate);
    } else {
      fromCanTranslate(null, coupleToUpdate);
    }
  };

  const setStatusValue = (e) => {
    setStatus(e.target.value);
    // if Validated, check if exist in DB
    if (e.target.value === 'VALIDATED') {
      const index = languageCouples.findIndex(
        (lang) => lang.couple === languageCouple.couple
      );
      if (index === -1) {
        // if not, set factor to 1
        setShowFactorAdd(true);
        setCoupleToUpdate({ couple: languageCouple.couple, factor: 1 });
      }
    } else {
      setShowFactorAdd(false);
    }
  };

  let itemLanguage;
  if (languageCouple) {
    switch (languageCouple.status) {
      case 'VALIDATED':
        itemLanguage = (
          <FontAwesomeIcon
            className={classnames(styles.icon, styles.VALIDATED)}
            icon={faCheckCircle}
          />
        );
        break;
      case 'PENDING':
        itemLanguage = (
          <FontAwesomeIcon
            className={classnames(styles.icon, styles.PENDING)}
            icon={faHourglassHalf}
          />
        );
        break;
      case 'REMOVED':
        itemLanguage = (
          <FontAwesomeIcon
            className={classnames(styles.icon, styles.REMOVED)}
            icon={faMinusCircle}
          />
        );
        break;
      default:
        itemLanguage = null;
        break;
    }
  }
  return (
    <>
      <div
        id={`couple_${languageCouple.couple}`}
        className={classnames(
          styles.languageCouple,
          styles[languageCouple.status]
        )}
        onClick={openModal}
      >
        {itemLanguage}
        <LanguageCouple couple={languageCouple} />

        <Modal isOpen={modalIsOpen}>
          <ModalHeader>
            <FormattedMessage id={`Admin.Translators.TraductionCouple`} />{' '}
            <LanguageCouple couple={languageCouple} />
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row xs="1">
                <Col>
                  <h3>
                    <FormattedMessage id={`Admin.Translators.ActualStatus`} />
                    &nbsp;:&nbsp;
                    <span className="text-danger">
                      <FormattedMessage
                        id={`Admin.Translators.${languageCouple.status}`}
                      />
                    </span>
                  </h3>
                </Col>

                <Col className="mt-3">
                  <p>
                    <FormattedMessage id={`Admin.Translators.UpdateStatus`} /> :
                  </p>
                </Col>

                <Col className="my-3" sm={{ size: 6, offset: 3 }}>
                  <Input
                    type="select"
                    value={status}
                    label="Admin.Translators.updateStatus"
                    placeholder="Admin.Translators.updateStatus"
                    onChange={setStatusValue}
                  >
                    {statusOptions().map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
              {showFactorAdd && (
                <>
                  <Row>
                    <Col>
                      <h3>
                        <FormattedMessage id="Admin.Translators.NewLanguageCouple" />
                      </h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <p className="mt-3">
                        <FormattedMessage id="Admin.Translators.AddFactor" />
                      </p>
                    </Col>
                    <Col className="my-3">
                      <input
                        type="number"
                        step="0.1"
                        min="0"
                        value={coupleToUpdate.factor}
                        label="Admin.Translators.AddFactor"
                        className="form-control"
                        onChange={(e) =>
                          setCoupleToUpdate({
                            ...coupleToUpdate,
                            factor: e.target.value,
                          })
                        }
                      ></input>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </ModalBody>
          <ModalFooter>
            <Container>
              <Row xs="2">
                <Col>
                  <Button color="secondary" onClick={closeModal}>
                    <FormattedMessage id={`Admin.Cancel`} />
                  </Button>
                </Col>
                <Col className="text-right">
                  <Button color="primary" onClick={onChangeAction}>
                    <FormattedMessage id={`Admin.Validate`} />
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default CanTranslate;
