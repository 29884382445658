import React from "react";
import request from "superagent";
import download from "downloadjs";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import { Container, Row, Col } from "reactstrap";
import Card from "../components/Card";

const get = (type) => {
  request
    .get(`${process.env.REACT_APP_API_URL}/${type}-csv`)
    .set("Authorization", localStorage.getItem("feathers-jwt"))
    .responseType("blob")
    .then((res) => {
      download(res.body, `${type}.csv`, "text/csv");
    });
};

const ExportPage = () => (
  <>
    <Container>
      <Row xs="2" md="4">
        <Col>
          <Card title="Admin.Orders">
            <Button type="button" onClick={() => get("orders")}>
              <FormattedMessage id="Admin.Download" />
            </Button>
          </Card>
        </Col>
        <Col>
          <Card title="Admin.Invoices">
            <Button type="button" onClick={() => get("invoices")}>
              <FormattedMessage id="Admin.Download" />
            </Button>
          </Card>
        </Col>
        <Col>
          <Card title="Admin.Companies">
            <Button type="button" onClick={() => get("companies")}>
              <FormattedMessage id="Admin.Download" />
            </Button>
          </Card>
        </Col>
        <Col>
          <Card title="Admin.Users">
            <Button type="button" onClick={() => get("users")}>
              <FormattedMessage id="Admin.Download" />
            </Button>
          </Card>
        </Col>
      </Row>
    </Container>





  </>
);

export default ExportPage;
