import React from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import Card from '../components/Card';
import TextField from '../components/formfields/TextField';
import DateTimeField from '../components/formfields/DateTimeField';

import { isNotComplete, needsPayApproved } from './orderUtils';
import { updateOrder } from '../actions';

const OrderForm = ({ order, doUpdateOrder }) => {
  if (!order) return null;

  const needsApproval = needsPayApproved(order);

  const save = (payload) => {
    if (needsApproval) {
      payload.payOverridden = true;
    }
    doUpdateOrder(payload);
  };

  const editable = needsApproval || isNotComplete(order);

  return (
    <Card title="Order.EditPaymentDetails" id="payment">
      <Form onSubmit={save} initialValues={order}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {needsApproval && (
              <span className="text-danger">
                <FormattedMessage id="Order.NeedsPayApproved" />
              </span>
            )}

            <Field
              name="price"
              label="Order.Confirm.Price.ExcludingTax"
              disabled
              component={TextField}
            />
            <Field
              name="pay"
              label="Translator.Order.Pay"
              component={TextField}
              disabled={!editable}
            />
            <Field
              name="deadline"
              label="Order.Due"
              component={DateTimeField}
              disabled={!editable}
            />
            <div className="text-center my-3">
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={!editable}
              >
                <FormattedMessage
                  FormattedMessage
                  id={
                    needsApproval ? 'Admin.Order.ApprovePayment' : 'Admin.Save'
                  }
                />
              </button>
            </div>
          </form>
        )}
      </Form>
    </Card>
  );
};
const actionsToProps = {
  doUpdateOrder: updateOrder,
};

export default connect(null, actionsToProps)(OrderForm);
