import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Help = ({ input, text }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <FontAwesomeIcon id="help" className="pull-right" icon={faInfoCircle} />
      <Tooltip id={`${input}-popover`} placement="right" isOpen={tooltipOpen} target="help" toggle={toggle}>
        {text}
      </Tooltip>
    </>
  );
};

Help.propTypes = {
  input: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
};
export default Help;
