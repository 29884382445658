import React from 'react';

import { OrderPropType } from './../common/proptypes';

import { orderStatus, needsPayApproved, isOverdue } from './orderUtils';
import { Row, Col } from 'reactstrap';
import styles from './OrderStatus.module.scss';
import { FormattedMessage } from 'react-intl';

const OrderStatus = ({ order }) => {
  const status = orderStatus(order, 'client');
  const needsApproval = needsPayApproved(order);
  const overdue = isOverdue(order);

  return (
    <Row className={styles.StatusContainer}>
      <Col xs="3" className={styles.VerticalAlign}>
        <span className="font-weight-bold">
          <FormattedMessage
            id={order.proofreading ? 'Proofreading' : 'Translation'}
          />
        </span>
      </Col>
      <Col xs="5" className={styles.VerticalAlign}>
        {order.translatorDetails && (
          <img
            src={order.translatorDetails.picture}
            className={styles.Avatar}
            alt={order.translatorDetails.givenName}
          />
        )}
        <span>{status.human}</span>
      </Col>
      <Col xs="4" className={styles.VerticalAlign}>
        {needsApproval && (
          <span className={styles.NeedsApproval}>
            <FormattedMessage id="Order.NeedsPayApproved" />
          </span>
        )}

        {overdue && !needsApproval && (
          <span className={styles.Overdue}>
            <FormattedMessage id="Order.Overdue" />
          </span>
        )}
      </Col>
    </Row>
  );
};

OrderStatus.propTypes = {
  order: OrderPropType.isRequired,
};

export default OrderStatus;
