import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { feathersServices } from "../feathers";
import { UncontrolledSelectField } from "./formfields/SelectField";

const UpdateSpecialityModal = ({
  modalIsOpen,
  closeModal,
  account,
  adminLang,
}) => {
  const [currentStatus, setCurrentStatus] = useState(modalIsOpen?.status);
  const intl = useIntl();

  const options = [
    {
      text: adminLang === "fr" ? "En attente" : "Pending",
      value: "PENDING",
    },
    {
      text: adminLang === "fr" ? "Validée" : "Validated",
      value: "VALIDATED",
    },
    {
      text: adminLang === "fr" ? "Supprimée" : "Deleted",
      value: "DELETED",
    },
  ];

  useEffect(() => {
    if (modalIsOpen) setCurrentStatus(modalIsOpen.status);
  }, [modalIsOpen]);

  const handleUpdateSpeciality = async () => {
    let newExp = [];
    if (currentStatus === "DELETED")
      newExp = account.expertise.filter((e) => e.key !== modalIsOpen.key);
    if (currentStatus === "PENDING")
      newExp = account.expertise.map((e) => {
        if (e.key === modalIsOpen.key) {
          return { key: e.key, status: currentStatus };
        } else return e;
      });
    await feathersServices.users.patch(account._id, { expertise: newExp });
    toast.success(intl.formatMessage({ id: "Admin.Toast.Update" }));
    closeModal();
  };

  return (
    <Modal isOpen={Boolean(modalIsOpen)} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        <FormattedMessage id="Translator.Speciality" />: {modalIsOpen?.title}
      </ModalHeader>
      <ModalBody>
        <p>
          <FormattedMessage id="Current.State" /> :{" "}
          <strong
            style={{
              color: modalIsOpen?.status === "VALIDATED" ? "#7de08a" : "orange",
            }}
          >
            {options.find((o) => o.value === modalIsOpen?.status)?.text}
          </strong>
        </p>
        <p>
          <FormattedMessage id="Update.State" /> :
        </p>
        <UncontrolledSelectField
          input={{
            onChange: (e) => setCurrentStatus(e.target.value),
            value: currentStatus,
            style: { maxWidth: 250 },
          }}
          options={options}
        />
      </ModalBody>
      <ModalFooter>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={closeModal}>
            <FormattedMessage id="Admin.Cancel" />
          </Button>
          <Button color="primary" onClick={handleUpdateSpeciality}>
            <FormattedMessage id="Admin.Update" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const Specialities = ({ specialities, account, adminLang }) => {
  const [modalIsOpen, setModalIsOpen] = useState(null);

  const validatedExpertise = useMemo(() => {
    return account?.expertise?.filter((e) => e.status === "VALIDATED");
  }, [account.expertise]);

  const pendingExpertise = useMemo(() => {
    return account?.expertise?.filter((e) => e.status === "PENDING");
  }, [account.expertise]);

  const total = [...validatedExpertise, ...pendingExpertise];

  const handleOpenModal = (selectedSpe) => {
    setModalIsOpen(selectedSpe);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      {total.map((s,i) => {
        const text = specialities.find((sp) => sp.key === s.key)?.speciality[
          adminLang
        ];
        return (
          <div
          key={i}
            style={{
              border: `1px solid ${
                s.status === "VALIDATED" ? "#7de08a" : "orange"
              }`,
            }}
            className="box-spe"
            onClick={() =>
              handleOpenModal({ key: s.key, status: s.status, title: text })
            }
          >
            <strong>{text}</strong>
          </div>
        );
      })}
      <UpdateSpecialityModal
        modalIsOpen={modalIsOpen}
        account={account}
        closeModal={() => setModalIsOpen(false)}
        adminLang={adminLang}
      />
    </div>
  );
};

export default Specialities;
