import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { AccountPropType } from "./../common/proptypes";
import { getTranslators } from "../selectors";
import TranslatorDetails from "../account/TranslatorDetails";
import Card from "../components/Card";
import { Container, Row, Col, Table } from "reactstrap";

const TranslatorsPage = ({ translators }) => {
  return (
    <Container>
      <Row>
        <Col>
          <Card>
            {translators && (
              <Table borderless responsive>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="Admin.Translators" />
                    </th>

                    <th>
                      <FormattedMessage id="Admin.Translators.Online" />
                    </th>
                    <th>
                      <FormattedMessage id="Admin.Translators.Push" />
                    </th>
                    <th>
                      <FormattedMessage id="Admin.Translators.Available" />
                    </th>
                    <th>
                      <FormattedMessage id="Admin.Translators.Pending" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {translators.map((translator) => (
                    <TranslatorDetails
                      key={translator._id}
                      translator={translator}
                    />
                  ))}
                </tbody>
              </Table>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

TranslatorsPage.propTypes = {
  translators: PropTypes.arrayOf(AccountPropType).isRequired,
};

const stateToProps = (state) => ({
  translators: getTranslators(state),
});

export default connect(stateToProps)(TranslatorsPage);
