import { get, filter, some } from 'lodash';

export const getMessages = (state, user) =>
  filter(
    get(state, 'messages.store.records', []),
    (message) =>
      (message.recipient === undefined && message.sender === user) ||
      (message.sender === undefined && message.recipient === user)
  );

export const getMessagesForOrder = (state, orderId) =>
  filter(
    get(state, 'messages.store.records', []),
    (message) => message.order === orderId
  );
export const getIsMessageSaving = (state) => state.messages.isSaving;
export const getIsMessageLoading = (state) => state.messages.isLoading;

export const getHasUnreadMessages = (state, user) =>
  some(
    getMessages(state, user),
    (message) => message.recipient === undefined && !message.seen
  );
