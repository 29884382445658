import React from 'react';
import { FormattedMessage } from 'react-intl';
import Flag from './Flag';
import { connect } from 'react-redux';
import { getProfile, getLanguagesList } from '../selectors';

const Languages = ({ order, languagesList, user }) => {
  const from = order.selectedLanguage;
  const to = order.destinationLanguage;
  let proofreadingOrigin = undefined;
  if (order.proofreading) {
    proofreadingOrigin = languagesList[user.lang].find(
      (lang) => lang.key === from
    );
  }
  return (
    <table className="table table-bordered" style={{ tableLayout: 'fixed' }}>
      <thead>
        {!order.proofreading ? (
          <tr className="table-primary text-center">
            <th xs="6">
              {' '}
              <FormattedMessage id="Language.Source" />
            </th>
            <th xs="6">
              <FormattedMessage id="Language.Destination" />
            </th>
          </tr>
        ) : (
          <tr className="table-primary text-center">
            <th xs="12">
              {' '}
              <FormattedMessage id="Language.Proofreading" />
            </th>
          </tr>
        )}
      </thead>
      <tbody>
        {!order.proofreading ? (
          <tr className=" text-center">
            <td>
              <Flag country={from} />
            </td>
            <td>
              <Flag country={to} />
            </td>
          </tr>
        ) : (
          <tr className=" text-center">
            <td>
              <Flag country={to} />
              <p>
                {' '}
                <FormattedMessage id="Language.OriginalDocument" /> :{' '}
                {proofreadingOrigin?.name}
              </p>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
const stateToProps = (state) => ({
  languagesList: getLanguagesList(state),
  user: getProfile(state),
});
export default connect(stateToProps)(Languages);
