import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import classnames from 'classnames';
import styles from './CanTranslate.module.scss';
import { FormattedMessage } from 'react-intl';
import { LanguageCouple } from './LanguageCouple';

export const CoupleFactor = ({ lang, onCreate }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [initialFactor, setInitialFactor] = useState(null);
  const [coupleToUpdate, setCoupleToUpdate] = useState({
    couple: '',
    factor: '',
  });
  const [showFactorError, setShowFactorError] = useState(false);

  const openModal = (langCouple) => {
    setInitialFactor(langCouple.factor);
    setCoupleToUpdate({ couple: langCouple.couple, factor: langCouple.factor });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onChangeFactor = (e) => {
    setShowFactorError(false);
    setCoupleToUpdate({
      ...coupleToUpdate,
      factor: e.target.value,
    });
  };

  const onChangeAction = () => {
    if (coupleToUpdate.factor !== initialFactor) {
      setModalIsOpen(false);
      onCreate(coupleToUpdate);
    } else {
      setShowFactorError(true);
    }
  };

  return (
    <>
      <Row>
        <Col
          onClick={() => openModal(lang)}
          className={classnames(styles.languageCouple, styles['VALIDATED'], {
            'mr-auto': true,
          })}
        >
          <Col>
            <LanguageCouple couple={lang} />
          </Col>
          <Col>
            <FormattedMessage id="Admin.Settings.Factor" />
            <span> : {lang.factor}</span>
          </Col>
        </Col>
      </Row>

      <Modal isOpen={modalIsOpen}>
        <ModalHeader>
          <FormattedMessage id={`Admin.Translators.TraductionCouple`} />{' '}
          <LanguageCouple couple={lang} />
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <h3>
                  <FormattedMessage id="Admin.Settings.ModifyFactor" />
                </h3>
              </Col>
            </Row>

            <Row>
              <Col>
                <p className="mt-3">
                  <FormattedMessage id="Admin.Translators.AddFactor" />
                </p>
              </Col>
              <Col className="my-3">
                <input
                  type="number"
                  step="0.1"
                  min="0"
                  value={coupleToUpdate.factor}
                  label="Admin.Translators.AddFactor"
                  className="form-control"
                  onChange={onChangeFactor}
                ></input>
              </Col>
            </Row>
            {showFactorError && (
              <Row>
                <Col>
                  <p className={styles.error}>
                    <FormattedMessage id="Admin.Settings.FactorError" />
                  </p>
                </Col>
              </Row>
            )}
          </Container>
        </ModalBody>
        <ModalFooter>
          <Container>
            <Row xs="2">
              <Col>
                <Button color="secondary" onClick={closeModal}>
                  <FormattedMessage id={`Admin.Cancel`} />
                </Button>
              </Col>
              <Col className="text-right">
                <Button color="primary" onClick={onChangeAction}>
                  <FormattedMessage id={`Admin.Update`} />
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Modal>
    </>
  );
};
