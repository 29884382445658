import { get, filter, sortBy } from "lodash";

export const getProfile = (state) => state.auth.user;
export const getTranslators = (state) =>
  sortBy(
    filter(
      get(state, "users.store.records", []),
      (t) => t.role === "translator"
    ),
    ["familyName", "givenName"]
  );
export const getOnlineTranslators = (state) =>
  filter(getTranslators(state), (t) => t.online);
export const getPushTranslators = (state) =>
  filter(getTranslators(state), (t) => t.pushTokens && t.pushTokens.length);
export const getPendingLanguagesTranslators = (state) =>
  filter(getTranslators(state), (t) =>
    t.canTranslate.find((l) => l.status === "PENDING")
  );

export const getCompanies = (state) =>
  get(state, "companies.store.records", []);
export const getCompanyCount = (state) =>
  get(state, "companies.store.records.length", 0);

export const getClients = (state) =>
  filter(get(state, "users.store.records", []), (t) => t.role === "client");
export const getOnlineClients = (state) =>
  filter(getClients(state), (c) => c.online);

export const getSettings = (state) => get(state, "settings.store.records", []);
export const getDayStart = (state) => settings(state).dayStart;
export const getAvailableLanguages = (state) =>
  settings(state).availableLanguagesCouples;
export const getLanguagesList = (state) => settings(state).languagesList;
export const getDayEnd = (state) => settings(state).dayEnd;
export const getOrdersLimitInDays = (state) =>
  getSettings(state).find((setting) => setting.key === "ordersLimitInDays");
let settingsCache;

export const settings = (state) => {
  if (settingsCache) {
    return settingsCache;
  }
  const serverSettings = get(state, "settings.store.records", false);
  if (serverSettings) {
    settingsCache = serverSettings.reduce((a, b) => {
      // eslint-disable-next-line no-param-reassign
      a[b.key] = b.value;
      return a;
    }, {});
    return settingsCache;
  }
  return {};
};

export const getLanguageCouples = (state) =>
  get(state, "languagecouples.store.records", []);

export const getNewLanguageCouples = (state) =>
  get(state, "languagecouples.data", []);

export const getSpecialities = (state) =>
  get(state, "specialities.store.records", []);

export const getNewSpecialities = (state) =>
  get(state, "specialities.data", []);

export const getSettingsSpecialities = (state) =>
  settings(state).specialities.filter((s) => s.status !== "REMOVED");
