import React from 'react';
import { Container } from 'reactstrap';
import { CoupleFactor } from './CoupleFactor';

export const CoupleFactorList = ({ languageCouples, onCreate }) => {
  return (
    <Container>
      <div className="m-auto">
        {languageCouples.map((lang) => (
          <CoupleFactor key={lang._id} lang={lang} onCreate={onCreate} />
        ))}
      </div>
    </Container>
  );
};
