import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AccountPropType } from './../common/proptypes';
import styles from './AccountCard.module.scss';
import { getHasUnreadMessages } from '../chat/selectors';
import Flag from '../components/Flag';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from 'reactstrap';

const AccountCard = (props) => {
  const {
    account: {
      picture,
      title,
      givenName,
      familyName,
      email,
      phone,
      accountId,
      nationality,
      dualNationality,
    },
    meta,
    hasUnreadMessages,
    link,
    remove,
    onRemove,
    removeSentence,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const removeAction = () => onRemove();
  const openModal = (e) => {
    e.stopPropagation();
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const doAction = () => {
    closeModal();
    removeAction();
  };
  return (
    <Row>
      <Col>
        <div className={styles.Card} onClick={link}>
          <img
            src={picture ? picture : 'src/img/logo.png'}
            width="20"
            alt={`${title} ${givenName} ${familyName}`}
            className={styles.Image}
          />
          <div className={styles.NameEmail}>
            <p>
              {`${givenName} ${familyName}`}
              {!!hasUnreadMessages && ' *'}
            </p>
            {nationality && nationality.length === 2 && (
              <Flag
                country={nationality}
                className={styles.Nationality}
                includeText={false}
              />
            )}
            {dualNationality && (
              <Flag
                country={dualNationality}
                className={styles.Nationality}
                includeText={false}
              />
            )}

            <p className={styles.Email}>{email}</p>
            <p className={styles.Phone}>{phone}</p>
          </div>
          <div className={styles.Account}>
            <p>{accountId}</p>
            {meta && <p className={styles.Meta}>{meta}</p>}
          </div>
          {remove && (
            <>
              <div className={styles.removeButton} onClick={openModal}></div>

              <Modal isOpen={modalIsOpen} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>
                  <FormattedMessage id={`ConfirmRemove`} />
                </ModalHeader>
                <ModalBody>
                  <Container>
                    <Row>
                      <Col>
                        <p>
                          <FormattedMessage id={removeSentence} />
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </ModalBody>
                <ModalFooter>
                  <Container>
                    <Row xs="2">
                      <Col>
                        <Button color="secondary" onClick={closeModal}>
                          <FormattedMessage id={`Admin.Cancel`} />
                        </Button>
                      </Col>
                      <Col className="text-right">
                        <Button color="primary" onClick={doAction}>
                          <FormattedMessage id={`Yes`} />
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </ModalFooter>
              </Modal>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

AccountCard.propTypes = {
  account: AccountPropType,
  meta: PropTypes.string,
  hasUnreadMessages: PropTypes.bool.isRequired,
  link: PropTypes.func,
  remove: PropTypes.bool,
  onRemove: PropTypes.func,
};

AccountCard.defaultProps = {
  account: null,
  meta: null,
  link: null,
  remove: false,
  onRemove: null,
};

const stateToProps = (state, ownProps) => ({
  hasUnreadMessages:
    ownProps.account && ownProps.account._id
      ? getHasUnreadMessages(state, ownProps.account._id)
      : false,
});

export default connect(stateToProps)(AccountCard);
