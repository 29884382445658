import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  withRouter,
  useRouteMatch,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { AccountPropType } from "./../common/proptypes";

import AccountForm from "../account/AccountForm";
import { findAccountById, ordersForAccount } from "../account/selectors";
import {
  getLanguageCouples,
  getSettingsSpecialities,
  getSpecialities,
} from "../selectors";
import Loading from "../components/Loading";
import OrderLink from "../orders/OrderLink";
import Chat from "../chat/Chat";
import ProfileView from "../account/ProfileView";
import Card from "../components/Card";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import LanguagesCoupleList from "../components/LanguagesCoupleList";
import { ToastContainer, toast } from "react-toastify";
import {
  convertToTranslator,
  setLanguage,
  setProofreading,
  createLanguageCouple,
} from "../actions";
import { differenceWith, isEqual } from "lodash";
import { settings, getProfile } from "../selectors";
import { AddCoupleModal } from "../components/AddCoupleModal";
import Specialities from "../components/Specialities";
import { UncontrolledSelectField } from "../components/formfields/SelectField";
import app, { feathersServices } from "../feathers";
import AccountCard from "../account/AccountCard";
import AccountLink from "../account/AccountLink";
import Flag from "../components/Flag";

const AddSpecialityModal = ({
  modalIsOpen,
  closeModal,
  account,
  specialities,
}) => {
  const [newExpertise, setNewExpertise] = useState(specialities[0].value);
  const intl = useIntl();

  useEffect(() => {
    if (!modalIsOpen) return
    setNewExpertise(specialities[0].value)
  }, [modalIsOpen])

  const handleAddSpeciality = async () => {
    if (newExpertise) {
      const newExp = [
        ...account.expertise,
        { key: newExpertise, status: "VALIDATED" },
      ];
      await feathersServices.users.patch(account._id, { expertise: newExp });
      toast.success(intl.formatMessage({ id: "Admin.Toast.Update" }));
    }
    closeModal();
  };

  return (
    <Modal isOpen={modalIsOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        <FormattedMessage id="Translator.Add.Speciality" />
      </ModalHeader>
      <ModalBody>
        <UncontrolledSelectField
          input={{
            onChange: (e) => setNewExpertise(e.target.value),
            value: newExpertise,
            style: { maxWidth: 250 },
          }}
          options={specialities}
        />
      </ModalBody>
      <ModalFooter>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={closeModal}>
            <FormattedMessage id="Admin.Cancel" />
          </Button>
          <Button onClick={handleAddSpeciality}>
            <FormattedMessage id="Admin.Add" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const EditAccountPage = ({
  account,
  doConvertToTranslator,
  orders,
  doSetLanguage,
  doSetProofreading,
  languageCouples,
  doCreateLanguageCouple,
  languagesList,
  adminLang,
  specialities,
}) => {
  const browserHistory = useHistory();
  const { path } = useRouteMatch();
  const onConvertToTranslator = () =>
    doConvertToTranslator({ id: account._id, browserHistory });
  const intl = useIntl();
  const [modalIsOpen, setIsOpen] = useState(false);
  const languageListWebsite = languagesList[adminLang];
  const [canTranslateItems, setCanTranslateItems] = useState(
    account.canTranslate
  );
  const [specModalIsOpen, setSpecModalIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false);

  console.log(account);

  const getFormattedSpecialities = () => {
    const tmp = specialities?.filter(
      (s) => !account?.expertise?.find((e) => e.key === s.key)
    );
    return tmp.map((spe) => ({
      value: spe.key,
      text: spe.speciality[adminLang],
    }));
  };


  const handleSetLanguage = (canTranslate) => {
    const diff = differenceWith(canTranslate, account.canTranslate, isEqual);
    if (diff !== null && diff.length > 0) {
      toast.success(intl.formatMessage({ id: "Admin.Toast.Update" }));
      doSetLanguage({ id: account._id, canTranslate });
    } else {
      toast.success(intl.formatMessage({ id: "Admin.Toast.NoUpdate" }));
    }
  };

  const handleSetProofreading = () => {
    const proofreading = !account.proofreading;
    toast.success(intl.formatMessage({ id: "Admin.Toast.Update" }));
    doSetProofreading({ id: account._id, proofreading });
  };

  const handleCreateLanguageCouple = (CoupleToCreate) => {
    doCreateLanguageCouple(CoupleToCreate);
  };

  const [favoriteList, setFavoriteList] = useState([]);

  const [myTMX, setMyTMX] = useState([]);

  const fetchMyTMX = async () => {
    try {
      const { data } = await app.service("tmx").find({
        query: {
          clientid: account._id,
        },
      });
      setMyTMX(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMyTMX();
  }, []);

  useEffect(() => {
    const fetchTranslators = async () => {
      const ids = [];
      Object.values(account.myFavoriteTranslators).forEach((list) =>
        list.forEach((l) => {
          if (!ids.find((id) => l === id) && l) ids.push(l);
        })
      );
      const { data } = await app
        .service("users")
        .find({ query: { _id: { $in: ids } } });

      setFavoriteList(data);
    };
    if (account.myFavoriteTranslators && account.role !== "translator")
      fetchTranslators();
  }, [account]);

  if (account) {
    const countedOrders = orders.filter(
      (order) =>
        order.status === "Order.Status.Complete" ||
        order.status === "Order.Status.InProgress" ||
        order.status === "Order.Status.Unassigned"
    );
    const words = countedOrders.reduce((sum, order) => sum + order.words, 0);

    /**
     * After an admin select a new couple of languages for a translator :
     * add to canTranslate, with status = validated,
     * if new couple detected, add with factor in languagcouples
     * @param {Object} languageCouple
     * */
    const backFromAddCoupleModal = (languageCouple) => {
      closeModal();
      if (languageCouple.couple) {
        if (
          !canTranslateItems.find((obj) => obj.couple === languageCouple.couple)
        ) {
          // test if not already existing
          const canTranslate = [
            ...canTranslateItems,
            { couple: languageCouple.couple, status: languageCouple.status },
          ];
          setCanTranslateItems(canTranslate);
          doSetLanguage({ id: account._id, canTranslate });
        }
      }
      if (languageCouple.coupleToUpdate.couple) {
        doCreateLanguageCouple(languageCouple.coupleToUpdate);
      }
    };

    return (
      <Container>
        <ToastContainer />
        <Switch>
          <Route exact path={path}>
            <>
              <Row>
                <Col xs="12" md="6">
                  <Card title="Account.Profile">
                    <ProfileView profile={account} />
                  </Card>

                  {account.role === "translator" && (
                    <>
                      <Card title="Settings.LanguagesTranslated">
                        <Row className="ml-2">
                          <LanguagesCoupleList
                            languageCouples={languageCouples}
                            translatorLanguagesCouples={account.canTranslate}
                            onChange={handleSetLanguage}
                            onCreate={handleCreateLanguageCouple}
                          />
                        </Row>
                        <Row className="justify-content-center mt-2">
                          <Button onClick={() => setIsOpen(true)}>
                            <FormattedMessage id="Admin.Translator.AddACouple" />
                          </Button>
                        </Row>
                      </Card>

                      <Card title="Translator.Specialities">
                        <Row className="ml-2">
                          <Specialities
                            specialities={specialities}
                            account={account}
                            adminLang={adminLang}
                          />
                        </Row>
                        <Row className="justify-content-center mt-2">
                          <Button onClick={() => setSpecModalIsOpen(true)}>
                            <FormattedMessage id="Translator.Add.Speciality" />
                          </Button>
                        </Row>
                      </Card>

                      <Card title="Settings.Proofreading">
                        <Row className="ml-2">
                          <Col xs="6">
                            <FormattedMessage
                              id={
                                account.proofreading &&
                                account.proofreading === true
                                  ? "Admin.Translator.AgreeToProofreading"
                                  : "Admin.Translator.NotAgreeToProofreading"
                              }
                            />
                          </Col>
                          <Col xs="6">
                            <Button onClick={() => handleSetProofreading()}>
                              <FormattedMessage id="Admin.Update" />
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </>
                  )}

                  <Card title="Account.Statistics">
                    <dl>
                      <dt>
                        <FormattedMessage id="Account.TotalWordsTranslated" />
                      </dt>
                      <dd>{words}</dd>

                      <dt>
                        <FormattedMessage id="Orders" />
                      </dt>
                      <dd>{countedOrders.length}</dd>
                    </dl>
                  </Card>

                  <Card title="Chat" titleData={{ person: account.givenName }}>
                    <Chat recipient={account} />
                  </Card>

                  {account.role !== "translator" && (
                    <Card
                      title="Favorites.Translator"
                      titleData={{ person: account.givenName }}
                    >
                      {favoriteList.map((fav) => {
                        return (
                          <div style={{ marginBottom: 10 }}>
                            <AccountLink account={fav} />
                          </div>
                        );
                      })}
                    </Card>
                  )}
                  {account.role !== "translator" && (
                    <Card title="TMX.Translator">
                      {myTMX?.map((tmx) => (
                        <div
                          key={tmx.clientid + tmx.destlang + tmx.srclang}
                          className="flex items-center justify-between flex-wrap"
                          style={{
                            padding: 20,
                            backgroundColor: "white",
                            borderRadius: 15,
                            marginBottom: 10,
                          }}
                        >
                          <div className="flex items-center flex-wrap">
                            <Flag country={tmx.srclang} includeText={false} />
                            <div
                              className="blue"
                              style={{ fontSize: 30, margin: "0px 20px" }}
                            >
                              {">"}
                            </div>
                            <Flag country={tmx.destlang} includeText={false} />
                            <div style={{ marginLeft: 30 }}>
                              <FormattedMessage id="Profile.TMX.Date" />{" "}
                              {new Date(tmx.updatedat).toLocaleDateString("fr")}
                            </div>
                          </div>
                          <a
                            className="pink"
                            href={`${process.env.REACT_APP_API_URL}/tmx/${tmx.srclang}_${tmx.destlang}_${tmx.clientid}.tmx`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <FormattedMessage id="Profile.TMX.Download" />
                          </a>
                        </div>
                      ))}
                    </Card>
                  )}
                </Col>

                <Col xs="12" md="6">
                  <Card title="Orders">
                    <>
                      {orders.map((order) => (
                        <OrderLink order={order} key={order._id} />
                      ))}
                      {!orders.length && account.role === "client" && (
                        <>
                          <p>
                            <FormattedMessage id="Admin.NoOrders" />.{" "}
                            <FormattedMessage id="Admin.Convert" />?
                          </p>
                          <p>
                            <Button
                              variant="danger"
                              onClick={onConvertToTranslator}
                            >
                              <FormattedMessage id="Admin.Convert" />
                            </Button>
                          </p>
                        </>
                      )}
                    </>
                  </Card>
                </Col>
              </Row>

              <AddCoupleModal
                modalIsOpen={modalIsOpen}
                account={account}
                languageListWebsite={languageListWebsite}
                languageCouples={languageCouples}
                closeModal={closeModal}
                fromAddCoupleModal={backFromAddCoupleModal}
              />
              <AddSpecialityModal
                specialities={getFormattedSpecialities()}
                modalIsOpen={specModalIsOpen}
                account={account}
                closeModal={() => setSpecModalIsOpen(false)}
              />
            </>
          </Route>

          <Route path={`${path}/edit`}>
            <AccountForm account={account} />
          </Route>
        </Switch>
      </Container>
    );
  }
  return <Loading />;
};

EditAccountPage.propTypes = {
  account: AccountPropType,
  languageCouples: PropTypes.array.isRequired,
  languagesList: PropTypes.object.isRequired,
  adminLang: PropTypes.string.isRequired,
  doSetLanguage: PropTypes.func.isRequired,
  doSetProofreading: PropTypes.func.isRequired,
  doConvertToTranslator: PropTypes.func.isRequired,
  doCreateLanguageCouple: PropTypes.func.isRequired,
};

EditAccountPage.defaultProps = {
  account: null,
};

const stateToProps = (state, ownProps) => {
  const account = findAccountById(state, ownProps.match.params.id);
  return {
    account,
    orders: ordersForAccount(state, account),
    languageCouples: getLanguageCouples(state),
    languagesList: settings(state).languagesList,
    adminLang: getProfile(state).lang,
    specialities: getSettingsSpecialities(state),
  };
};

const dispatchToProps = {
  doConvertToTranslator: convertToTranslator,
  doSetLanguage: setLanguage,
  doSetProofreading: setProofreading,
  doCreateLanguageCouple: (data) => createLanguageCouple(data),
};

export default withRouter(
  connect(stateToProps, dispatchToProps)(EditAccountPage)
);
