import reduxThunk from 'redux-thunk';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import reduxMulti from 'redux-multi';
import createSagaMiddleware from 'redux-saga';

export const sagaMiddleware = createSagaMiddleware();
export default [
  reduxThunk, // Thunk middleware for Redux
  reduxMulti, // Dispatch multiple actions
  reduxPromiseMiddleware, // Resolve, reject promises with conditional optimistic updates
  sagaMiddleware, // Have sagas, yay!
];
