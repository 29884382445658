import React from "react";
import moment from "moment-timezone";
import { FormattedMessage } from "react-intl";
import { get, findIndex } from "lodash";

import { wordCount } from "./pricing";
import { timezone } from "../constants";

const getName = (order, role) => {
  if (role === "client") {
    return get(order, "translatorDetails.givenName", "");
  }
  return `${order.userDetails.title} ${order.userDetails.givenName} ${order.userDetails.familyName}`;
};

/**
 * Gets the code and human status of the order
 * @param order: the order to check the status for
 * @param role: either "client" or "translator"
 * @returns {{code: string, human: <FormattedMessage />}}
 */
export const orderStatus = (order, role) => {
  let human = "";
  if (order && order.status) {
    if (order.status === "Order.Status.InProgress") {
      human = (
        <FormattedMessage
          id={`${order.status}.${role}`}
          values={{ name: getName(order, role) }}
        />
      );
    } else if (order.status === "Order.Status.Complete") {
      human = (
        <FormattedMessage
          id={`${order.status}.${role}`}
          values={{
            translator: getName(order, role),
            date: moment.tz(order.completedAt, timezone).format("DD/MM/YYYY"),
          }}
        />
      );
    } else if (
      order.status === "Order.Status.Quoted" &&
      order.statusQuote === ""
    ) {
      const translator = getName(order, role);
      if (translator !== "") {
        human = (
          <FormattedMessage
            id="Admin.Quote.NotSentSupportedBy"
            values={{ translator: translator }}
          />
        );
      } else {
        human = <FormattedMessage id="Admin.Quote.NotSent" />;
      }
    } else if (
      order.status === "Order.Status.Quoted" &&
      order.statusQuote !== ""
    ) {
      const translator = getName(order, role);
      if (translator !== "") {
        human = (
          <FormattedMessage
            id="Admin.Quote.SentSupportedBy"
            values={{ translator: translator }}
          />
        );
      } else {
        human = <FormattedMessage id="Admin.Quote.Sent" />;
      }
    } else {
      human = <FormattedMessage id={order.status} />;
    }
  }
  return {
    code: order.status,
    human,
  };
};

export const isOverdue = (order) =>
  moment(order.deadline) <=
    (order.completedAt ? moment(order.completedAt) : moment()) &&
  order.status !== "Order.Status.Cancelled";
export const needsPayApproved = (order) =>
  isOverdue(order) && order.completedAt && !order.payOverridden;

/**
 * Filter to extract "in progress" translatiosn
 * param  order
 * @return boolean
 */
export const isNotComplete = (order) =>
  [
    "Order.Status.Unassigned",
    "Order.Status.Unpaid",
    "Order.Status.InProgress",
    "Order.Status.Suspended",
  ].includes(order.status);

export const isUnassigned = (order) =>
  ["Order.Status.Unassigned"].includes(order.status);

// to know if an order is "in progress"
export const isInProgress = (order) =>
  ["Order.Status.InProgress"].includes(order.status);

export const isSuspended = (order) =>
  ["Order.Status.Suspended"].includes(order.status);

/**
 * Filter to extract completed translations
 * param  order
 * @return boolean
 */
export const isComplete = (order) =>
  ["Order.Status.Complete"].includes(order.status);

const getAllUploads = (order) => {
  const attachments = order.attachments ? [...order.attachments] : [];
  if (order.upload) {
    attachments.push({
      name: order.upload.split("/").pop(),
      path: order.upload,
      authorId: order.user,
      type: "client",
      words: order.words,
    });
  }
  if (order.translatorUpload) {
    attachments.push({
      name: order.translatorUpload.split("/").pop(),
      path: order.translatorUpload,
      authorId: order.translatorId,
      type: "translator",
    });
  }
  return attachments;
};
export const getClientUploads = (order) =>
  getAllUploads(order).filter((u) => u.type === "client");
export const getTranslatorUploads = (order) =>
  getAllUploads(order).filter((u) => u.type === "translator");
export const getClientIntermediateUploads = (order) =>
  getAllUploads(order).filter((u) => u.type === "client-intermediate");
export const getTranslatorIntermediateUploads = (order) =>
  getAllUploads(order).filter((u) => u.type === "translator-intermediate");

export const getTMX = (order) =>
  getAllUploads(order).filter((a) => a.name.split(".").at(-1) !== "tmx");
  
export const getReferenceUploads = (order) =>
  getAllUploads(order).filter((a) => a.type === "reference");

export const getUploadsForFile = (order, { path }) =>
  getTranslatorUploads(order).filter((u) => u.translationFor === path);

export const getIndexForAttachment = (order, attachment) => {
  const attachments = getAllUploads(order);
  const { path, name, nonce } = attachment;
  const search = { name };
  if (path) {
    search.path = path;
  }
  if (nonce) {
    search.nonce = nonce;
  }
  return findIndex(attachments, search);
};

export function countWordsInOrder(order) {
  if (order) {
    const { text } = order;
    const attachments = getAllUploads(order).filter((a) => a.type === "client");
    if (attachments && attachments.length) {
      return attachments
        .map((o) => o.words)
        .reduce((prev, curr) => prev + curr, 0);
    }
    return wordCount(text);
  }
  return 0;
}
