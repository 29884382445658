import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrderLink from './OrderLink';
import { getInProgressOrders } from './selectors';
import Card from '../components/Card';

const OrdersPageSubPageInProgress = ({ inProgressOrders }) => {
  return (
    <Card title="Admin.InProgress" fullWidth>
      {inProgressOrders &&
        inProgressOrders.map((order) => (
          <OrderLink key={order._id} order={order} />
        ))}
    </Card>
  );
};

OrdersPageSubPageInProgress.propTypes = {
  inProgressOrders: PropTypes.array.isRequired,
};

OrdersPageSubPageInProgress.defaultProps = {};

const stateToProps = (state) => ({
  inProgressOrders: getInProgressOrders(state),
});

export default connect(stateToProps)(OrdersPageSubPageInProgress);
