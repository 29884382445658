import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  getProfile,
  getTranslators,
  getCompanyCount,
  getClients,
  getOnlineClients,
  getOnlineTranslators,
  getPushTranslators,
} from '../selectors';
import {
  getPendingLanguagesTranslators,
  getAvailableLanguages,
  getLanguagesList,
} from '../selectors';
import { getOrderCount } from '../orders/selectors';
import { loadOrders, loadCompanies } from '../actions';
import Stats from '../components/Stats';
import { getCompletedInvoices, getInvoices } from '../invoices/selectors';

const HomePage = ({
  user,
  translators,
  orders,
  companies,
  clients,
  invoices,
  languagesCouplesToValidate,
  availableLanguages,
  languagesList,
}) => {
  const linkToLogin = (
    <Link to="/login">
      <FormattedMessage id="Login" />
    </Link>
  );
  return user ? ( // Only show stats if user is online
    <Stats
      user={user}
      orders={orders}
      clients={clients}
      translators={translators}
      companies={companies}
      invoices={invoices}
      availableLanguages={availableLanguages}
      languagesList={languagesList}
      languagesCouplesToValidate={languagesCouplesToValidate}
    />
  ) : (
    // Otherwise show a login prompt
    <div className="body">
      <div className="card">
        <div className="section-title">
          <FormattedMessage id="Admin.Login" values={{ linkToLogin }} />
        </div>
      </div>
    </div>
  );
};

HomePage.propTypes = {
  orders: PropTypes.number.isRequired,
  user: PropTypes.object,
  invoices: PropTypes.object,
};

HomePage.defaultProps = {
  orders: 0,
};

const stateToProps = (state) => ({
  orders: getOrderCount(state),
  clients: {
    total: getClients(state).length,
    online: getOnlineClients(state).length,
  },
  user: getProfile(state),
  translators: {
    total: getTranslators(state).length,
    online: getOnlineTranslators(state).length,
    push: getPushTranslators(state).length,
    languagesCouplesToValidate: getPendingLanguagesTranslators(state).length,
  },
  companies: getCompanyCount(state),
  invoices: {
    total: getInvoices(state).length,
    completed: getCompletedInvoices(state).length,
  },
  availableLanguages: getAvailableLanguages(state),
  languagesList: getLanguagesList(state),
});

const actionsToProps = (dispatch) => ({
  loadOrders: () => dispatch(loadOrders()),
  loadCompanies: () => dispatch(loadCompanies()),
});

export default connect(stateToProps, actionsToProps)(HomePage);
