import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { reducer as formReducer } from 'redux-form';
import { get, set, findIndex, cloneDeep } from 'lodash';
import lang from './common/lang';
import { reducer as notificationReducer } from 'react-notification-system-redux';

import { feathersServices, feathersAuthentication } from './feathers';
import { locale } from './constants';

import {
  DEBUG,
  AUTHENTICATION_LOGOUT,
  MESSAGES_CREATE_RECEIVED,
  NOTIFICATIONS_CREATE_RECIEVED,
  ORDERS_PATCH_RECEIVED,
  UPLOAD,
  UPLOAD_PROGRESS,
  UPLOAD_ERROR,
  UPLOAD_COMPLETE,
  REMOVE_FILE,
} from './common/redux/actions';

const defaultState = {
  app: {
    debug: false,
    newNotification: false,
    header: 'Dashboard',
  },
  intl: {
    locale,
    messages: lang[locale],
  },
};

const appStateReducer = (state = defaultState.app, action) => {
  switch (action.type) {
    case DEBUG:
      return {
        ...state,
        debug: action.payload,
      };
    case NOTIFICATIONS_CREATE_RECIEVED:
      return {
        ...state,
        newNotification: true,
      };
    default:
      return state;
  }
};

const reducer = combineReducers({
  app: appStateReducer,
  notificationReducer,
  auth: feathersAuthentication.reducer,
  users: feathersServices.users.reducer,
  orders: feathersServices.orders.reducer,
  messages: feathersServices.messages.reducer,
  notifications: feathersServices.notifications.reducer,
  companies: feathersServices.companies.reducer,
  settings: feathersServices.settings.reducer,
  invoices: feathersServices.invoices.reducer,
  languagecouples: feathersServices.languagecouples.reducer,
  specialities: feathersServices.specialities.reducer,
  form: formReducer,
  intl: intlReducer,
});

const attachments = (state = [], action) => {
  switch (action.type) {
    case UPLOAD:
      return [...state, action.payload];
    case UPLOAD_PROGRESS: {
      const { name, percent, nonce } = action.payload;
      const fileIndex = findIndex(state, { name, nonce });
      return state.map((attachment, index) => {
        if (index === fileIndex) {
          return Object.assign({}, attachment, { percent });
        }
        return attachment;
      });
    }
    case UPLOAD_ERROR: {
      const { name, error, nonce } = action.payload;
      const fileIndex = findIndex(state, { name, nonce });
      return state.map((attachment, index) => {
        if (index === fileIndex) {
          return Object.assign({}, attachment, { percent: undefined, error });
        }
        return attachment;
      });
    }
    case UPLOAD_COMPLETE: {
      const { name, words, nonce, path } = action.payload;
      const fileIndex = findIndex(state, { name, nonce });

      return state.map((attachment, index) => {
        if (index === fileIndex) {
          return Object.assign({}, attachment, {
            percent: undefined,
            words,
            path,
          });
        }
        return attachment;
      });
    }
    case REMOVE_FILE: {
      const { file } = action.payload;
      const index = state.map((a) => a.path).indexOf(file);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    default:
      return state;
  }
};

/*
 * rootReducer overrides the default behaviour of some of the 'managed' reducers
 */
const rootReducer = (state = defaultState, action) => {
  let newState = state;

  switch (action.type) {
    case AUTHENTICATION_LOGOUT:
      newState = { ...defaultState };
      break;
    case MESSAGES_CREATE_RECEIVED:
      if (
        !state.messages.queryResult.length ||
        get(state, 'messages.queryResult[0].order', null) ===
          action.payload.order
      ) {
        const messages = [...state.messages.queryResult, action.payload];
        newState = cloneDeep(set(state, 'messages.queryResult', messages));
      }
      break;
    case ORDERS_PATCH_RECEIVED:
      if (action.payload._id === get(state, 'orders.data._id', null)) {
        const order = Object.assign({}, state.orders.data, action.payload);
        newState = Object.assign({}, state, {
          orders: { ...state.orders, data: order },
        });
      }
      break;
    case UPLOAD: {
      newState = Object.assign({}, state, {
        orders: {
          ...state.orders,
          data: {
            ...state.orders.data,
            attachments: attachments(state.orders.data.attachments, action),
          },
        },
      });
      break;
    }
    case UPLOAD_PROGRESS: {
      newState = Object.assign({}, state, {
        orders: {
          ...state.orders,
          data: {
            ...state.orders.data,
            attachments: attachments(state.orders.data.attachments, action),
          },
        },
      });
      break;
    }
    case UPLOAD_ERROR: {
      newState = Object.assign({}, state, {
        orders: {
          ...state.orders,
          data: {
            ...state.orders.data,
            attachments: attachments(state.orders.data.attachments, action),
          },
        },
      });
      break;
    }
    case UPLOAD_COMPLETE: {
      newState = Object.assign({}, state, {
        orders: {
          ...state.orders,
          data: {
            ...state.orders.data,
            attachments: attachments(state.orders.data.attachments, action),
          },
        },
      });
      break;
    }
    case REMOVE_FILE: {
      newState = Object.assign({}, state, {
        orders: {
          ...state.orders,
          data: {
            ...state.orders.data,
            attachments: attachments(state.orders.data.attachments, action),
          },
        },
      });
      break;
    }
    default:
      break;
  }
  return reducer(newState, action);
};

export default rootReducer;
