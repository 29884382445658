import { feathersServices } from "../feathers";

export const MAX = 9007199254740991;
export function wordCount(text) {
  return text ? text.match(/\S+/g).length : 0;
}

export const round = (number) => {
  const factor = Math.pow(10, 2);
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
};

export default class PriceCalculator {
  constructor({
    priceBreaks,
    basePrice,
    minFastPrice,
    minSlowPrice,
    timeCalculator,
  }) {
    this.priceBreaks = priceBreaks;
    this.basePrice = basePrice;
    this.minFastPrice = minFastPrice;
    this.minSlowPrice = minSlowPrice;
    this.timeCalculator = timeCalculator;
  }

  cachedPrice = {};

  async calculateBasePrice(specialityId, couple) {
    this.specialities = await feathersServices.specialities.find();
    const speciality = this.specialities.find(
      (spec) => spec.key === specialityId
    );
    const specialityFactor = speciality ? speciality.factor : 1;

    this.langCouple = await feathersServices.languagecouples.find();
    const lngCouple = this.langCouple.find((spec) => spec.couple === couple);
    const languageCoupleFactor = lngCouple ? lngCouple.factor : 1;

    return this.basePrice * specialityFactor * languageCoupleFactor;
  }

  getPriceDetailsForMST = (mst, words) => {
    const minutes = mst.asMinutes();
    if (this.cachedPrice[minutes]) {
      return this.cachedPrice[minutes];
    }
    const priceBreaks = [...this.priceBreaks];
    let details;
    let previous;

    // Loop through price breaks until we find a MST break bigger than the current;
    for (const next of priceBreaks) {
      // Normalise down from infinity to something reasonable
      if (+next.mst === MAX)
        next.mst = this.timeCalculator.getMaxDuration(words).asMinutes();

      if (+next.mst === minutes) {
        previous = Object.assign({}, next);
      } else if (+next.mst > minutes) {
        details = Object.assign({}, next); // Take a copy of the pricebreak details so we can modify it
        if (previous) {
          // do some smoothing based on the difference between now and the previous
          const multiplier =
            (minutes - +previous.mst) / (+next.mst - +previous.mst);
          details.uplift =
            +previous.uplift - (+previous.uplift - +next.uplift) * multiplier;
          details.margin =
            +previous.margin - (+previous.margin - +next.margin) * multiplier;
        }
        this.cachedPrice[minutes] = details;
        return details;
      } else {
        previous = Object.assign({}, next); // Do nothing, but remember this for later
      }
    }
    // We got to the end of the price breaks, most likely by just being over the new max
    this.cachedPrice[minutes] = previous;
    return previous;
  };

  getPriceForMST = (mst, words) => {
    const pricing = this.getPriceDetailsForMST(mst, words);
    return this.basePrice + this.basePrice * pricing.uplift;
  };

  getMarginForMST = (mst, words) => {
    const pricing = this.getPriceDetailsForMST(mst, words);
    return pricing.margin;
  };

  getPrice = (mst, words, time, maxTime, avgRedundancy, uniqueWords) => {
    const pricing = this.getPriceDetailsForMST(mst, words);
    const pricePerWord = this.basePrice + this.basePrice * pricing.uplift;
    const priceClassic = round(
      Math.max(words * pricePerWord, this.getMinPrice(time, maxTime))
    );

    const priceWithRedundancy = round(
      Math.max(
        uniqueWords * pricePerWord + (words - uniqueWords) * (0.5 * pricePerWord),
        this.getMinPrice(time, maxTime)
      )
    );

    console.log(priceClassic, priceWithRedundancy)
    return avgRedundancy > 0 ? priceWithRedundancy : priceClassic;
  };

  getMinPrice = (time, maxTime) =>
    ((maxTime - time) / maxTime) * (this.minFastPrice - this.minSlowPrice) +
    this.minSlowPrice;

  getPay = (mst, words, price) => {
    const margin = this.getMarginForMST(mst, words);
    return round(price - price * margin);
  };
}
