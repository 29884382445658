import PropTypes from "prop-types";
import AccountPropType from "./AccountPropType";

export default PropTypes.shape({
  title: PropTypes.string,
  discount: PropTypes.number,
  discountReason: PropTypes.string,
  weekendsAvailable: PropTypes.bool,
  name: PropTypes.string,
  accountId: PropTypes.string,
  email: PropTypes.string,
  contactName: PropTypes.string,
  address: PropTypes.string,
  creditLimit: PropTypes.number,
  balance: PropTypes.number,
  members: PropTypes.arrayOf(AccountPropType),
});
