import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { parse } from "query-string";
import { AccountPropType } from "./../common/proptypes";

import { getProfile } from "../selectors";
import Loading from "../components/Loading";

const API_LOGIN = "/oauth/auth0";
const REDIRECT_KEY = "mybrian-redirect";

class Login extends Component {
  async componentDidMount() {
    const { location } = this.props;
    const query = parse(location.search);
    if (!query.token) {
      await localStorage.removeItem("feathers-jwt");
      this.redirectToAuth();
    } else {
      this.login();
    }
  }

  login() {
    const { location } = this.props;
    const query = parse(location.search);

    localStorage.setItem("feathers-jwt", query.token);
    if (localStorage[REDIRECT_KEY]) {
      const redirectTo = localStorage.getItem(REDIRECT_KEY);
      localStorage.removeItem(REDIRECT_KEY);
      window.location.href = redirectTo;
    } else {
      window.location.href = `${window.location.protocol}//${window.location.host}`;
    }
  }

  redirectToAuth() {
    const { location } = this.props;
    const query = parse(location.search);

    const windowIfDefined = typeof window === "undefined" ? null : window;
    if (windowIfDefined) {
      let redirectTo = `${windowIfDefined.location.protocol}//${windowIfDefined.location.host}`;

      if (query.redirect) {
        redirectTo += query.redirect;
      }

      localStorage.setItem(REDIRECT_KEY, redirectTo);
      windowIfDefined.location.href = `${process.env.REACT_APP_API_URL}${API_LOGIN}`;
    }
  }

  render() {
    const { profile } = this.props;
    if (profile) {
      if (localStorage[REDIRECT_KEY]) {
        const redirectTo = localStorage.getItem(REDIRECT_KEY);
        localStorage.removeItem(REDIRECT_KEY);
        window.location.href = redirectTo;
      } else {
        window.location.href = `${window.location.protocol}//${window.location.host}`;
      }
    }
    return <Loading />;
  }
}
Login.propTypes = {
  profile: AccountPropType,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Login.defaultProps = {
  profile: null,
  location: null,
};

const mapStateToProps = (state) => ({
  profile: getProfile(state),
});

export default connect(mapStateToProps)(Login);
