import React, { useState } from 'react';
import AccountCard from '../account/AccountCard';
import Autocomplete from 'react-autocomplete';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getClients } from './../selectors';
import { filter } from 'lodash';

// const users = feathers.service('users');
const ClientLookup = ({ setClient }) => {
  const [autocompleteText, setAutocompleteText] = useState('');
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const clients = useSelector(getClients);

  // autocomplete on client's store list
  const lookupClient = (val) => {
    const value = val.toLowerCase();
    const results = filter(clients, function (item) {
      return (
        item.email?.toLowerCase().indexOf(value) > -1 ||
        item.givenName?.toLowerCase().indexOf(value) > -1 ||
        item.familyName?.toLowerCase().indexOf(value) > -1 ||
        item.accountId?.toLowerCase().indexOf(value) > -1
      );
    });
    setAutocompleteResults(results);
  };

  return (
    <div className="position-relative">
      <label className="mr-2">
        <FormattedMessage id="Order.Client.Lookup" />
      </label>
      <Autocomplete
        value={autocompleteText}
        items={autocompleteResults}
        onChange={(event, text) => {
          setAutocompleteText(text);
          lookupClient(text);
        }}
        onSelect={(value, item) => {
          setAutocompleteText('');
          setClient(item);
        }}
        inputProps={{
          style: {
            width: '100%',
            border: '1px solid #ced4da',
            borderRadius: '0.25rem',
            padding: '0.375rem 0.75rem',
          },
        }}
        wrapperStyle={{ position: 'relative', width: '100%' }}
        menuStyle={{
          position: 'absolute',
          left: '0px',
          top: '30px',
          zIndex: '1000',
          background: '#fff',
        }}
        getItemValue={(item) => item._id}
        renderItem={(item, isHighlighted) => (
          <div
            className={
              isHighlighted
                ? 'autocomplete autocomplete--highlighted'
                : 'autocomplete'
            }
            key={item._id}
          >
            <AccountCard account={item} />
          </div>
        )}
      />
    </div>
  );
};

export default ClientLookup;
