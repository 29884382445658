import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './LanguagesCoupleList.module.scss';
import CanTranslate from './CanTranslate';

const LanguagesCoupleList = ({
  languageCouples,
  translatorLanguagesCouples,
  onChange,
  onCreate,
}) => {
  // Replace languagecouple with new status in list
  const onChangeAction = (couple, coupleToUpdate) => {
    if (couple !== null) {
      let newCouples = [...translatorLanguagesCouples];
      const itemIndex = newCouples.findIndex(
        (item) => item.couple === couple.couple
      );
      newCouples[itemIndex] = couple;
      onChange(newCouples);
    } else {
      onChange([...translatorLanguagesCouples]);
    }
    if (coupleToUpdate.couple) {
      onCreate(coupleToUpdate);
    }
  };

  const noLanguage = () => {
    return !translatorLanguagesCouples ||
      translatorLanguagesCouples.length === 0 ? (
      <FormattedMessage id="Aucun couple de langue précisé" />
    ) : null;
  };

  return (
    <>
      <div className={styles.CoupleContainer}>
        <p>{noLanguage()}</p>
        {translatorLanguagesCouples.map((lang) => (
          <CanTranslate
            key={lang.couple}
            languageCouple={lang}
            languageCouples={languageCouples}
            fromCanTranslate={onChangeAction}
          />
        ))}
      </div>
    </>
  );
};

export default LanguagesCoupleList;
