import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import accounting from "accounting";
import Card from "../components/Card";
import { Row, Col } from "reactstrap";
import {
  orderStatus,
  isNotComplete,
  needsPayApproved,
  isOverdue,
} from "./orderUtils";
import style from "./OrderKeyDetails.module.scss";
import ActionLink from "../components/ActionLink";
import AccountCard from "../account/AccountCard";
import TranslatorLookup from "../components/TranslatorLookup";

const OrderKeyDetails = ({
  order,
  cancelOrder,
  setTranslator,
  settingsSpecialities,
  adminLang,
}) => {
  const [showTranslatorSearch, setShowTranslatorSearch] = useState(false);
  const history = useHistory();
  if (!order) return null;

  const editable = isNotComplete(order);
  const needsApproval = needsPayApproved(order);
  const overdue = isOverdue(order);

  return (
    <Card
      fullWidth
      title="Order.KeyDetails"
      action={
        editable ? (
          <ActionLink onClick={cancelOrder}>
            <FormattedMessage id="Admin.Order.Cancel" />
          </ActionLink>
        ) : null
      }
    >
      <Row>
        <Col xs="12" md="3">
          <dl>
            <dt>
              <FormattedMessage id="Order.Id" />
            </dt>
            <dd className="text-center">{order.orderId}</dd>
          </dl>
        </Col>
        <Col xs="6" md="2">
          <dl>
            {order.specificity && (
              <>
                <dt>
                  <FormattedMessage id="Order.Specificity.admin" />
                </dt>
                <dd>
                  {
                    settingsSpecialities.find(
                      (spe) => spe.key === order?.specialityId
                    )?.speciality[adminLang]
                  }
                </dd>
              </>
            )}
          </dl>
        </Col>
        <Col xs="6" md="2">
          <dl>
            <dt>
              <FormattedMessage id="Order.Status" />
            </dt>
            <dd>{orderStatus(order, "client").human} </dd>
          </dl>
        </Col>

        <Col xs="6" md="5">
          <Row>
            <Col xs="4">
              <dl>
                <dt>
                  <FormattedMessage id="Invoice.Words" />
                </dt>
                <dd>{order.words}</dd>
              </dl>
            </Col>
            <Col xs="4">
              <dt>
                <FormattedMessage id="Order.Confirm.Price.ExcludingTax" />
              </dt>
              <dd>
                {accounting.formatMoney(
                  order.discountedPrice || order.price,
                  "€",
                  2,
                  ".",
                  ","
                )}
              </dd>
            </Col>
            <Col xs="4">
              <dt>
                <FormattedMessage id="Translator.Order.Pay" />
              </dt>
              <dd>{accounting.formatMoney(order.pay, "€", 2, ".", ",")}</dd>
            </Col>
            <Col xs="12">
              {!!order.payOverridden && (
                <p>
                  <FormattedMessage id="Order.TranslatorPayOverridden" />
                </p>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="3" className="text-center mx-auto">
          {needsApproval && (
            <a href="#payment" className={style.NeedsApproval}>
              <FormattedMessage id="Order.NeedsPayApproved" />
            </a>
          )}
          {overdue && !needsApproval && (
            <span className={style.Overdue}>
              <FormattedMessage id="Order.Overdue" />
            </span>
          )}
        </Col>
      </Row>
      {order?.keywords?.length > 0 && (
        <div style={{ margin: "40px 0px 20px 0px" }}>
          {
            <div className="flex w-full flex-wrap">
              {order?.keywords.map((k, i) => (
                <div
                  key={`k-${i}`}
                  style={{
                    padding: "10px 30px",
                    border: "1px solid grey",
                    margin: "0px 10px 10px 0px",
                  }}
                  className="flex items-center justify-center"
                >
                  {k}
                </div>
              ))}
            </div>
          }
        </div>
      )}

      <Row className="mt-3">
        <Col xs="12" md="6">
          <dl>
            <dt>
              <FormattedMessage id="client" />
            </dt>
            <dd>
              <AccountCard
                account={order.userDetails}
                link={() => history.push(`/clients/${order?.user}`)}
              />
            </dd>
          </dl>
          {order?.onlyFavTranslator && !order?.translator ? (
            <>
              <p style={{ fontWeight: "bold"}}>
                <FormattedMessage id="Order.Wait.Favorite" />
              </p>
              <Link
                style={{ color: "black", textDecoration: "underline" }}
                to={`/clients/${order.user}`}
              >
                <FormattedMessage id="Order.View.Favorites" />{" "}
                {order?.userDetails?.givenName}
              </Link>
            </>
          ) : null}
          {order?.extendResearch && !order?.translator ? (
            <p style={{ fontWeight: "bold"}}>
              <FormattedMessage id="Order.Extend.Research" />
            </p>
          ) : null}
        </Col>
        <Col xs="12" md="6">
          <dl>
            <dt>
              <FormattedMessage id="translator" />
            </dt>
            <dd>
              {order.translator ? (
                <React.Fragment>
                  <AccountCard
                    account={order.translatorDetails}
                    link={() =>
                      history.push(`/translators/${order.translator}`)
                    }
                  />
                  {!!editable &&
                    (showTranslatorSearch ? (
                      <TranslatorLookup setTranslator={setTranslator} />
                    ) : (
                      <ActionLink onClick={() => setShowTranslatorSearch(true)}>
                        <FormattedMessage id="Order.Change.Translator" />
                      </ActionLink>
                    ))}
                </React.Fragment>
              ) : (
                !!editable && <TranslatorLookup setTranslator={setTranslator} />
              )}
            </dd>
          </dl>
        </Col>
      </Row>
      <dl className={style.Container}>
        <div></div>
        <div className={style.break}></div>
        <div></div>

        <div></div>
      </dl>
    </Card>
  );
};

export default OrderKeyDetails;
