import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getTranslatorInvoices } from '../../invoices/selectors';
import InvoiceRowDetails from '../../invoices/InvoiceRowDetails';

const InvoicesTranslatorSubpage = ({ translatorInvoices }) => (
  <div>
    {translatorInvoices.map((invoice) => (
      <InvoiceRowDetails key={invoice._id} invoice={invoice} />
    ))}
  </div>
);

InvoicesTranslatorSubpage.propTypes = {
  translatorInvoices: PropTypes.array.isRequired,
};

const stateToProps = (state) => ({
  translatorInvoices: getTranslatorInvoices(state),
});

export default connect(stateToProps)(InvoicesTranslatorSubpage);
