import React, { useState } from 'react';
import classnames from 'classnames';

import Card from '../../components/Card';
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import InvoicesClientSubpage from './InvoicesClientSubpage';
import InvoicesTranslatorSubpage from './InvoicesTranslatorSubpage';
import { FormattedMessage } from 'react-intl';
import LimitOnCreatedAt from '../../components/LimitOnCreatedAt';

const InvoicesPage = ({ clientInvoices, translatorInvoices }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container>
      <Card>
        <Row>
          <Col>
            <Row>
              <Col>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      <FormattedMessage id="Settings.ClientInvoices" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      <FormattedMessage id="Settings.TranslatorInvoices" />
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col xs="3" className="text-right">
                <LimitOnCreatedAt></LimitOnCreatedAt>
              </Col>
            </Row>

            <TabContent activeTab={activeTab}>
              <Card>
                <TabPane tabId="1">
                  <Row>
                    <Col>{activeTab === '1' && <InvoicesClientSubpage />}</Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col>
                      {activeTab === '2' && <InvoicesTranslatorSubpage />}
                    </Col>
                  </Row>
                </TabPane>
              </Card>
            </TabContent>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default InvoicesPage;
