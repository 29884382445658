import React from "react";
import PropTypes from "prop-types";
import { Field, FormSpy } from "react-final-form";
import { useIntl } from "react-intl";

import { specificities } from "../../constants";
import ButtonGroupField from "./ButtonGroupField";
import SelectField from "./SelectField";

const warnIfSpecialist = (value) =>
  value === "specialist" ? "Order.Specificity.warning" : undefined;

const SpecificityPicker = ({
  mutators: { setFieldData },
  settingsSpecialities,
  adminLang,
  order,
}) => {
  const intl = useIntl();
  const options = specificities.map((s) => ({
    value: s,
    text: intl.formatMessage({ id: `Order.Specificity.${s}` }),
  }));

  const getFormattedSpecialities = () => {
    return settingsSpecialities.map((spe) => ({
      value: spe.key,
      text: spe.speciality[adminLang],
    }));
  };

  const speciality = settingsSpecialities.find(
    (s) => s.key === order.specialityId
  )?.speciality[adminLang];

  return (
    <div className="section-lang">
      {/* <Field
        component={ButtonGroupField}
        name="specificity"
        label="Order.Specificity"
        options={options}
        showWarningBeforeTouched
      /> */}
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 5, marginTop: 10 }}>
          MyBrian has detected that the speciality is <b>{speciality}</b>. If
          this is incorrect <span style={{ color: "ff5f6b" }}>select</span> the
          right one :
        </div>
          <Field
            component={SelectField}
            name="specialityId"
            showWarningBeforeTouched
            options={getFormattedSpecialities()}
            //validate={showPicker && required}
          />
      </div>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          setFieldData("specificity", {
            warning: warnIfSpecialist(values.specificity),
          });
        }}
      />
    </div>
  );
};

SpecificityPicker.propTypes = {
  mutators: PropTypes.shape({
    setFieldData: PropTypes.func,
  }).isRequired,
};

export default SpecificityPicker;
