import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NavigationItem from "./NavigationItem";

import style from "./Navigation.module.scss";
import { Burger, Close } from "../style/common";
import { roleHasUnreadMessages } from "../account/selectors";

const Navigation = ({ className, translatorUnread, clientUnread }) => (
  <div className={className}>
    <div className={style.NavigationHeader}>
      <a
        href="#main-menu"
        className={style.MenuToggle}
        role="button"
        id="main-menu-toggle"
        aria-expanded="false"
        aria-controls="main-menu"
        aria-label="Open main menu"
        style={{display:"none"}}
      >
        <span className={style.SrOnly}>Open main menu</span>
        <Burger aria-hidden="true" />{" "}
      </a>
      <Link to="/" className="icon-my-brian" />
    </div>
    <nav
      id="main-menu"
      className={style.MainMenu}
      role="navigation"
      aria-expanded="false"
      aria-label="Main menu"
    >
      <a
        href="#main-menu-toggle"
        className={style.MenuClose}
        role="button"
        id="main-menu-close"
        aria-expanded="false"
        aria-controls="main-menu"
        aria-label="Close main menu"
      >
        <span className={style.SrOnly}>Close main menu</span>
        <Close className={style.CloseButton} aria-hidden="true" />
      </a>
      <ul>
        <NavigationItem to="/" title="Dashboard" exact />
        <NavigationItem
          to="/clients"
          title="Clients"
          notification={clientUnread}
        />
        <NavigationItem
          to="/translators"
          title="Translators"
          notification={translatorUnread}
        />
        <NavigationItem to="/companies" title="Companies" />
        <NavigationItem to="/orders" title="Orders" />
        <NavigationItem to="/invoices" title="Invoices" />
        <NavigationItem to="/export" title="Export" />
        <NavigationItem to="/app-settings" title="Admin.Settings" />
      </ul>
    </nav>

    <a
      href="#main-menu-toggle"
      className={style.Backdrop}
      tabIndex="-1"
      aria-hidden="true"
      hidden
    >
      &nbsp;
    </a>
  </div>
);

Navigation.propTypes = {
  className: PropTypes.string.isRequired,
  clientUnread: PropTypes.bool.isRequired,
  translatorUnread: PropTypes.bool.isRequired
};

const stateToProps = state => ({
  clientUnread: roleHasUnreadMessages(state, "client"),
  translatorUnread: roleHasUnreadMessages(state, "translator")
});
export default connect(stateToProps)(Navigation);
