import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./Loading.module.scss";

const Loading = () => (
  <div className={styles.Loading}>
    <FormattedMessage id="Loading" />
  </div>
);

export default Loading;
