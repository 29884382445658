import PropTypes from 'prop-types';

export default PropTypes.shape({
  title: PropTypes.string,
  givenName: PropTypes.string,
  familyName: PropTypes.string,
  email: PropTypes.string,
  lang: PropTypes.string,
  canTranslate: PropTypes.arrayOf(
    PropTypes.shape({
      couple: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  phone: PropTypes.string,
  address: PropTypes.string,
  billTo: PropTypes.string,
  billingName: PropTypes.string,
  jobTitle: PropTypes.string,
  department: PropTypes.string,
  sector: PropTypes.string,
  agreedToTerms: PropTypes.bool,
  nationality: PropTypes.string,
  taxStatus: PropTypes.string,
  company: PropTypes.string,
  companyNumber: PropTypes.string,
  invoiceHeaderDetail: PropTypes.string,
  invoiceFooterDetail: PropTypes.string,
  agreedToTranslatorTerms: PropTypes.bool,
  translatorBankDetails: PropTypes.shape({
    accountName: PropTypes.string,
    accountEmail: PropTypes.string,
    iban: PropTypes.string,
  }),
});
