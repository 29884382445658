import React from "react";
import PropTypes from "prop-types";
import ReactJson from "react-json-view";
import { useIntl } from "react-intl";

import { FormGroup, Label } from "reactstrap";
import Help from "./Help";

const JsonField = ({
  input,
  label,
  tooltip,
  meta: { error, warning },
  disabled
}) => {
  const intl = useIntl();
  const state = error ? "error" : warning ? "warning" : null;
  const translatedLabel = label ? intl.formatMessage({ id: label }) : "";
  return (
    <FormGroup>
      <Label>{translatedLabel}</Label>
      {tooltip && <Help input={input.name} text={tooltip} />}
      {disabled ? (
        <ReactJson src={input.value || {}} />
      ) : (
          <ReactJson
            src={input.value || {}}
            onEdit={value => input.onChange(value.updated_src)}
            onAdd={value => input.onChange(value.updated_src)}
            onDelete={value => input.onChange(value.updated_src)}
          />
        )}
      {state && (
        <Label className={state}>{error || warning || ""}</Label>
      )}
    </FormGroup>
  );
};

JsonField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.object,
  meta: PropTypes.object
};

export default JsonField;
