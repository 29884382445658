import { createAction } from 'redux-actions';

export const DEBUG = 'mybrian/app/debug';
export const LOGOUT = 'mybrian/app/logout';
export const logout = createAction(LOGOUT);
export const CREATE_ORDER = 'mybrian/orders/create';
export const createOrder = createAction(CREATE_ORDER);
export const LOAD_ORDERS = 'mybrian/orders/load';
export const loadOrders = createAction(LOAD_ORDERS);
export const LOAD_IN_PROGRESS_ORDERS = 'mybrian/orders/load-inprogress';
export const loadInProgressOrders = createAction(LOAD_IN_PROGRESS_ORDERS);
export const UPDATE_ORDER = 'mybrian/orders/update';
export const updateOrder = createAction(UPDATE_ORDER);
export const CONFIRM_ORDER = 'mybrian/orders/confirm';
export const confirmOrder = createAction(CONFIRM_ORDER);

export const LOAD_COMPANIES = 'mybrian/companies/load';
export const loadCompanies = createAction(LOAD_COMPANIES);

export const CREATE_COMPANY = 'mybrian/companies/create';
export const createCompany = createAction(CREATE_COMPANY);
export const CREATE_COMPANY_FULFILLED = 'SERVICES_COMPANIES_CREATE_FULFILLED';

export const LOAD_TRANSLATORS = 'mybrian/translators/load';
export const loadTranslators = createAction(LOAD_TRANSLATORS);

export const AUTH_FULFILLED = 'SERVICES_AUTHENTICATION_AUTHENTICATE_FULFILLED';
export const USER_DETAIL_FULFILLED = 'SERVICES_USERS_GET_FULFILLED';

export const GET_ORDER_FULFILLED = 'SERVICES_ORDERS_GET_FULFILLED';
export const CREATE_ORDER_FULFILLED = 'SERVICES_ORDERS_CREATE_FULFILLED';
export const UPDATE_ORDER_FULFILLED = 'SERVICES_ORDERS_UPDATE_FULFILLED';
export const UPDATE_ORDER_FAILED = 'SERVICES_ORDERS_UPDATE_FAILED';

export const CREATE_ORDER_RECEIVED = 'CREATE_ORDER_RECEIVED';
export const PATCH_ORDER_RECEIVED = 'PATCH_ORDER_RECEIVED';
export const UPDATE_ORDER_RECEIVED = 'UPDATE_ORDER_RECEIVED';
export const REMOVE_ORDER_RECEIVED = 'REMOVE_ORDER_RECEIVED';
export const ORDERS_PATCH_RECEIVED = 'SERVICES_ORDERS_PATCH_RECEIVED';

export const LOAD_NOTIFICATIONS = 'mybrian/notifications/load';
export const NOTIFICATIONS_CREATE_RECIEVED =
  'SERVICES_NOTIFICATIONS_CREATE_RECEIVED';
export const loadNotifications = createAction(LOAD_NOTIFICATIONS);
export const AUTHENTICATION_LOGOUT = 'SERVICES_AUTHENTICATION_LOGOUT';
export const MESSAGES_CREATE_RECEIVED = 'SERVICES_MESSAGES_CREATE_RECEIVED';

export const GET_COMPANY = 'mybrian/company/get';
export const getCompany = createAction(GET_COMPANY);

export const GET_ORDER = 'mybrian/order/get';
export const getOrder = createAction(GET_ORDER);

export const UPDATE_COMPANY = 'mybrian/companies/update';
export const updateCompany = createAction(UPDATE_COMPANY);
export const UPDATE_COMPANY_FULFILLED = 'SERVICES_COMPANIES_UPDATE_FULFILLED';
export const UPDATE_COMPANY_FAILED = 'SERVICES_COMPANIES_UPDATE_FAILED';

export const LOAD_SETTINGS = 'mybrian/settings/load';
export const loadSettings = createAction(LOAD_SETTINGS);

export const CREATE_SETTINGS = 'mybrian/settings/create';
export const createSettings = createAction(CREATE_SETTINGS);

export const UPDATE_SETTINGS = 'mybrian/settings/update';
export const updateSettings = createAction(UPDATE_SETTINGS);

export const CLEAR_OFFERS = 'mybrian/order/clear-offers';
export const clearOffers = createAction(CLEAR_OFFERS);

export const CLEAR_REJECTIONS = 'mybrian/order/clear-rejections';
export const clearRejections = createAction(CLEAR_REJECTIONS);

export const SUBMIT_INVOICE = 'mybrian/invoices/submit';
export const submitInvoice = createAction(SUBMIT_INVOICE);

export const CANCEL_ORDER = 'mybrian/orders/cancel';
export const cancelOrder = createAction(CANCEL_ORDER);

export const SET_TRANSLATOR_AVAILABLE = 'mybrian/translator/available';
export const setAvailability = createAction(SET_TRANSLATOR_AVAILABLE);

export const SET_TRANSLATOR_LANGUAGES = 'mybrian/translator/setlanguage';
export const setLanguage = createAction(SET_TRANSLATOR_LANGUAGES);

export const SET_TRANSLATOR_PROOFREADING = 'mybrian/translator/setproofreading';
export const setProofreading = createAction(SET_TRANSLATOR_PROOFREADING);

export const SET_TRANSLATOR_SPECIALITIES = 'mybrian/translator/setspecialities';
export const setSpecialities = createAction(SET_TRANSLATOR_SPECIALITIES);

export const CONVERT_TO_TRANSLATOR = 'mybrian/account/convert-to-translator';
export const convertToTranslator = createAction(CONVERT_TO_TRANSLATOR);

export const LOAD_LANGUAGE_COUPLES = 'mybrian/languagecouples/load';
export const loadLanguageCouples = createAction(LOAD_LANGUAGE_COUPLES);

export const CREATE_LANGUAGE_COUPLE = 'mybrian/languagecouples/create';
export const createLanguageCouple = createAction(CREATE_LANGUAGE_COUPLE);

export const LOAD_SPECIALITIES = 'mybrian/specialities/load';
export const loadSpecialities = createAction(LOAD_SPECIALITIES);

export const CREATE_SPECIALITY = 'mybrian/specialities/create';
export const createSpeciality = createAction(CREATE_SPECIALITY);

export const RELOAD_ALL_ORDERS_AND_INVOICES =
  'mybrian/orders-and-invoices/reload';
export const reloadAllOrdersAndInvoices = createAction(
  RELOAD_ALL_ORDERS_AND_INVOICES
);
