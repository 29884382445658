import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import moment from 'moment-timezone';
import { timezone } from '../constants';

import styles from './ChatMessage.module.scss';

const ChatMessage = ({
  text, time, senderName, senderPhoto, myMessage,
}) => {
  const timeAgo = moment.min(moment(), moment.tz(time, timezone)).fromNow();
  const photo = senderPhoto ? <img className={styles.Photo} src={senderPhoto} alt={senderName} /> : <span className={classnames({ 'icon-my-brian': true, [styles.Photo]: true })} />;
  return (
    <div className={classnames({ [styles.Message]: true, [styles.Right]: myMessage, [styles.Left]: !myMessage })}>
      { !myMessage && photo}
      <div className={styles.Text} data-time={timeAgo}>
        {text.split('\n').map((item, key) => (
          <span key={key}>
            {item}
            <br />
          </span>
        ))}
      </div>
    </div>
  );
};

ChatMessage.propTypes = {
  text: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  senderName: PropTypes.string.isRequired,
  senderPhoto: PropTypes.string.isRequired,
  myMessage: PropTypes.bool.isRequired,
};

export default ChatMessage;
