import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { useIntl } from "react-intl";
import FieldHolder from "./FieldHolder";

const TextareaField = (props) => {
  const { placeholder, input, readOnly, rows } = props;
  const intl = useIntl();
  const translatedPlaceholder = placeholder
    ? intl.formatMessage({ id: placeholder })
    : "";

  return (
    <FieldHolder {...props}>
      <Input
        type="textarea"
        placeholder={translatedPlaceholder}
        {...input}
        rows={rows}
        onChange={readOnly ? () => { } : input.onChange}
      />
    </FieldHolder>
  );
};

TextareaField.propTypes = {
  input: PropTypes.object.isRequired,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
};

TextareaField.defaultProps = {
  rows: 3,
  placeholder: undefined,
  readOnly: false,
};

export default TextareaField;
