import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { FormGroup, Label } from "reactstrap";
import Help from "./Help";

const maybeTranslate = (value, intl) => {
  if (typeof value === "string" && value.length) {
    return intl.formatMessage({ id: value });
  }
  return value;
};

const FieldHolder = ({
  input: { name },
  label,
  customLabel,
  tooltip,
  meta: { error, warning, touched },
  showErrorBeforeTouched,
  showWarningBeforeTouched,
  children
}) => {
  const intl = useIntl();
  let validationState;
  if (error && (touched || showErrorBeforeTouched)) {
    validationState = "error";
  } else if (warning && (touched || showWarningBeforeTouched)) {
    validationState = "warning";
  }

  const translatedLabel =
    customLabel || label ? intl.formatMessage({ id: label }) : undefined;
  const translatedError = maybeTranslate(error, intl);
  const translatedWarning = maybeTranslate(warning, intl);

  return (
    <FormGroup>
      {translatedLabel && <Label>{translatedLabel}</Label>}
      {tooltip && <Help input={name} text={tooltip} />}
      {children}
      {validationState && (
        <Label className={`section-textarea__message ${validationState}`}>
          {error ? translatedError : translatedWarning}
        </Label>
      )}
    </FormGroup>
  );
};

FieldHolder.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  customLabel: PropTypes.object,
  meta: PropTypes.object.isRequired,
  tooltip: PropTypes.object,
  showErrorBeforeTouched: PropTypes.bool,
  showWarningBeforeTouched: PropTypes.bool,
  children: PropTypes.element.isRequired
};

FieldHolder.defaultProps = {
  label: "",
  customLabel: undefined,
  tooltip: undefined,
  showErrorBeforeTouched: false,
  showWarningBeforeTouched: false
};

export default FieldHolder;
