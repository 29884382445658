import React from "react";

import Card from "../components/Card";
import SplitPane from "../layout/SplitPane";
import AttachmentPairs from "../attachments/AttachmentPairs";
import Languages from "../components/Languages";
import {
  getTranslatorUploads,
  getIndexForAttachment,
  getClientUploads,
  getUploadsForFile,
  getClientIntermediateUploads,
  getTranslatorIntermediateUploads,
  getTMX,
  getReferenceUploads,
} from "../orders/orderUtils";
import Attachment from "../attachments/Attachment";
import { FormattedMessage } from "react-intl";

const OrderTranslation = ({ order }) => {
  if (!order) return null;
  const clientUploads = getClientUploads(order);
  const translatorUploads = getTranslatorUploads(order);
  const clientIntermediateUploads = getClientIntermediateUploads(order);
  const translatorIntermediateUploads = getTranslatorIntermediateUploads(order);
  const referenceUploads = getReferenceUploads(order);

  return (
    <>
      <Card title={order.proofreading ? "Proofreading" : "Translation"}>
        <Languages order={order} />

        {!(order.attachments && order.attachments.length) && (
          <Card title="Order.View.Text">
            <SplitPane
              left={<Card fill>{order.text}</Card>}
              right={<Card fill>{order.translation}</Card>}
            />
          </Card>
        )}

        <SplitPane
          left={
            <>
              {clientUploads.map((c) => (
                <Attachment
                  attachment={c}
                  index={getIndexForAttachment(order, c)}
                  link
                  readonly
                />
              ))}
            </>
          }
          right={
            <>
              {translatorUploads.map((c) => (
                <Attachment
                  attachment={c}
                  index={getIndexForAttachment(order, c)}
                  link
                  readonly
                />
              ))}
            </>
          }
        />
      </Card>
      <Card title="Intermediate files">
        <SplitPane
          left={
            <>
              <div>client</div>
              {clientIntermediateUploads.map((a) => (
                <Attachment
                  key={`${a.path}${a.nonce}`}
                  attachment={a}
                  link
                  readonly
                  index={getIndexForAttachment(order, a)}
                />
              ))}
            </>
          }
          right={
            <>
              <div>translator</div>
              {translatorIntermediateUploads.map((a) => (
                <Attachment
                  key={`${a.path}${a.nonce}`}
                  attachment={a}
                  link
                  readonly
                  index={getIndexForAttachment(order, a)}
                />
              ))}
            </>
          }
        />
      </Card>
      <Card title="Translator.TMX.Title">
        <SplitPane
          left={
            <div>
              <p>
                <FormattedMessage id="Translator.TMX.Client" />
              </p>
              {referenceUploads.filter(
                (a) => a.name.split(".").at(-1) === "tmx"
              )?.length > 0
                ? referenceUploads
                    .filter((a) => a.name.split(".").at(-1) === "tmx")
                    ?.map((sourceAttachment) => (
                      <Attachment
                        key={sourceAttachment.path}
                        attachment={sourceAttachment}
                        index={getIndexForAttachment(order, sourceAttachment)}
                        link
                        readonly={true}
                        showRemove={false}
                      />
                    ))
                : "Aucun Fichier"}
            </div>
          }
          right={
            <div>
              <p>
                <FormattedMessage id="Translator.TMX.PostTrad" />
              </p>
              {translatorUploads.filter(
                (a) => a.name.split(".").at(-1) === "tmx"
              )?.length > 0
                ? translatorUploads
                    .filter((a) => a.name.split(".").at(-1) === "tmx")
                    ?.map((sourceAttachment) => (
                      <Attachment
                        key={sourceAttachment.path}
                        attachment={sourceAttachment}
                        index={getIndexForAttachment(order, sourceAttachment)}
                        link
                        readonly={true}
                        showRemove={false}
                      />
                    ))
                : "Aucun Fichier"}
            </div>
          }
        />
      </Card>
    </>
  );
};

export default OrderTranslation;
