import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import request from 'superagent';
import {
  upload,
  uploadProgress,
  uploadError,
  uploadComplete,
} from './../common/redux/actions';
import { AccountPropType, OrderPropType } from './../common/proptypes';

import { getCurrentOrder } from './selectors';
import { getProfile } from '../account/selectors';
import UploadField from '../components/formfields/UploadField';

import styles from './Uploader.module.scss';

const handleUpload = async ({
  acceptedFiles,
  order,
  type,
  notifyUpload,
  notifyUploadProgress,
  notifyUploadComplete,
  notifyUploadError,
  user,
}) => {
  const cookie = await localStorage.getItem('feathers-jwt');

  acceptedFiles.forEach((file) => {
    // eslint-disable-next-line no-bitwise
    const nonce = [...Array(10)]
      .map(() => (~~(Math.random() * 36)).toString(36))
      .join('');
    const { name } = file;
    notifyUpload({
      name,
      percent: 0,
      error: null,
      words: null,
      path: null,
      uploading: true,
      nonce,
      authorId: user ? user._id : null,
      type,
    });

    request
      .post(`${process.env.REACT_APP_API_URL}/uploads`)
      .set('Authorization', cookie)
      .query({ $client: { orderId: order._id, type } })
      .attach('uri', file, name)
      .on('progress', (event) =>
        notifyUploadProgress({ name, percent: event.percent, nonce })
      )
      .then(
        ({ body }) =>
          notifyUploadComplete({
            name,
            words: body.words,
            path: body.id,
            nonce,
            detectedLanguage: body.detectedLanguage,
          }),
        (error) =>
          notifyUploadError({ name, error: error.response.body.message, nonce })
      );
  });
};

const Uploader = ({
  order,
  type,
  notifyUpload,
  notifyUploadProgress,
  notifyUploadComplete,
  notifyUploadError,
  user,
  uploadMessage,
  disabled,
}) => (
  <div className={styles.UploadContainer}>
    <UploadField
      className={styles.FileInfo}
      uploadMessage={uploadMessage}
      disabled={disabled}
      handleDrop={(acceptedFiles) =>
        handleUpload({
          acceptedFiles,
          order,
          type,
          notifyUpload,
          notifyUploadProgress,
          notifyUploadComplete,
          notifyUploadError,
          user,
        })
      }
    />
  </div>
);

Uploader.propTypes = {
  order: OrderPropType.isRequired,
  type: PropTypes.string.isRequired,
  notifyUpload: PropTypes.func.isRequired,
  notifyUploadProgress: PropTypes.func.isRequired,
  notifyUploadError: PropTypes.func.isRequired,
  notifyUploadComplete: PropTypes.func.isRequired,
  user: AccountPropType,
  uploadMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

Uploader.defaultProps = {
  user: null,
  uploadMessage: 'File.Drop',
};

const stateToProps = (state) => {
  const order = getCurrentOrder(state);
  const user = getProfile(state);

  return {
    order,
    user,
  };
};

const dispatchToProps = {
  notifyUpload: upload,
  notifyUploadProgress: uploadProgress,
  notifyUploadError: uploadError,
  notifyUploadComplete: uploadComplete,
};

export default connect(stateToProps, dispatchToProps)(Uploader);
