import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import styles from './Stats.module.scss';
import Card from './Card';

const Stats = ({
  user,
  orders,
  clients,
  translators,
  companies,
  invoices,
  availableLanguages,
  languagesList,
}) => {
  const showAvailableLanguages = availableLanguages?.map((el, i) => {
    return (
      <li key={i}>
        {' '}
        {
          languagesList[user.lang].filter((l) => l.key === el.iso6391Name)[0]
            .name
        }{' '}
        ->{' '}
        {
          languagesList[user.lang].filter(
            (l) => l.key === el.supportedDestinations
          )[0].name
        }{' '}
      </li>
    );
  });

  return (
    <>
      <Container>
        <Row xs="1" md="2" lg="3">
          <Col>
            <Card title="Admin.Orders">
              <Link to="/orders/"> {orders} in progress</Link>
            </Card>
          </Col>
          <Col>
            <Card title="Admin.Clients">
              <Link to="/clients/">
                Total:
                {clients.total}, Online:
                {clients.online}
              </Link>
            </Card>
          </Col>
          <Col>
            <Card title="Admin.Translators">
              <Link to="/translators/">
                Total:
                {translators.total}, Online:
                {translators.online}, Pending :
                {translators.languagesCouplesToValidate}, Push:
                {translators.push}
              </Link>
            </Card>
          </Col>
          <Col>
            <Card title="Settings.Invoices">
              <Link to="/invoices/">
                Total:
                {invoices.total}, Completed:
                {invoices.completed}
              </Link>
            </Card>
          </Col>
          <Col>
            <Card title="Admin.Companies">
              <Link to="/companies/">
                {companies} <FormattedMessage id="Admin.Companies" />
              </Link>
            </Card>
          </Col>
          <Col>
            <Card title="Admin.Languages">
              <div className={styles.LanguageContainer}>
                <ul>{showAvailableLanguages}</ul>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Stats;
