import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { find } from 'lodash';
import Card from '../components/Card';
import ActionLink from '../components/ActionLink';
import AccountCard from '../account/AccountCard';
import { isNotComplete } from './orderUtils';

const OrderOfferList = ({ order, clearOffers }) => {
  if (!order) return null;

  return (
    <Card
      title="Admin.Order.Offers"
      action={
        isNotComplete(order) ? (
          <ActionLink onClick={clearOffers}>
            <FormattedMessage id="Admin.Order.ClearOffers" />
          </ActionLink>
        ) : null
      }
    >
      <>
        {order.offeredToDetails.map((item) => (
          <AccountCard
            key={item._id}
            account={item}
            meta={moment(
              find(order.offeredTo, ['translator', item._id]).offeredAt
            ).calendar()}
          />
        ))}
      </>
    </Card>
  );
};

export default OrderOfferList;
