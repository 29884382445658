import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import isValidIBANNumber from '../utils/isValidIBANNumber';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AccountPropType,
  SupportedLanguagePropType,
} from './../common/proptypes';
import { updateUser } from './../common/redux/actions';

import { billTos, taxStatuses, discounts } from '../constants';
import Card from '../components/Card';
import TextField from '../components/formfields/TextField';
import ActionLink from '../components/ActionLink';
import CountryPickerField from '../components/formfields/CountryPickerField';
import TextareaField from '../components/formfields/TextareaField';
import SelectField from '../components/formfields/SelectField';
import { settings } from '../selectors';
import composeValidators from '../utils/composeValidators';
import ConditionalField from '../components/formfields/ConditionalField';
import { Button } from 'reactstrap';
import SliderField from '../components/formfields/SliderField';

const required = (value) => (value ? undefined : 'Forms.Required');
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Forms.InvalidEmail'
    : undefined;
const AccountForm = ({ account, supportedLanguages, doUpdateUser }) => {
  const intl = useIntl();

  const browserHistory = useHistory();
  const translator = account && account.role === 'translator';

  const discountOptions = discounts.map((discount) => ({
    value: `Order.DiscountReason.${discount}`,
    text: intl.formatMessage({ id: `Order.DiscountReason.${discount}` }),
  }));

  const languageOptions = () =>
    supportedLanguages.map((lang) => ({
      value: lang.iso6391Name,
      text: intl.formatMessage({ id: `Language.${lang.iso6391Name}` }),
    }));

  const billToOptions = () =>
    billTos.map((to) => ({
      value: `Account.BillTo.${to}`,
      text: intl.formatMessage({ id: `Account.BillTo.${to}` }),
    }));

  const validBillToSelection = (value) =>
    value && !billToOptions().find((o) => o.value === value)
      ? 'Forms.InvalidOption'
      : undefined;

  const taxStatusOptions = () =>
    Object.keys(taxStatuses).map((status) => ({
      value: status,
      text: intl.formatMessage({ id: `Translator.TaxStatus.${status}` }),
    }));

  const validTaxStatusSelection = (value) =>
    value && !taxStatusOptions().find((o) => o.value === value)
      ? 'Forms.InvalidOption'
      : undefined;
  const validateIban = (iban) =>
    iban && !isValidIBANNumber(iban) ? 'Forms.InvalidIban' : undefined;
  return (
    <Form
      onSubmit={(user) => doUpdateUser({ user, browserHistory })}
      initialValues={account}
    >
      {({ handleSubmit }) => (
        <>
          <Card
            title="User.Header"
            action={
              <ActionLink onClick={browserHistory.goBack}>
                <FormattedMessage id="Forms.Back" />
              </ActionLink>
            }
          >
            <>
              <Field
                name="accountId"
                disabled
                label="Account.AccountId"
                component={TextField}
              />
              <Field
                component={TextField}
                name="givenName"
                label="Account.GivenName"
                validate={required}
                showErrorBeforeTouched
              />
              <Field
                component={TextField}
                name="familyName"
                label="Account.FamilyName"
                validate={required}
                showErrorBeforeTouched
              />

              <Button onClick={handleSubmit}>
                <FormattedMessage id="Admin.Save" />
              </Button>
            </>
          </Card>
          {!!translator && (
            <Card>
              <>
                <Field
                  name="nationality"
                  component={CountryPickerField}
                  label="Account.Nationality"
                  validate={required}
                  parse={(option) => option.value}
                />
                <Field
                  component={CountryPickerField}
                  name="dualNationality"
                  label="Account.DualNationality"
                  parse={(option) => option.value}
                />
                <Field
                  component={TextField}
                  name="expertise"
                  label="Account.AreaOfExpertise"
                />

                <Field
                  component={CountryPickerField}
                  name="residence"
                  label="Account.Residence"
                  parse={(option) => option.value}
                />

                <Field
                  component={TextField}
                  keyboardType="numeric"
                  name="yearsOfExperience"
                  label="Account.YearsOfExperience"
                />

                <Field
                  name="bio"
                  component={TextareaField}
                  label="Account.Bio"
                  multiline
                />
              </>
            </Card>
          )}

          <Card title="Account.EssentialContact">
            <>
              <Field
                component={TextField}
                name="email"
                label="Account.Email"
                validate={email}
                showErrorBeforeTouched
              />

              {!translator && (
                <>
                  <Field
                    component={SelectField}
                    name="billTo"
                    label="Account.BillTo"
                    options={billToOptions()}
                    validate={composeValidators(required, validBillToSelection)}
                  />

                  <ConditionalField when="billTo" is="Account.BillTo.Company">
                    <Field
                      component={TextField}
                      name="billingName"
                      label="Account.BillingName"
                      validate={required}
                      showErrorBeforeTouched
                    />
                  </ConditionalField>
                  <Field
                    name="billingCountry"
                    label="Billing.Country"
                    component={CountryPickerField}
                    parse={(option) => option.value}
                  />

                  <Field
                    name="billingVAT"
                    label="Billing.VAT"
                    component={TextField}
                  />

                  <Field
                    name="discountReason"
                    label="Account.DiscountReason"
                    component={SelectField}
                    options={discountOptions}
                    placeholder="Order.DiscountReason.None"
                  />

                  <ConditionalField when="discountReason" not="">
                    <Field
                      name="discount"
                      label="Account.Discount"
                      component={SliderField}
                      min={0.01}
                      max={0.3}
                      step={0.01}
                      output={(value) =>
                        parseFloat(value * 100).toFixed(0) + '%'
                      }
                    />
                  </ConditionalField>
                </>
              )}
            </>
          </Card>

          <Card title="Language">
            <Field
              component={SelectField}
              name="lang"
              label="Settings.DisplayLanguage"
              options={languageOptions()}
            />
          </Card>
          {!translator && (
            <Card title="Account.Context">
              <>
                <Field
                  name="jobTitle"
                  component={TextField}
                  label="Account.JobTitle"
                />
                <Field
                  component={TextField}
                  name="department"
                  label="Account.Department"
                />

                <Field
                  component={TextField}
                  name="sector"
                  label="Account.Sector"
                />
              </>
            </Card>
          )}

          {!!translator && (
            <>
              <Card title="Account.Billing">
                <>
                  <Field
                    component={SelectField}
                    name="taxStatus"
                    label="Translator.TaxStatus"
                    options={taxStatusOptions()}
                    validate={composeValidators(
                      required,
                      validTaxStatusSelection
                    )}
                    showErrorBeforeTouched
                  />

                  <ConditionalField
                    when="taxStatus"
                    not="FrenchMicroEntrepreneur"
                  >
                    <>
                      <Field
                        key="company"
                        component={TextField}
                        name="company"
                        label="Account.CompanyName"
                      />
                      <Field
                        key="companyNumber"
                        component={TextField}
                        name="companyNumber"
                        label="Account.CompanyNumber"
                      />
                    </>
                  </ConditionalField>

                  <Field
                    component={TextareaField}
                    name="invoiceHeaderDetail"
                    label="User.InvoiceHeaderDetails"
                  />
                  <Field
                    component={TextareaField}
                    name="invoiceFooterDetail"
                    label="User.InvoiceFooterDetail"
                  />
                </>
              </Card>

              <Card title="Account.translatorBankDetails.title">
                <Field
                  component={TextField}
                  name="translatorBankDetails.accountName"
                  label="Account.translatorBankDetails.accountName"
                  validate={required}
                  showErrorBeforeTouched
                  initialValue={
                    account?.translatorBankDetails?.accountName
                      ? account.translatorBankDetails.accountName
                      : undefined
                  }
                />

                <Field
                  component={TextField}
                  name="translatorBankDetails.accountEmail"
                  label="Account.translatorBankDetails.accountEmail"
                  validate={composeValidators(required, email)}
                  showErrorBeforeTouched
                  initialValue={
                    account?.translatorBankDetails?.accountEmail
                      ? account.translatorBankDetails.accountEmail
                      : undefined
                  }
                />

                <Field
                  component={TextField}
                  name="translatorBankDetails.iban"
                  label="Account.translatorBankDetails.iban"
                  validate={validateIban}
                  showErrorBeforeTouched
                  initialValue={
                    account?.translatorBankDetails?.iban
                      ? account.translatorBankDetails.iban
                      : undefined
                  }
                />
              </Card>
            </>
          )}
        </>
      )}
    </Form>
  );
};

AccountForm.propTypes = {
  account: AccountPropType.isRequired,
  supportedLanguages: PropTypes.arrayOf(SupportedLanguagePropType).isRequired,
};

const mapStateToProps = (state) => ({
  supportedLanguages: settings(state).supportedLanguages,
});

const mapDispatchToProps = {
  doUpdateUser: updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);
