import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import UserIsAuthenticated from './UserIsAuthenticated';

import HomePage from '../pages/HomePage';
import OrdersPage from '../orders/OrdersPage';
import ViewOrderPage from '../orders/ViewOrderPage';
import Login from '../pages/Login';
import CompaniesPage from '../pages/CompaniesPage';
import EditCompanyPage from '../pages/EditCompanyPage';
import AppSettingsPage from '../pages/AppSettingsPage';
import TranslatorsPage from '../pages/TranslatorsPage';
import ClientsPage from '../pages/ClientsPage';
import InvoicesPage from '../pages/InvoicesPage/InvoicesPage';
import Navigation from './Navigation';
import Header from './Header';

import style from './App.module.scss';
import FullScreenLogin from './FullScreenLogin';
import ExportPage from '../pages/ExportPage';
import EditAccountPage from '../pages/EditAccountPage';

const App = ({ user, settings }) => (
  <>
    {user && settings && (
      <div className={style.App}>
        <Navigation className={style.Navigation} />
        <Header />
        <div className={style.Content}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route
              exact
              path="/orders/"
              component={UserIsAuthenticated(OrdersPage)}
            />
            <Route
              exact
              path={['/orders/:id', '/view/:id']}
              component={UserIsAuthenticated(ViewOrderPage)}
            />
            <Route
              exact
              path="/companies"
              component={UserIsAuthenticated(CompaniesPage)}
            />
            <Route
              exact
              path="/companies/:id"
              component={UserIsAuthenticated(EditCompanyPage)}
            />
            <Route
              exact
              path="/app-settings"
              component={UserIsAuthenticated(AppSettingsPage)}
            />
            <Route
              exact
              path="/translators"
              component={UserIsAuthenticated(TranslatorsPage)}
            />
            <Route
              path="/translators/:id"
              component={UserIsAuthenticated(EditAccountPage)}
            />
            <Route
              exact
              path="/clients"
              component={UserIsAuthenticated(ClientsPage)}
            />
            <Route
              path="/clients/:id"
              component={UserIsAuthenticated(EditAccountPage)}
            />
            <Route
              exact
              path="/invoices"
              component={UserIsAuthenticated(InvoicesPage)}
            />
            <Route
              exact
              path="/export"
              component={UserIsAuthenticated(ExportPage)}
            />
          </Switch>
        </div>
      </div>
    )}
    {user && !settings && 'Loading....'}
    {!user && (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route component={FullScreenLogin} />
      </Switch>
    )}
  </>
);

const stateToProps = (state) => ({
  user: state.auth.user,
  settings: get(state, 'settings.store.records', false),
});

export default withRouter(connect(stateToProps)(App));
