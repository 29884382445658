import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './CanTranslate.module.scss';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export const LanguageCouple = ({ couple }) => {
  return (
    <strong>
      <FormattedMessage id={`Language.${couple.couple.split('-')[0]}`} />{' '}
      <FontAwesomeIcon className={`${styles.icon} small`} icon={faArrowRight} />{' '}
      <FormattedMessage id={`Language.${couple.couple.split('-')[1]}`} />
    </strong>
  );
};
