import feathers from '@feathersjs/client';
import reduxifyServices, { getServicesStatus } from 'feathers-redux';
import reduxifyAuthentication from 'feathers-reduxify-authentication';
import io from 'socket.io-client';
import Realtime from 'feathers-offline-realtime';

const socket = io("https://api.mybrian.fr");

// Configure feathers-client
const app = feathers()
  .configure(feathers.socketio(socket, { timeout: 200000 }))
  .configure(
    feathers.authentication({
      storage: localStorage, // store the token in localStorage and initially sign in with that
    })
  );
export default app;

// Customise processing in feathers/index.js for your app

// See feathers-reduxify-services::default
export const mapServicePathsToNames = {
  settings: 'settings',
  users: 'users',
  orders: 'orders',
  messages: 'messages',
  notifications: 'notifications',
  companies: 'companies',
  invoices: 'invoices',
  uploads: 'uploads',
  languagecouples: 'languagecouples',
  specialities: 'specialities',
  redundancy: "redundancy"
};

// See feathers-reduxify-services::getServicesStatus. Order highest priority msg first.
export const prioritizedListServices = [
  'settings',
  'auth',
  'users',
  'orders',
  'messages',
  'notifications',
  'languagecouples',
  'specialities',
  'companies',
  'invoices',
  'uploads',
    "redundancy"
];

// Reduxify feathers-authentication
export const feathersAuthentication = reduxifyAuthentication(app);

// Reduxify feathers services
export const feathersServices = reduxifyServices(app, mapServicePathsToNames);

// Convenience method to get status of feathers services, incl feathers-authentication
export const getFeathersStatus = (
  servicesRootState,
  names = prioritizedListServices
) => getServicesStatus(servicesRootState, names);

const realtimeSubscriptions = [];
const orderStatuses = [
  'Order.Status.DraftQuote',
  'Order.Status.Quoted',
  'Order.Status.Unassigned',
  'Order.Status.InProgress',
  'Order.Status.Suspended',
  'Order.Status.Cancelled',
  'Order.Status.Complete',
];

const publications = {
  orders: (order) => orderStatuses.includes(order.status),
};
const queries = {
  // orders: { status: { $in: orderStatuses }, createdAt: {$lte} },
  orders: { status: { $in: orderStatuses } },
  invoices: { $sort: { createdAt: -1 } },
};

export const subscribe = (store) => {
  Object.keys(mapServicePathsToNames).forEach((s) => {
    subscribeOne(s, store);
  });
};

export const subscribeOne = (s, store) => {
  const service = app.service(s);

  const SERVICE_NAME = `SERVICES_${mapServicePathsToNames[s].toUpperCase()}_`;

  service.on('created', (data) =>
    store.dispatch({
      type: `${SERVICE_NAME}CREATE_RECEIVED`,
      payload: data,
    })
  );

  service.on('updated', (data) =>
    store.dispatch({
      type: `${SERVICE_NAME}UPDATE_RECEIVED`,
      payload: data,
    })
  );

  service.on('patched', (data) =>
    store.dispatch({
      type: `${SERVICE_NAME}PATCH_RECEIVED`,
      payload: data,
    })
  );

  service.on('removed', (data) =>
    store.dispatch({
      type: `${SERVICE_NAME}REMOVE_RECEIVED`,
      payload: data,
    })
  );

  if (!['uploads'].includes(s)) {
    const subscription = new Realtime(service, {
      publication: publications[s] ? publications[s] : null,
      query: queries[s] ? queries[s] : null,
      subscriber: (records, last) => {
        store.dispatch(
          feathersServices[mapServicePathsToNames[s]].store({
            connected: subscription.connected,
            last,
            records,
          })
        );
      },
    });
    realtimeSubscriptions.push(subscription);
  }
};
export const connect = () => {
  realtimeSubscriptions.forEach((sub) => sub.connect());
};
export const connectOne = (service) => {
  const sub = realtimeSubscriptions.find(
    (sub) => sub._service.path === service
  );
  if (sub) {
    sub.connect();
  }
};
export const disconnect = () => {
  realtimeSubscriptions.forEach((sub) => sub.disconnect());
};
