import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import { useIntl } from "react-intl";

import { FormGroup, Label, Input } from "reactstrap";
import Help from "./Help";

const ToggleField = ({
  input,
  label,
  tooltip,
  meta: { error, warning },
  disabled
}) => {
  const intl = useIntl();
  const state = error ? "error" : warning ? "warning" : null;
  const translatedLabel = label ? intl.formatMessage({ id: label }) : "";

  return (
    <FormGroup>
      <Label>{translatedLabel}</Label>
      {tooltip && <Help input={input.name} text={tooltip} />}
      {disabled ? (
        <Input id={input.name} type="text" readOnly >{input.value}</Input>
      ) : (
          <Toggle onChange={input.onChange} checked={!!input.value} />
        )}
      {state && (
        <Label className={state}>{error || warning || ""}</Label>
      )}
    </FormGroup>
  );
};

ToggleField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.object,
  meta: PropTypes.object
};

export default ToggleField;
