import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import style from "./NavigationItem.module.scss";

const NavigationItem = props => {
  const { to, title, notification } = props;
  return (
    <li>
      <NavLink
        to={to}
        className={style.Link}
        activeClassName={style.ActiveLink}
      >
        <>
          <FormattedMessage className={style.Text} id={title} />
          {!!notification && " *"}
        </>
      </NavLink>
    </li>
  );
};

NavigationItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  notification: PropTypes.bool
};

NavigationItem.defaultProps = {
  notification: false
};

export default NavigationItem;
