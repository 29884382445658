import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import Card from '../components/Card';

import OrdersPageSubpageQuoted from './OrdersPageSubpageQuoted';
import OrdersPageSubPageInProgress from './OrdersPageSubPageInProgress';
import OrdersPageSubPageCompleted from './OrdersPageSubPageCompleted';
import OrdersPageSubPageCancelled from './OrdersPageSubPageCancelled';
import { FormattedMessage } from 'react-intl';
import LimitOnCreatedAt from '../components/LimitOnCreatedAt';

const OrdersPage = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container>
      <Card title="Admin.Orders">
        <Row>
          <Col>
            <Row>
              <Col>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      <FormattedMessage id="Admin.InProgress" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      <FormattedMessage id="Admin.Completed" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        toggle('3');
                      }}
                    >
                      <FormattedMessage id="Admin.Cancelled" />
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === '4',
                      })}
                      onClick={() => {
                        toggle('4');
                      }}
                    >
                      <FormattedMessage id="Admin.Quotes" />
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col xs="3" className="text-right">
                <LimitOnCreatedAt></LimitOnCreatedAt>
              </Col>
            </Row>

            <TabContent activeTab={activeTab}>
              <Card>
                <TabPane tabId="1">
                  <Row>
                    <Col>
                      {activeTab === '1' && <OrdersPageSubPageInProgress />}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col>
                      {activeTab === '2' && <OrdersPageSubPageCompleted />}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col>
                      {activeTab === '3' && <OrdersPageSubPageCancelled />}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col>
                      {activeTab === '4' && <OrdersPageSubpageQuoted />}
                    </Col>
                  </Row>
                </TabPane>
              </Card>
            </TabContent>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default OrdersPage;
