import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';
import middleware, { sagaMiddleware } from './middleware';
import sagas from './sagas';
import { subscribe } from './feathers';
import { formActionSaga } from './utils/ReduxFormSaga';

export default function configureStore(initialState) {
  const store = createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(sagas);
  sagaMiddleware.run(formActionSaga);
  subscribe(store);

  return store;
}
