import moment from 'moment-timezone';
import { timezone } from '../constants';

function round(duration, by, method) {
  return moment.duration(Math[method](+duration / +by) * +by);
}

export default class timeCalculator {
  constructor({
    dayStart,
    dayEnd,
    skipWeekend,
    extendBy,
    searchTime,
    extendAfter,
    minLength,
    increments,
  }) {
    this.dayStart = dayStart;
    this.dayEnd = dayEnd;
    this.skipWeekend = skipWeekend;
    this.extendBy = extendBy;
    this.searchTime = searchTime;
    this.extendAfter = extendAfter;
    this.minLength = minLength;
    this.increments = increments;
  }

  getHoursInDay = () => {
    const startOfDay = moment.tz(timezone).set(this.dayStart);
    const endOfDay = moment.tz(timezone).set(this.dayEnd);

    return moment.duration(endOfDay.diff(startOfDay)).asHours();
  };

  /**
   * Adds time, taking in consideration for business hours
   * @param time - the start time
   * @param amount - the amount to add
   * @returns {*}
   */
  addBusinessHours = (time = null, amount = null) => {
    time = time ? moment.tz(time, timezone) : moment.tz(timezone);
    // time.startOf('minute'); // we're not going to care about seconds yet
    const startOfDay = time.clone().set(this.dayStart);
    let endOfDay = time.clone().set(this.dayEnd);

    const hoursInDay = this.getHoursInDay();
    // Move out of the weekend
    if (time.isoWeekday() >= 6 && this.skipWeekend) {
      time = time.add({ days: 8 - time.isoWeekday() });
    }

    // Normalise the start time to French working hours
    if (time.isBefore(startOfDay)) {
      time = startOfDay;
    }
    if (time.isSameOrAfter(endOfDay)) {
      time = startOfDay.clone().add({ days: 1 });
      if (time.isoWeekday() >= 6 && this.skipWeekend) {
        time = time.add({ days: 8 - time.isoWeekday() });
      }
    }
    // Now add the time to it, and check the hours again

    if (amount) {
      amount = moment.duration(amount);
      while (amount.asHours() >= hoursInDay) {
        time.add({ days: 1 });
        if (time.isoWeekday() >= 6 && this.skipWeekend) {
          time = time.add({ days: 8 - time.isoWeekday() });
        }
        amount.subtract({ hours: hoursInDay });
      }
      time.add(amount);
    }

    endOfDay = time.clone().set(this.dayEnd);

    if (time.isAfter(endOfDay)) {
      time = this.addBusinessHours(time, moment.duration(time.diff(endOfDay)));
    }
    // If it's a weekend, add some days.
    if (time.isoWeekday() >= 6 && this.skipWeekend) {
      time = time.add({ days: 8 - time.isoWeekday() });
    }

    return time;
  };

  getEarliestTime = (words, time = null) => {
    // combine the start delay and the time it takes to translate
    const duration = moment.duration({
      hours: words * this.extendBy + this.searchTime,
    });
    // round up to the nearest half hour and fit them into business time
    const earliestTime = this.addBusinessHours(
      time,
      round(duration, moment.duration(30, 'minutes'), 'ceil')
    );
    return earliestTime;
  };

  // Indicate the number of business hours to fulfil the order
  getBusinessHours = (words, time) => {
    const leadTime = words * this.extendBy + this.searchTime || 0;
    const workTime = 0.5 * time;
    return Math.round((workTime + leadTime) * 10) / 10;
  };

  getMaxDuration = (words) => {
    const hours =
      words > this.extendAfter ? (words - this.extendAfter) * this.extendBy : 0;
    const maxDuration = moment.duration({
      hours: Math.ceil(hours) + this.minLength * this.getHoursInDay(),
    });
    return maxDuration;
  };

  getLatestTime = (words, time = null) => {
    const earliestTime = this.getEarliestTime(words, time);
    const duration = this.getMaxDuration(words);
    return this.addBusinessHours(earliestTime, duration);
  };

  /**
   * compte le nb d'incréments
   * @param {*} words
   * @param {*} time
   * @returns le nombre de steps entre temps minimum calculé  et temps max :
   */
  generateMarks = (words, time = null) => {
    let currentTime = this.getEarliestTime(words, time);
    const latestTime = this.getLatestTime(words, time);
    const marks = { 0: '' };
    let steps = 0;
    do {
      const nextTime = this.addBusinessHours(currentTime, {
        minutes: this.increments,
      });
      steps += 1;
      currentTime = nextTime;
    } while (currentTime.isSameOrBefore(latestTime));
    steps -= 1;
    marks[steps] = '';
    return { marks, max: steps };
  };

  /**
   * A partir du n° d'incréments, retourne la date
   * @param {*} increments
   * @param {*} time
   * @returns la date
   */
  timeFromIncrements = (increments, time = null) => {
    while (increments > 0) {
      time = this.addBusinessHours(time, { minutes: this.increments });
      increments -= 1;
    }
    return time;
  };

  /**
   *
   * @param {*} deadline : date choisie au curseur
   * @param {*} words : nb de mots
   * @param {*} time
   * @returns
   */
  mustStartTime = (deadline, words, time = null) => {
    deadline = this.addBusinessHours(deadline);
    time = this.addBusinessHours(time);
    let endOfDay = time.clone().set(this.dayEnd);
    const hoursInDay = this.getHoursInDay();

    const mst = moment.duration();
    while (endOfDay.isBefore(deadline)) {
      mst.add(endOfDay.diff(time));
      time = this.addBusinessHours(time.clone().set(this.dayStart), {
        hours: hoursInDay,
      });
      endOfDay = time.clone().set(this.dayEnd);
    }
    mst.add(deadline.diff(time));

    return mst.subtract({
      hours: words * this.extendBy + this.searchTime,
    });
  };
}
