import React from "react";
import { CompanyPropType } from "./../common/proptypes";
import { Link } from "react-router-dom";

import style from "./CompanyLink.module.scss";

const CompanyLink = ({ company }) => (
  <div className={style.Link}>
    <Link to={`/companies/${company._id}`}>{company.name}</Link>
  </div>
);

CompanyLink.propTypes = {
  company: CompanyPropType.isRequired,
};

export default CompanyLink;
