import { filter, find, get, sortBy } from "lodash";

export const getInvoiceForCompany = (state, companyId) =>
  find(
    sortBy(getInvoices(state), ["createdAt"]),
    (s) => s.companyId === companyId && s.status !== "Sent"
  );

export const getInvoices = (state) => get(state, "invoices.store.records", []);
export const getCompletedInvoices = (state) =>
  filter(getInvoices(state), (i) => i.status === "Sent");

export const getTranslatorInvoices = (state) =>
  filter(getCompletedInvoices(state), (i) => i.translatorInvoiceId);

export const getClientInvoices = (state) =>
  filter(getCompletedInvoices(state), (i) => !i.translatorInvoiceId);

export const getInvoice = (state, _id) =>
  find(get(state, "invoices.store.records", []), { _id });
