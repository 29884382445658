import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CompanyPropType } from './../common/proptypes';

import CompanyForm from '../companies/CompanyForm';
import { findCompanyById } from '../companies/selectors';

const EditCompanyPage = ({ company }) => <CompanyForm company={company} />;

EditCompanyPage.propTypes = {
  company: CompanyPropType,
};

EditCompanyPage.defaultProps = {
  company: null,
};

const stateToProps = (state, ownProps) => ({
  company: findCompanyById(state, ownProps.match.params.id),
});

export default withRouter(connect(stateToProps)(EditCompanyPage));
