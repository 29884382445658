import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import style from "./Card.module.scss";

const Card = ({ title, titleData, children, action, fullWidth, id }) => (
  <div className={fullWidth ? style.FullWidthCard : style.Card} id={id}>
    {!!(title || action) && (
      <div className={style.Title}>
        {!!title && <FormattedMessage id={title} values={titleData} />}
        {!!action && <div className={style.Action}>{action}</div>}
      </div>
    )}
    <div className={style.Content}>{children}</div>
  </div>
);

Card.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  titleData: PropTypes.object,
  action: PropTypes.element,
  children: PropTypes.any,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
};

Card.defaultProps = {
  title: null,
  titleData: null,
  fullWidth: false,
  action: null,
  id: null,
  children: null,
};

export default Card;
