import React from "react";
import PropTypes from "prop-types";

import { useIntl } from "react-intl";

import { FormGroup, Label, Input } from "reactstrap";
import Help from "./Help";

const TextField = ({
  input,
  label,
  placeholder,
  tooltip,
  meta: { error, warning },
  disabled,
}) => {
  const intl = useIntl();
  const state = error ? "error" : warning ? "warning" : null;
  const translatedError = error ? intl.formatMessage({ id: error }) : null;
  const translatedWarning = warning
    ? intl.formatMessage({ id: warning })
    : null;
  const translatedPlaceholder = placeholder
    ? intl.formatMessage({ id: placeholder })
    : "";
  const translatedLabel = label ? intl.formatMessage({ id: label }) : "";

  return (
    <FormGroup>
      <Label>{translatedLabel}</Label>
      {tooltip && <Help input={input.name} text={tooltip} />}
      {disabled ? (
        <Input id={input.name} readOnly type="text" {...input} />
      ) : (
          <Input
            type="text"
            placeholder={translatedPlaceholder}
            {...input}
          />
        )}
      {state && (
        <Label className={state}>
          {translatedError || translatedWarning || ""}
        </Label>
      )}
    </FormGroup>
  );
};

TextField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.object,
  meta: PropTypes.object,
};

export default TextField;
