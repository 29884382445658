import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { FormattedMessage } from "react-intl";
import styles from "./SplitPane.module.scss";

const SplitPane = ({ left, right, leftTitle, rightTitle, centerline }) => (
  <div
    className={classnames({
      [styles.Container]: true,
      [styles.Centerline]: centerline
    })}
  >
    <div className={styles.Left}>
      <>
        {(leftTitle || rightTitle) && (
          <div className={styles.Header}>
            {leftTitle && <FormattedMessage id={leftTitle} />}
          </div>
        )}
        <div className={styles.LeftContent}>{left}</div>
      </>
    </div>
    {right && (
      <div className={styles.Right}>
        <>
          {(leftTitle || rightTitle) && (
            <div className={styles.Header}>
              {rightTitle && <FormattedMessage id={rightTitle} />}
            </div>
          )}
          <div className={styles.RightContent}>{right}</div>
        </>
      </div>
    )}
  </div>
);

SplitPane.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  centerline: PropTypes.bool
};

SplitPane.defaultProps = {
  left: null,
  right: null,
  leftTitle: null,
  rightTitle: null,
  centerline: false
};

export default SplitPane;
