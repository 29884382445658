import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { Button, Container, Row, Col } from 'reactstrap';

const UpdateSettings = ({
  settingsToUpdate,
  settingsType,
  handleUpdateSettings,
}) => {
  const [inputValues, setInputValues] = useState(settingsToUpdate);
  const handleChange = (target) => {
    const arr = inputValues.map((el) =>
      el.key === target.key
        ? {
            ...el,
            value: target.type === 'number' ? target.value * 1 : target.value,
          }
        : el
    );
    console.log(arr);
    setInputValues(arr);
  };
  const handleChangeArrayOfObjects = (target) => {
    let values = inputValues[0].value;
    values[target.index][target.key] = target.value;
    const newObj = { ...inputValues[0], value: values };
    setInputValues([newObj]);
  };

  const showArrayOfObjectsFields = (item, idx) => {
    const objLength = Object.entries(item).length;
    const col = Math.round(12 / (objLength || 1));
    const fields = Object.entries(item).map(([key, value]) => {
      return (
        <Row key={`${idx}-${key}`} className="p-1">
          <Col xs={col}>{key}</Col>
          <Col xs="auto">
            <input
              name={key}
              value={value || 0}
              className="form-control"
              onChange={(e) =>
                handleChangeArrayOfObjects({
                  index: idx,
                  key: key,
                  value: e.target.value,
                })
              }
            ></input>
          </Col>
        </Row>
      );
    });
    return fields;
  };
  return (
    <Container>
      <Form
        onSubmit={() => {
          return handleUpdateSettings(inputValues);
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {settingsType === 'single' &&
              inputValues.map((set) => (
                <Row className="align-items-center my-1" key={set._id}>
                  <Col xs="6">
                    <p>{set.description} </p>
                  </Col>
                  <Col xs="auto">
                    <input
                      key={set._id}
                      type={set.type}
                      name={set.key}
                      value={set.value || 0}
                      className="form-control"
                      onChange={(e) =>
                        handleChange({
                          key: set.key,
                          value: e.target.value,
                          type: set.type,
                        })
                      }
                    ></input>
                  </Col>
                </Row>
              ))}

            {/* Cas d'un tableau d'objets à updater */}
            {settingsType === 'arrayOfObjects' &&
              inputValues.map((set) => (
                <div key={set._id}>
                  <Row className="align-items-center my-1">
                    <Col xs="12">
                      <p>{set.description}</p>
                    </Col>
                  </Row>
                  {set.value.length &&
                    set.value.map((item, idx) => (
                      <Row key={idx} className="bg-light my-1">
                        <Col xs="1" className="align-self-center">
                          {idx}
                        </Col>
                        <Col xs="11">{showArrayOfObjectsFields(item, idx)}</Col>
                      </Row>
                    ))}
                </div>
              ))}
            <Row>
              <Col xs="12" className="my-2 text-center">
                <Button color="primary" type="submit" disabled={submitting}>
                  <FormattedMessage id="Forms.Submit" />
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Form>
    </Container>
  );
};

export default UpdateSettings;
