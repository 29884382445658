import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-intl-redux";
import { initializeFileTypeIcons } from "@uifabric/file-type-icons";

import "babel-polyfill"; /* eslint-disable-line import/default */
import moment from "moment-timezone";

import { feathersAuthentication } from "./feathers"; // does feathers init

import * as settings from "./constants";

import configureStore from "./store";
import App from "./components/App";

import './style/index.scss';
/* global document */
/* global localStorage */

// eslint-disable-next-line prefer-destructuring
const locale = settings.locale;
const { format } = settings.localeFormatTweaks[locale] || undefined;
moment.locale(locale, format);

export const store = configureStore();
initializeFileTypeIcons();

if (localStorage.getItem("feathers-jwt")) {
  store.dispatch(feathersAuthentication.authenticate());
}

function run() {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
}

run();
