import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrderLink from './OrderLink';
import { getCompletedOrders } from './selectors';
import Card from '../components/Card';

const OrdersPageSubPageCompleted = ({ completedOrders }) => {
  return (
    <Card title="CustomerHomepage.CompletedTranslations" fullWidth>
      <>
        {completedOrders &&
          completedOrders.map((order) => (
            <OrderLink key={order._id} order={order} />
          ))}
      </>
    </Card>
  );
};

OrdersPageSubPageCompleted.propTypes = {
  completedOrders: PropTypes.array.isRequired,
};

OrdersPageSubPageCompleted.defaultProps = {};

const stateToProps = (state) => ({
  completedOrders: getCompletedOrders(state),
});

export default connect(stateToProps)(OrdersPageSubPageCompleted);
