import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { useIntl, FormattedMessage } from "react-intl";
import styles from "./UploadField.module.scss";

import fileImg from "../../img/upload-cloud.png";

const UploadField = ({
  accept,
  className,
  handleDrop,
  uploading,
  progress,
  disabled,
  uploadMessage
}) => {
  const intl = useIntl();
  return (
    <Dropzone accept={accept} onDrop={handleDrop} disabled={disabled}>
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
        <div
          className={`${className} ${isDragAccept ? styles.Accept : ""} ${
            isDragReject ? styles.Reject : ""
          }`}
          {...getRootProps()}
        >
          {uploading ? (
            <div className={styles.UploadingContainer}>
              <div className={styles.ProgressHolder}>
                <span
                  className={styles.ProgressBar}
                  style={{ width: `${progress}%` }}
                />
              </div>
              <div className={styles.Uploading}>
                <FormattedMessage id="Order.Uploading" />
              </div>
            </div>
          ) : (
            <>
              <input {...getInputProps()} accept={accept} />
              <div className={styles.DragPrompt}>
                <img
                  src={fileImg}
                  alt={intl.formatMessage({ id: "File" })}
                  height={30}
                />
                <p>
                  <FormattedMessage id={uploadMessage} />
                </p>
              </div>
            </>
          )}
        </div>
      )}
    </Dropzone>
  );
};

UploadField.propTypes = {
  accept: PropTypes.string,
  handleDrop: PropTypes.func,
  uploading: PropTypes.bool,
  progress: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  uploadMessage: PropTypes.string
};

UploadField.defaultProps = {
  accept: "",
  handleDrop: () => {},
  uploading: false,
  progress: 0,
  disabled: false,
  uploadMessage: "File.Drop",
  className: "",
  children: null
};
export default UploadField;
