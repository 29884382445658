import React from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import moment from 'moment-timezone';

const OrderInvoiceSummary = ({
  count, orderId, words, pay, completedAt,
}) => (
  <tr className={(count % 2) ? 'even' : 'odd'}>
    <td>{orderId}</td>
    <td>{words}</td>
    <td>{accounting.formatMoney(pay, '€', 2, '.', ',')}</td>
    <td>{moment(completedAt).calendar()}</td>
  </tr>
);

OrderInvoiceSummary.propTypes = {
  count: PropTypes.number.isRequired,
  orderId: PropTypes.string.isRequired,
  words: PropTypes.number.isRequired,
  pay: PropTypes.number.isRequired,
  completedAt: PropTypes.string.isRequired,
};

export default OrderInvoiceSummary;
