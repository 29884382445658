Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports["default"] = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var _default = _propTypes["default"].shape({
  iso6391Name: _propTypes["default"].string,
  dialects: _propTypes["default"].arrayOf(_propTypes["default"].string),
  supportedDestinations: _propTypes["default"].arrayOf(
    _propTypes["default"].string
  ),
});

exports["default"] = _default;
