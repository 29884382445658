import React from 'react';

import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';

import Card from '../components/Card';

const OrderTimings = ({ order }) => {
  if (!order) return null;

  return (
    <Card title="Order.TimingDetails">
      <dl>
        <dt><FormattedMessage id="Order.ConfirmedAt" /></dt>
        <dd>{moment(order.confirmedAt).calendar()}</dd>

        {order.status === 'Order.Status.Unassigned' && (
          <React.Fragment>
            <dt><FormattedMessage id="Order.ShouldAssignBy" /></dt>
            <dd>{moment(order.shouldAssignBy).calendar()}</dd>
          </React.Fragment>
        )}

        <dt><FormattedMessage id="Order.Due" /></dt>
        <dd>{moment(order.deadline).calendar()}</dd>

        {order.status === 'Order.Status.Complete' && (
          <React.Fragment>
            <dt><FormattedMessage id="Invoice.Completed" /></dt>
            <dd>{moment(order.completedAt).calendar()}</dd>
          </React.Fragment>
        )}
      </dl>
    </Card>
  );
};

export default OrderTimings;
