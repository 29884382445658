import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";

const ConditionalField = ({ when, condition, is, not, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => {
      if (condition !== undefined) {
        return condition(value) ? children : null;
      }
      if (is !== undefined) {
        return is === value ? children : null;
      }
      return not !== value ? children : null;
    }}
  </Field>
);

ConditionalField.propTypes = {
  when: PropTypes.string.isRequired,
  condition: PropTypes.func,
  is: PropTypes.any,
  not: PropTypes.any,
  children: PropTypes.element.isRequired,
};

ConditionalField.defaultProps = {
  condition: undefined,
  is: undefined,
  not: undefined,
};
export default ConditionalField;
