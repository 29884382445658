import React, { useState } from 'react';
// import Modal from 'react-modal';
import { Row, Col, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import styles from './ConfirmationTrigger.module.scss';

const ConfirmationTrigger = ({
  title = 'Confirmation : are you sure ?',
  text = 'explanation of the action',
  yes = 'yes',
  no = 'no',
  actionYes = null,
  actionNo = null,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  let innerHTML = null;

  const actionYesFct = () => {
    closeModal();
    if (actionYes) {
      actionYes();
    }
  };
  const actionNoFct = () => {
    closeModal();
    if (actionNo) {
      actionNo();
    }
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className={styles.cross} onClick={openModal}>
        {innerHTML}
      </div>
      <Modal isOpen={modalIsOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>{title}</ModalHeader>
        <ModalBody>
          <Row className="my-3">
            <Col>
              <p> {text}</p>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 1 }} xs="6" className="text-center">
              <Button color="primary" onClick={actionYesFct}>
                {' '}
                {yes}
              </Button>
            </Col>
            <Col md={{ size: 4, offset: 2 }} xs="6" className="text-center">
              <Button onClick={actionNoFct}>{no}</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ConfirmationTrigger;
