import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import accounting from 'accounting';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClientLookup from '../components/ClientLookup';
import { discounts, canBeExcludedFromTax } from '../constants';
import feathers from '../feathers';
import TextAreaField from '../components/formfields/TextareaField';
import TextField from '../components/formfields/TextField';
import ToggleField from '../components/formfields/ToggleField';
import SelectField from '../components/formfields/SelectField';
import AccountCard from '../account/AccountCard';
import CountryPickerField from '../components/formfields/CountryPickerField';

import OrderInvoiceSummary from '../invoices/OrderInvoiceSummary';
import { getInvoiceForCompany } from '../invoices/selectors';
import { updateCompany, submitInvoice } from '../actions';
import JsonField from '../components/formfields/JsonField';
import Card from '../components/Card';
import ConditionalField from '../components/formfields/ConditionalField';
import SliderField from '../components/formfields/SliderField';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from 'reactstrap';
const required = (value) => (value ? undefined : 'Forms.Required');
const CompanyForm = ({
  invoice,
  doSubmitInvoice,
  doUpdateCompany,
  company,
}) => {
  const users = feathers.service('users');
  const intl = useIntl();
  const history = useHistory();
  const [members, setMembers] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const discountOptions = discounts.map((discount) => ({
    value: `Order.DiscountReason.${discount}`,
    text: intl.formatMessage({ id: `Order.DiscountReason.${discount}` }),
  }));
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    setMembers(company ? company.members : []);
  }, [setMembers, company]);

  const save = (company, redirect = true) => {
    doUpdateCompany({ company, browserHistory: history, redirect });
  };

  const setClient = (client) => {
    setModalData(client);
    openModal();
  };

  const addMemberToCompany = () => {
    setMembers([...members, modalData]);
    users.patch(modalData, { companyId: company._id });
    save(company, false);
    closeModal();
  };
  const unlinkClientFromCompany = (memberId) => {
    let newMembers = [...members];
    if (newMembers && newMembers.length > 0) {
      const removeIndex = newMembers.map((item) => item.id).indexOf(memberId);
      removeIndex && newMembers.splice(removeIndex, 1);
    }
    setMembers([...newMembers]);
    users.patch(memberId, { companyId: null });
    save(company, false);
  };

  if (!company) return null;
  return (
    <>
      <Form onSubmit={save} initialValues={company}>
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'row',
              overflowY: 'auto',
              overflowX: 'hidden',
              flexWrap: 'wrap',
              alignItems: 'stretch',
              alignContent: 'flex-start',
            }}
          >
            <Card title="User.Company">
              <React.Fragment>
                <Field
                  name="name"
                  validate={required}
                  label="Company.Name"
                  component={TextField}
                />
                <Field
                  name="accountId"
                  disabled
                  label="Company.AccountId"
                  component={TextField}
                />
              </React.Fragment>
            </Card>

            <Card title="Account.EssentialContact">
              <React.Fragment>
                <Field name="email" label="Email" component={TextField} />

                <Field
                  name="contactName"
                  label="Company.ContactName"
                  component={TextField}
                />
                <Field
                  name="address"
                  label="Company.Address"
                  component={TextAreaField}
                />

                <Field
                  name="billingCountry"
                  label="Billing.Country"
                  component={CountryPickerField}
                  parse={(option) => option.value}
                />
                <ConditionalField
                  when="billingCountry"
                  condition={(country) =>
                    canBeExcludedFromTax.includes(country)
                  }
                >
                  <Field
                    name="billingVAT"
                    label="Billing.VAT"
                    component={TextField}
                  />
                </ConditionalField>

                <div className="text-center">
                  <button type="submit" className="button button--inline">
                    <FormattedMessage FormattedMessage id="Admin.Save" />
                  </button>
                </div>
              </React.Fragment>
            </Card>

            <Card title="Settings">
              <React.Fragment>
                <Field
                  name="creditLimit"
                  label="Company.CreditLimit"
                  component={TextField}
                />

                <Field
                  name="discountReason"
                  label="Account.DiscountReason"
                  component={SelectField}
                  options={discountOptions}
                  placeholder="Order.DiscountReason.None"
                />

                <ConditionalField when="discountReason" not="">
                  <Field
                    name="discount"
                    label="Account.Discount"
                    component={SliderField}
                    min={0.0}
                    max={0.3}
                    step={0.01}
                    output={(value) => parseFloat(value * 100).toFixed(0) + '%'}
                  />
                </ConditionalField>
                <Field
                  name="dayStart"
                  label="Company.DayStart"
                  component={JsonField}
                />
                <Field
                  name="dayEnd"
                  label="Company.DayEnd"
                  component={JsonField}
                />

                <Field
                  name="weekendsAvailable"
                  label="Company.WeekendAvailable"
                  component={ToggleField}
                />

                <div className="text-center">
                  <button type="submit" className="button button--inline">
                    <FormattedMessage FormattedMessage id="Admin.Save" />
                  </button>
                </div>
              </React.Fragment>
            </Card>

            <Card title="Company.Members">
              <div className="section-general">
                {members &&
                  members.map((member) => (
                    <AccountCard
                      key={member._id}
                      account={member}
                      remove={true}
                      onRemove={() => unlinkClientFromCompany(member._id)}
                      removeSentence={`Company.RemoveClient.Sentence`}
                    />
                  ))}

                <FormattedMessage id="Company.Members.Add" />

                <div className="form-group">
                  <ClientLookup setClient={setClient} />

                  {/* <Autocomplete
                    value={autocompleteText}
                    items={autocompleteResults}
                    onChange={(event, text) => {
                      setAutocompleteText(text);
                      lookupUser(text);
                    }}
                    onSelect={selectUser}
                    getItemValue={(item) => item._id}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={
                          isHighlighted
                            ? 'autocomplete autocomplete--highlighted'
                            : 'autocomplete'
                        }
                        key={item._id}
                      >
                        <AccountCard key={item._id} account={item} />
                      </div>
                    )}
                  /> */}
                </div>
              </div>
            </Card>
          </form>
        )}
      </Form>

      {invoice && invoice.orders && (
        <Card title="Company.CurrentInvoice">
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="Invoice.OrderId" />
                </th>
                <th>
                  <FormattedMessage id="Invoice.Words" />
                </th>
                <th>
                  <FormattedMessage id="Order.Confirm.Price" />
                </th>
                <th>
                  <FormattedMessage id="Order.ConfirmedAt" />
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(invoice.orders).map((index) => {
                const {
                  orderId,
                  words,
                  price,
                  discountedPrice,
                  confirmedAt,
                } = invoice.orders[index];

                return (
                  <OrderInvoiceSummary
                    key={orderId}
                    count={parseInt(index, 10)}
                    orderId={orderId}
                    words={words}
                    pay={discountedPrice || price}
                    completedAt={confirmedAt}
                  />
                );
              })}
            </tbody>
          </table>

          <div className="section-invoice">
            {!!invoice.tax && (
              <div>
                <div className="section-invoice__row">
                  <div className="section-invoice__row-label">Total HT</div>
                  <div className="seciont-invoice__row-amount">
                    {accounting.formatMoney(invoice.subTotal, '€', 2, '.', ',')}
                  </div>
                </div>
                <div className="section-invoice__row">
                  <div className="section-invoice__row-label">
                    Total TVA 20.00 %
                  </div>
                  <div className="seciont-invoice__row-amount">
                    {accounting.formatMoney(invoice.tax, '€', 2, '.', ',')}
                  </div>
                </div>
              </div>
            )}

            <div className="section-invoice__row">
              <div className="section-invoice__row-label">
                Total
                {!!invoice.tax && 'TTC'}
              </div>
              <div className="seciont-invoice__row-amount">
                {accounting.formatMoney(invoice.total, '€', 2, '.', ',')}
              </div>
            </div>

            <button
              type="button"
              onClick={() => doSubmitInvoice(invoice)}
              className="button"
            >
              <FormattedMessage id="Invoice.Submit" />
            </button>
          </div>
        </Card>
      )}
      <Modal isOpen={modalIsOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          <FormattedMessage id="ModalConfirm" />
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <p>
                  <FormattedMessage
                    id={'Admin.Confirm.AddToCompany'}
                    values={{
                      name: `${modalData.givenName} ${modalData.familyName}`,
                      company: company.name,
                    }}
                  />
                </p>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Container>
            <Row xs="2">
              <Col>
                <Button color="secondary" onClick={closeModal}>
                  <FormattedMessage id={`Admin.Cancel`} />
                </Button>
              </Col>
              <Col className="text-right">
                <Button color="primary" onClick={addMemberToCompany}>
                  <FormattedMessage id={`Yes`} />
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Modal>
    </>
  );
};

CompanyForm.propTypes = {
  company: PropTypes.object,
  invoice: PropTypes.object,
  doSubmitInvoice: PropTypes.func.isRequired,
  doUpdateCompany: PropTypes.func.isRequired,
};

CompanyForm.defaultProps = {
  company: null,
  invoice: null,
};

const stateToProps = (state, ownProps) => ({
  invoice: ownProps.company
    ? getInvoiceForCompany(state, ownProps.company._id)
    : null,
});

const actionsToProps = {
  doSubmitInvoice: submitInvoice,
  doUpdateCompany: updateCompany,
};

export default connect(stateToProps, actionsToProps)(CompanyForm);
