import React from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '../components/Card';
import ActionLink from '../components/ActionLink';
import AccountCard from '../account/AccountCard';

const OrderRejectList = ({ order, clearRejections }) => {
  if (!order) return null;

  return (
    <Card
      title="Admin.Order.Rejections"
      action={
        <ActionLink onClick={clearRejections}>
          <FormattedMessage id="Admin.Order.ClearRejections" />
        </ActionLink>
      }
    >
      <>
        {order.rejectedDetails.map((item) => (
          <AccountCard key={item._id} account={item} />
        ))}
      </>
    </Card>
  );
};

export default OrderRejectList;
