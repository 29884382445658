import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import accounting from 'accounting';
import { Row, Col, Collapse } from 'reactstrap';
import Thumbnail from '../attachments/Thumbnail';
import { getClientUploads } from './orderUtils';
import { timezone } from '../constants';
import OrderStatus from './OrderStatus';

import styles from './OrderLink.module.scss';

const OrderLink = ({ order }) => {
  const link = `/orders/${order._id}`;
  const deadline = moment.tz(order.deadline, timezone);
  const uploads = getClientUploads(order);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={styles.Wrapper}>
      <Row>
        <Col xs="3" className={styles.OrderId}>
          <Link to={link}>
            <span>{order.orderId !== '' && order.orderId}</span>
            <span>{!order.orderId && 'No Id'}</span>
          </Link>
        </Col>
        <Col xs="5" className={styles.Timing}>
          <div className={styles.Inner}>{deadline.calendar()}</div>
        </Col>
        <Col xs="1" className={styles.ToggleDocuments}>
          {uploads.length > 0 && (
            <span className={styles.toggleUpload} onClick={toggle}></span>
          )}
          {uploads.length === 0 && (
            <span className={styles.toggleText} onClick={toggle}></span>
          )}
        </Col>
        <Col xs="3" className={styles.Price}>
          <div className={styles.Inner}>
            {accounting.formatMoney(
              order.discountedPrice || order.price,
              '',
              2,
              '.',
              ','
            )}
          </div>
        </Col>
        <Col xs="12" className={styles.OrderInfo}>
          <>
            {uploads.length > 0 && (
              <>
                <Collapse isOpen={isOpen}>
                  <div className={styles.FilesContainer}>
                    {uploads.map((u) => (
                      <div key={u.path} className={styles.UploadInfo}>
                        <Thumbnail file={u.path} />
                        {u.name}
                      </div>
                    ))}
                  </div>
                </Collapse>
              </>
            )}
            {uploads.length === 0 && (
              <Collapse isOpen={isOpen}>
                <div className={styles.UploadInfo}>
                  <Thumbnail file="text.txt" />
                  {order.text && order.text.substr(0, 50)}
                  {order.text && order.text.length > 50 && '...'}
                </div>
              </Collapse>
            )}
          </>
        </Col>
      </Row>

      <OrderStatus order={order} />
    </div>
  );
};

OrderLink.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderLink;
