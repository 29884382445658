import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Json from "react-json-view";
import { useIntl, FormattedMessage } from "react-intl";
import OrderForm from "./OrderForm";
import {
  clearOffers,
  clearRejections,
  cancelOrder,
  updateOrder,
  getOrder,
} from "../actions";
import Card from "../components/Card";
import OrderKeyDetails from "./OrderKeyDetails";
import OrderTimings from "./OrderTimings";
import OrderTranslation from "./OrderTranslation";
import { isNotComplete } from "./orderUtils";
import OrderOfferList from "./OrderOfferList";
import OrderRejectList from "./OrderRejectList";
import { useHistory } from "react-router-dom";
import QuoteForm from "./QuoteForm";
import PriceCalculator, { wordCount } from "./pricing";
import TimeCalculator from "./times";
import { getProfile, settings } from "../selectors";
import createDecorator from "final-form-calculate";
import Chat from "../chat/Chat";
import StarRating from "../components/StarRating";
import { useDispatch } from "react-redux";
import { removeOrder } from "./../common/redux/actions";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";

const ViewOrderPage = ({
  doClearRejections,
  order,
  doClearOffers,
  doCancelOrder,
  doUpdateOrder,
  match,
  doLoadOrder,
  dayStart,
  dayEnd,
  extendBy,
  searchTime,
  extendAfter,
  minLength,
  increments,
  priceBreaks,
  priceBreaksAskProofreading,
  basePrice,
  minFastPrice,
  minSlowPrice,
  settingsSpecialities,
  adminLang,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalData, setModalData] = useState({});
  const [basePriceState, setBasePrice] = useState(0.125);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!order || order._id !== match.params.id) {
      doLoadOrder(match.params.id);
    }
  }, [doLoadOrder, match, order]);

  const clearRejections = () => {
    if (
      window.confirm(
        intl.formatMessage({ id: "Admin.Order.ClearRejections.Confirm" })
      )
    ) {
      doClearRejections(order);
    }
  };

  const clearOffers = () => {
    if (
      window.confirm(
        intl.formatMessage({ id: "Admin.Order.ClearOffers.Confirm" })
      )
    ) {
      doClearOffers(order);
    }
  };

  const cancelOrder = () => {
    if (
      window.confirm(intl.formatMessage({ id: "Admin.Order.Cancel.Confirm" }))
    ) {
      doCancelOrder({ orderId: order._id, browserHistory: history });
    }
  };

  const openModal = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // All the actions to be confirmed in modal :
  const doActionModal = () => {
    switch (modalType) {
      default:
      case "removeQuote":
        dispatch(removeOrder({ orderId: order._id, history }));
        break;

      case "addTranslator":
        const updated = {
          ...order,
          translator: modalData._id,
          translatorDetails: modalData,
        };
        doUpdateOrder(updated);
        break;
    }

    //doUpdateOrder(orderUpdated);
    closeModal();
  };

  const setTranslator = (translator) => {
    setModalType("addTranslator");
    setModalData(translator);
    openModal();
  };

  const removeQuote = () => {
    setModalType("removeQuote");
    setModalData(null);
    openModal();
  };

  if (!order) return null;

  const timeCalculator = new TimeCalculator({
    dayStart,
    dayEnd,
    extendBy,
    searchTime,
    extendAfter,
    minLength,
    increments,
    skipWeekend: true,
  });
  const priceCalculator = new PriceCalculator({
    priceBreaks: !order.proofreading ? priceBreaks : priceBreaksAskProofreading,
    basePrice: basePrice * basePriceState,
    minFastPrice,
    minSlowPrice,
    timeCalculator,
  });

  let wordRef = 0;
  let timeRef = 0;
  let priceRef = 0;
  let incrementRef = 0;
  const calculatePrice = (time, words, values) => {
    if (timeRef === time && wordRef === words) return;
    const clientFiles = values?.attachments?.filter((a) => a.type === "client");
    const uniqueWord = clientFiles
      ?.reduce(
        (total, next) => total + next?.words * (1 - next?.redundancy / 100),
        0
      )
      .toFixed(0);

    const averageRedundancy =
      clientFiles?.reduce((total, next) => total + next?.redundancy ?? 0, 0) /
      (clientFiles?.length ?? 1);
    words = words || 0;
    const { max } = timeCalculator.generateMarks(words);
    const start = timeCalculator.getEarliestTime(words);
    const deadline = timeCalculator.timeFromIncrements(time, start);
    const mst = timeCalculator.mustStartTime(deadline, words);
    wordRef = words;
    timeRef = time;
    return priceCalculator.getPrice(mst, words, time, max, averageRedundancy, uniqueWord);
  };

  const calculatePay = (price, time, words) => {
    if (timeRef === time && wordRef === words && priceRef === price) return;
    words = words || 0;
    price = price || 0;
    const start = timeCalculator.getEarliestTime(words);
    const deadline = timeCalculator.timeFromIncrements(time, start);
    const mst = timeCalculator.mustStartTime(deadline, words);
    priceRef = price;
    wordRef = words;
    timeRef = time;
    return priceCalculator.getPay(mst, words, price);
  };
  const calculateDeadline = (increment, words) => {
    if (incrementRef === increment && wordRef === words) return;
    words = words || 0;
    const start = timeCalculator.getEarliestTime(words);
    incrementRef = increment;
    wordRef = words;
    return timeCalculator.timeFromIncrements(increment, start);
  };

  const decorator = createDecorator(
    {
      field: "time",
      updates: {
        calculatedPrice: (time, values, o) =>
          calculatePrice(time, values.words, values),
        deadline: (time, values) => calculateDeadline(time, values.words),
      },
    },
    {
      field: "calculatedWords",
      updates: {
        words: (calculated, values, previous) => {
          if (
            !values.words ||
            (previous.calculatedWords &&
              previous.calculatedWords === previous.words)
          ) {
            return calculated;
          }
          return values.words;
        },
      },
    },
    {
      field: "words",
      updates: {
        calculatedPrice: (words, values) => calculatePrice(values.time, words),
      },
    },
    {
      field: "text",
      updates: {
        calculatedWords: (text) => wordCount(text),
      },
    },
    {
      field: "calculatedPrice",
      updates: {
        price: (calculated, values, previous) => {
          if (
            !values.price ||
            (previous.calculatedPrice &&
              previous.calculatedPrice === previous.price)
          ) {
            return calculated;
          }
          return values.price;
        },
      },
    },
    {
      field: "price",
      updates: {
        calculatedPay: (price, values) =>
          calculatePay(price, values.time, values.words),
        margin: (price, values) => (price - values.pay) / price,
      },
    },
    {
      field: "calculatedPay",
      updates: {
        pay: (calculated, values, previous) => {
          if (
            !values.pay ||
            (previous.calculatedPay && previous.calculatedPay === previous.pay)
          ) {
            return calculated;
          }
          return values.pay;
        },
      },
    },
    {
      field: "pay",
      updates: {
        margin: (pay, values) => {
          const amount = (values.price - pay) / values.price;

          return Number(amount || 0).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
          });
        },
      },
    }
  );

  return (
    <Container>
      {["Order.Status.Quoted", "Order.Status.DraftQuote"].includes(
        order.status
      ) ? (
        <Row>
          <Col>
            {order && (
              <QuoteForm
                quoteOrder={order}
                timeCalculator={timeCalculator}
                decorator={decorator}
                removeQuote={removeQuote}
                setBasePrice={setBasePrice}
              />
            )}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <OrderKeyDetails
              order={order}
              cancelOrder={cancelOrder}
              setTranslator={setTranslator}
              settingsSpecialities={settingsSpecialities}
              adminLang={adminLang}
            />
            <OrderTranslation order={order} />
            <OrderTimings order={order} />
            <OrderForm order={order} doUpdateOrder={doUpdateOrder} />
            {isNotComplete(order) && (
              <>
                <OrderOfferList order={order} clearOffers={clearOffers} />
                <OrderRejectList
                  order={order}
                  clearRejections={clearRejections}
                />
              </>
            )}
            <Card title="Chat.Transcript">
              <Chat orderId={order._id} />
            </Card>
            {order.translatorRating && (
              <Card title="Order.TranslatorFeedback">
                <dl>
                  <dt>
                    <FormattedMessage id="Order.Rating.Title.Content" />
                  </dt>
                  <dd>
                    <StarRating stars={order.translatorRating.Content} />
                  </dd>
                  <dt>
                    <FormattedMessage id="Order.Rating.Title.Money" />
                  </dt>
                  <dd>
                    <StarRating stars={order.translatorRating.Money} />
                  </dd>
                  <dt>
                    <FormattedMessage id="Order.Rating.Title.Deadline" />
                  </dt>
                  <dd>
                    <StarRating stars={order.translatorRating.Deadline} />
                  </dd>
                </dl>
              </Card>
            )}
            {order.clientStars && (
              <Card title="Order.ClientFeedback">
                <p>{order.clientReview}</p>
                <StarRating stars={order.clientStars} />
                {order.clientFeedback && (
                  <>
                    <dt>
                      <FormattedMessage
                        id={
                          order.clientStars < 5
                            ? "Feedback.Bad.Question"
                            : "Feedback.Good.Question"
                        }
                      />
                    </dt>
                    <dd>
                      <ul>
                        {order.clientFeedback.map((item) => (
                          <li key={item}>
                            <FormattedMessage id={item} />
                          </li>
                        ))}
                      </ul>
                      {order.clientFeedbackOther}
                    </dd>
                  </>
                )}
              </Card>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Card fullWidth>
            <Json src={order} collapsed />
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modalIsOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          <FormattedMessage id="ModalConfirm" />
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <p>
                  {(modalType === "addTranslatorForQuoter" ||
                    modalType === "addTranslator") &&
                    modalData && (
                      <FormattedMessage
                        id={"Admin.Translators.ConfirmSelection"}
                        values={{
                          translator: `${modalData.givenName} ${modalData.familyName}`,
                        }}
                      />
                    )}
                  {modalType === "addClient" && modalData && (
                    <FormattedMessage
                      id={"Admin.Client.ConfirmSelection"}
                      values={{
                        client: `${modalData.givenName} ${modalData.familyName}`,
                      }}
                    />
                  )}

                  {modalType === "removeQuote" && (
                    <FormattedMessage id={"Admin.Quote.Delete.Confirm"} />
                  )}
                </p>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Container>
            <Row xs="2">
              <Col>
                <Button color="secondary" onClick={closeModal}>
                  <FormattedMessage id={`Admin.Cancel`} />
                </Button>
              </Col>
              <Col className="text-right">
                <Button color="primary" onClick={doActionModal}>
                  <FormattedMessage id={`Yes`} />
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

ViewOrderPage.propTypes = {
  order: PropTypes.object,
  doClearRejections: PropTypes.func.isRequired,
  doClearOffers: PropTypes.func.isRequired,
  doCancelOrder: PropTypes.func.isRequired,
  doUpdateOrder: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  order: state.orders.data,
  dayStart: settings(state).dayStart,
  dayEnd: settings(state).dayEnd,
  extendBy: settings(state).extendBy,
  searchTime: settings(state).searchTime,
  extendAfter: settings(state).extendAfter,
  minLength: settings(state).minLength,
  increments: settings(state).increments,
  priceBreaks: settings(state).priceBreaks,
  priceBreaksAskProofreading: settings(state).priceBreaksAskProofreading,
  basePrice: settings(state).basePrice,
  minFastPrice: settings(state).minFastPrice,
  minSlowPrice: settings(state).minSlowPrice,
  settingsSpecialities: settings(state).specialities,
  adminLang: getProfile(state).lang,
});

const dispatchToProps = {
  doClearRejections: clearRejections,
  doClearOffers: clearOffers,
  doCancelOrder: cancelOrder,
  doUpdateOrder: updateOrder,
  doLoadOrder: getOrder,
};

export default connect(stateToProps, dispatchToProps)(ViewOrderPage);
