import React from "react";
import { AccountPropType } from "./../common/proptypes";
import { Link } from "react-router-dom";

// import style from "./AccountLink.module.scss";
import AccountCard from "./AccountCard";

const AccountLink = ({ account }) => (
  <div>
    <Link to={`/${account.role}s/${account._id}`}>
      <AccountCard account={account} />
    </Link>
  </div>
);

AccountLink.propTypes = {
  account: AccountPropType.isRequired,
};

export default AccountLink;
