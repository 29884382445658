import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getClientInvoices } from '../../invoices/selectors';
import InvoiceRowDetails from '../../invoices/InvoiceRowDetails';

const InvoicesClientSubpage = ({ clientInvoices }) => (
  <div>
    {clientInvoices.map((invoice) => (
      <InvoiceRowDetails key={invoice._id} invoice={invoice} />
    ))}
  </div>
);

InvoicesClientSubpage.propTypes = {
  clientInvoices: PropTypes.array.isRequired,
};

const stateToProps = (state) => ({
  clientInvoices: getClientInvoices(state),
});

export default connect(stateToProps)(InvoicesClientSubpage);
