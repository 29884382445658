import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import style from './FullScreenLogin.module.scss';

const FullScreenLogin = () => (
  <div className={style.Container}>
    <Link to="/login">
      <div className={style.LoginBox}>
        <div className={style.Logo}>
          <span className="icon-my-brian" />
        </div>

        <FormattedMessage
          id="Admin.Login"
          values={{ linkToLogin: <FormattedMessage id="Login" /> }}
        />
      </div>
    </Link>
  </div>
);

export default FullScreenLogin;
