import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import ActionLink from '../components/ActionLink';
import Comment from './AttachmentComment';
import Thumbnail from './Thumbnail';

import styles from './Attachment.module.scss';
import AttachmentPropType from './AttachmentPropType';
import ClickableDiv from '../layout/ClickableDiv';
import serverLink from '../utils/ServerLink';

/* global window */

const Attachment = ({
  attachment: { path, name, percent, error, words, comment },
  onRemove,
  index,
  readonly,
  link,
}) => (
  <ClickableDiv
    onClick={link ? () => window.open(serverLink(path), '_blank') : null}
    className={classnames({
      [styles.FieldHolder]: true,

      [styles.Link]: link,
    })}
  >
    <div
      className={classnames({
        [styles.Description]: true,
        [styles.Error]: error,
      })}
    >
      <Thumbnail file={name} />
      <Comment
        error={error}
        readonly={readonly}
        comment={comment}
        index={index}
      />
    </div>
    <div
      className={styles.Info}
      style={{ background: backgroundStyle(percent) }}
    >
      <div
        className={classnames({
          [styles.FileName]: true,
          [styles.Uploading]: percent && percent !== 100,
        })}
      >
        {name}
      </div>

      {words > 0 ? (
        <div className={styles.Words}>
          <FormattedMessage
            id="Order.Words"
            defaultMessage={
              '{words, number} {words, plural, one {word} other {words}}'
            }
            values={{ words }}
          />
        </div>
      ) : null}
      {((path && !readonly) || error) && (
        <ActionLink className={styles.Remove} onClick={onRemove}>
          <span className="icon-bin" />
        </ActionLink>
      )}
    </div>
  </ClickableDiv>
);

Attachment.propTypes = {
  attachment: AttachmentPropType.isRequired,
  onRemove: PropTypes.func,
  index: PropTypes.number.isRequired,
  readonly: PropTypes.bool,
  link: PropTypes.bool,
};

Attachment.defaultProps = {
  readonly: false,
  link: false,
  onRemove: null,
};

const backgroundStyle = (percent) =>
  percent !== undefined
    ? `linear-gradient(90deg, rgba(255,255,255,0) 0%, #ff5f6b ${
        percent + 10
      }%,  rgba(255,255,255,0) ${percent + 10}%)`
    : null;

export default Attachment;
