import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import AccountCard from "../account/AccountCard";
import Attachment from "../attachments/Attachment";
import ActionLink from "../components/ActionLink";
import Card from "../components/Card";
import ClientLookup from "../components/ClientLookup";
import ConditionalField from "../components/formfields/ConditionalField";
import LanguagePicker from "../components/formfields/LanguagePicker";
import SelectField from "../components/formfields/SelectField";
import SpecificityPicker from "../components/formfields/SpecificityPicker";
import TextareaField from "../components/formfields/TextareaField";
import TextField from "../components/formfields/TextField";
import TimePickerField from "../components/formfields/TimePickerField";
import TranslatorLookup from "../components/TranslatorLookup";
import { getProfile, getSettingsSpecialities, settings } from "../selectors";
import { removeFile, updateOrder } from "./../common/redux/actions";
import {
  getClientUploads,
  getIndexForAttachment,
  getReferenceUploads,
} from "./orderUtils";
import Uploader from "./Uploader";
import composeValidators from "../utils/composeValidators";
import { feathersServices } from "../feathers";
import {
  cancelOrder,
  clearOffers,
  clearRejections,
  getOrder,
} from "../actions";

const required = (value) => (value ? undefined : "Forms.Required");
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Forms.InvalidEmail"
    : undefined;

const QuoteForm = ({
  quoteOrder,
  timeCalculator,
  decorator,
  removeQuote,
  adminLang,
    setBasePrice,
  settingsSpecialities,
}) => {
  const [showTranslatorSearch, setShowTranslatorSearch] = useState(false);
  const [showClientSearch, setShowClientSearch] = useState(false);
  const [isUploaderDisabled, setIsUploaderDisabled] = useState(false);
  const [order, setOrder] = useState(quoteOrder);
  const [nextPart, setNextPart] = useState(false);
  const intl = useIntl();
  const supportedLanguages = useSelector(settings).supportedLanguages;
  let attachments = getClientUploads(order);
  let referenceAttachments = getReferenceUploads(order);
  const dispatch = useDispatch();
  const history = useHistory();
  const oneFieldIsRequired = (value) =>
    !value && !order.user ? "Admin.Quote.OneFieldIsRequired" : undefined;
  const [openPdf, setOpenPdf] = useState(false);

  useEffect(() => {
    openPdf && pdf();
  }, [order.quoteFile]);

  const pdf = () => {
    if (!!order.quoteFile)
      window.open(
        `${process.env.REACT_APP_ADMIN_URL}/files/${order.quoteFile}`,
        "_blank",
        "noopener,noreferrer"
      );
  };

  useEffect(() => {
    setOrder(quoteOrder);
    setNextPart(false);
  }, [quoteOrder.attachments]);

  const onChangeText = (value) => {
    if (value !== null && value !== undefined && value !== "") {
      setIsUploaderDisabled(true);
      setOrder({
        ...order,
        text: value,
      });
    } else {
      setIsUploaderDisabled(false);
    }
  };

  const removeTranslator = () => {
    const newOrder = { ...order, translator: null, translatorDetails: null };
    setOrder(newOrder);
    dispatch(updateOrder(newOrder));
  };
  const removeClient = () => {
    const newOrder = {
      ...order,
      user: null,
      userDetails: null,
      quoteEmail: null,
    };
    setOrder(newOrder);
    dispatch(updateOrder(newOrder));
  };

  const setTranslator = (translator) => {
    const newStatus =
      order.status !== "Order.Status.DraftQuote" &&
      order.status !== "Order.Status.Quoted"
        ? "Order.Status.InProgress"
        : order.status;
    const newOrder = {
      ...order,
      translator: translator._id,
      translatorDetails: translator,
      status: newStatus,
    };
    setOrder(newOrder);
    dispatch(updateOrder(newOrder));
  };

  const setClient = (client) => {
    const newOrder = {
      ...order,
      user: client._id,
      userDetails: client,
      quoteEmail: "",
    };
    setOrder(newOrder);
    dispatch(updateOrder(newOrder));
  };
  const languageOptions = () =>
    supportedLanguages
      .filter((lang) => ["fr", "en"].includes(lang.iso6391Name))
      .map((lang) => ({
        value: lang.iso6391Name,
        text: intl.formatMessage({ id: `Language.${lang.iso6391Name}` }),
      }));

  const uniqueWord = order?.attachments
    ?.filter((a) => a.type === "client")
    ?.reduce(
      (total, next) => total + next?.words * (1 - next?.redundancy / 100),
      0
    )
    .toFixed(0);

  if (!order) return <div></div>;

  return (
    <Form
      onSubmit={(e) => {
        return dispatch(
          updateOrder({ ...order, ...e, status: "Order.Status.Quoted" })
        );
      }}
      initialValues={quoteOrder}
      decorators={[decorator]}
      render={({ handleSubmit, form, values, valid, submitting, pristine }) => {
        const { max, marks } = timeCalculator.generateMarks(values.words);
        const start = timeCalculator.getEarliestTime(values.words);
        const deadline = timeCalculator.timeFromIncrements(values.time, start);
        const businessHours = timeCalculator.getBusinessHours(
          values.words,
          values.time
        );
        const sendEmail = () => {
          values.statusQuote = "Sending";
          handleSubmit();
        };

        const callRedundancy = async () => {
          setNextPart(true);
          const res = await feathersServices.redundancy.create({
            orderId: values._id,
            selectedLanguage: values.selectedLanguage,
            destinationLanguage: values.destinationLanguage,
            specificityId: values.specialityId,
          });
          const specialities = await feathersServices.specialities.find()
          const speciality = (await specialities.payload.promise).find((spec) => spec.key === values.specialityId)
          const specialityFactor = speciality ? speciality.factor : 1;

          const langCouple = await feathersServices.languagecouples.find()
          const lngCouple = (await langCouple.payload.promise).find((spec) => spec.couple === `${values.selectedLanguage}-${values.destinationLanguage}`)
          const languageCoupleFactor = lngCouple ? lngCouple.factor : 1;

          setBasePrice(specialityFactor * languageCoupleFactor)
        };

        return (
          <form onSubmit={handleSubmit}>
            <Card
              title="Quote.Create"
              action={
                order._id && (
                  <ActionLink onClick={removeQuote}>
                    <FormattedMessage id="Admin.Quote.Delete" />
                  </ActionLink>
                )
              }
            ></Card>

            <Card title="Order.Files.Upload.ToTranslate">
              <Row>
                <Col>
                  {attachments.map((u) => (
                    <Attachment
                      key={`${u.name}${u.nonce}`}
                      attachment={u}
                      index={getIndexForAttachment(order, u)}
                      onRemove={() =>
                        dispatch(
                          removeFile({ orderId: order._id, file: u.path })
                        )
                      }
                    />
                  ))}
                  <Uploader type="client" disabled={isUploaderDisabled} />
                </Col>
              </Row>
            </Card>

            {!attachments.length && (
              <Row>
                <Col>
                  <Card title="Order.Text">
                    <Field
                      name="text"
                      placeholder="Order.Text"
                      validate={required}
                      component={TextareaField}
                      rows={5}
                    />
                    <OnChange name="text">
                      {(value) => {
                        onChangeText(value);
                      }}
                    </OnChange>
                  </Card>
                </Col>
              </Row>
            )}

            <Card title="Order.Files.Upload.Reference">
              <Row>
                <Col>
                  {referenceAttachments.map((u) => (
                    <Attachment
                      key={`${u.name}${u.nonce}`}
                      attachment={u}
                      index={getIndexForAttachment(order, u)}
                      onRemove={() =>
                        dispatch(
                          removeFile({
                            orderId: order._id,
                            file: u.path,
                          })
                        )
                      }
                    />
                  ))}

                  <Uploader type="reference" />
                </Col>
              </Row>
            </Card>

            <ConditionalField when="calculatedWords" not={0}>
              <>
                <Row className="row-eq-height">
                  <Col xs="6">
                    <Card title="Quote.chooseAClient">
                      <div className="section-general">
                        {order.user ? (
                          <>
                            <AccountCard
                              account={order.userDetails}
                              link={() =>
                                history.push(`/clients/${order.user}`)
                              }
                              remove={true}
                              onRemove={() => removeClient()}
                              removeSentence={`Account.RemoveClient.Sentence`}
                            />
                            {showClientSearch ? (
                              <ClientLookup setClient={setClient} />
                            ) : (
                              <ActionLink
                                onClick={() => setShowClientSearch(true)}
                              >
                                <p className="pt-3">
                                  <FormattedMessage id="Quote.ChangeClient" />
                                </p>
                              </ActionLink>
                            )}
                          </>
                        ) : (
                          <ClientLookup setClient={setClient} />
                        )}

                        <Field
                          component={TextField}
                          name="quoteEmail"
                          label={
                            order.user
                              ? "Quote.Email"
                              : "Quote.SendToANonClient"
                          }
                          disabled={order.user ? true : false}
                          validate={composeValidators(
                            email,
                            oneFieldIsRequired
                          )}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col xs="6">
                    <Card title="Quote.chooseATranslator">
                      <div className="section-general">
                        {order.translatorDetails ? (
                          <>
                            <AccountCard
                              account={order.translatorDetails}
                              link={() =>
                                history.push(`/translators/${order.translator}`)
                              }
                              remove={true}
                              onRemove={() => removeTranslator()}
                              removeSentence={`Account.RemoveTranslator.Sentence`}
                            />
                            {showTranslatorSearch ? (
                              <TranslatorLookup setTranslator={setTranslator} />
                            ) : (
                              <ActionLink
                                onClick={() => setShowTranslatorSearch(true)}
                              >
                                <p className="pt-3">
                                  <FormattedMessage id="Quote.ChangeTranslator" />
                                </p>
                              </ActionLink>
                            )}
                          </>
                        ) : (
                          <TranslatorLookup setTranslator={setTranslator} />
                        )}
                        <p className="small">
                          {" "}
                          <FormattedMessage id="Quote.AssignTranslator" />
                        </p>
                      </div>
                    </Card>
                  </Col>
                </Row>
                {/* Continue filling form only if client is choosen */}
                <Row>
                  <Col>
                    <Card title="Language">
                      <>
                        <LanguagePicker
                          selectedLanguage={
                            values.selectedLanguage
                              ? values.selectedLanguage
                              : values.detectedLanguage
                          }
                        />
                        <SpecificityPicker
                          adminLang={adminLang}
                          settingsSpecialities={settingsSpecialities}
                          mutators={{ setFieldData: () => {} }}
                          order={order}
                        />
                      </>
                    </Card>
                  </Col>
                </Row>

                {values.quoteEmail || order.user ? (
                  <Row style={{ width: "100%" }}>
                    <Col>
                      <Button onClick={callRedundancy}>Suivant</Button>
                    </Col>
                  </Row>
                ) : null}
                {nextPart && (
                  <>
                    <Row>
                      <Col xs="12">
                        <Field
                          component={TextareaField}
                          rows={5}
                          name="quoteMessage"
                          label="Quote.Message"
                        />
                        <Field
                          component={SelectField}
                          name="quoteLang"
                          label="Quote.Language"
                          options={languageOptions()}
                          validate={required}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="6">
                        <Field
                          name="calculatedWords"
                          component={TextField}
                          label="Order.CalculatedWords"
                          disabled
                        />
                      </Col>
                      <Col xs="6">
                        <Field
                          name="words"
                          component={TextField}
                          label="Order.QuotedWords"
                          validate={required}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card title="Price">
                          <p>Number of words</p>
                          <div style={{ display: "flex" }}>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: 100,
                              }}
                            >
                              Unique
                            </span>
                            <TextField
                              disabled
                              meta={{}}
                              input={{ value: uniqueWord, style: {} }}
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: 100,
                              }}
                            >
                              Repeated
                            </span>
                            <TextField
                              disabled
                              meta={{}}
                              input={{
                                value: order.words - uniqueWord,
                                style: {},
                              }}
                            />
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field
                          name="time"
                          component={TimePickerField}
                          marks={marks}
                          max={max}
                          label="Order.Speed"
                        />

                        <dl>
                          <dt>
                            <FormattedMessage id="Order.Due.IfConfirmedNow" />
                          </dt>
                          <dd>
                            {deadline.calendar()} ({businessHours}{" "}
                            <FormattedMessage id="Order.Due.BusinessHours" />)
                          </dd>
                        </dl>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="6">
                        <Field
                          name="calculatedPrice"
                          component={TextField}
                          label="Order.CalculatedPrice"
                          disabled
                        />
                      </Col>
                      <Col xs="6">
                        <Field
                          name="price"
                          component={TextField}
                          label="Order.QuotedPrice"
                          validate={required}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="6">
                        <Field
                          name="calculatedPay"
                          component={TextField}
                          label="Order.CalculatedPay"
                          disabled
                        />
                      </Col>
                      <Col xs="6">
                        <Field
                          name="pay"
                          component={TextField}
                          label="Order.QuotedPay"
                          validate={required}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field
                          name="margin"
                          component={TextField}
                          label="Order.Margin"
                          disabled
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Card title="Order.AdditionalInfo">
                          <Field
                            component={TextareaField}
                            name="additionalInfo"
                            label="Order.AdditionalInfo"
                          />
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Card title="Quote.Submit">
                          <div className="text-center">
                            {order.statusQuote === "Sent" && (
                              <p className="pink">
                                <FormattedMessage id="Quote.Sent" />
                              </p>
                            )}
                          </div>
                          <Row>
                            <Col xs={{ size: 4 }} className="text-center">
                              <Button
                                onClick={() => {
                                  console.log("ici");

                                  handleSubmit();
                                  setOpenPdf(false);
                                }}
                                outline
                                disabled={submitting || pristine}
                              >
                                <FormattedMessage id="Admin.Save" />
                              </Button>
                            </Col>

                            <Col xs={{ size: 4 }} className="text-center">
                              <Button
                                outline
                                onClick={() => {
                                  handleSubmit();
                                  setOpenPdf(true);
                                }}
                                disabled={submitting || pristine}
                              >
                                <FormattedMessage id="View.Quote" />
                              </Button>
                            </Col>

                            <Col xs={{ size: 4 }} className="text-center">
                              <Button
                                onClick={() => {
                                  sendEmail();
                                  setOpenPdf(false);
                                }}
                                disabled={submitting || pristine}
                              >
                                <FormattedMessage id="Quote.Submit" />
                              </Button>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            </ConditionalField>
          </form>
        );
      }}
    />
  );
};

const stateToProps = (state) => {
  return {
    adminLang: getProfile(state).lang,
    settingsSpecialities: getSettingsSpecialities(state),
  };
};

const dispatchToProps = {
  doUpdateOrder: updateOrder,
};

export default connect(stateToProps, dispatchToProps)(QuoteForm);
