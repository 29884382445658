import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import Help from './Help';
import { useIntl } from 'react-intl';

import styles from './SliderField.module.scss';
const SliderField = ({
  input,
  min,
  max,
  step,
  label,
  tooltip,
  meta: { error, warning },
  output,
}) => {
  const intl = useIntl();
  const state = error ? 'error' : warning ? 'warning' : null;
  const translatedLabel = label ? intl.formatMessage({ id: label }) : '';

  return (
    <FormGroup className={styles.Container}>
      {tooltip && <Help input={input.name} text={tooltip} />}
      {translatedLabel && <Label>{translatedLabel}</Label>}
      <input
        id={input.name}
        type="range"
        {...input}
        min={min}
        max={max}
        step={step}
      />
      {!!output && <span className={styles.Output}>{output(input.value)}</span>}
      {state && <Label className={state}>{error || warning || ''}</Label>}
    </FormGroup>
  );
};

SliderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.any.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  tooltip: PropTypes.object,
  meta: PropTypes.object,
  output: PropTypes.func,
};

SliderField.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  tooltip: null,
  meta: { error: null, warning: null },
  output: undefined,
};

export default SliderField;
