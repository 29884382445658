import React from 'react';
import moment from 'moment-timezone';
import { formatMoney } from 'accounting';
import { Container, Row, Col } from 'reactstrap';
import styles from './InvoiceRowDetails.module.scss';

const InvoiceRowDetails = ({ invoice }) => (
  <Container className={styles.Wrapper}>
    <Row className={styles.Row}>
      <Col xs="2" className={styles.invoiceId}>
        {invoice.translatorInvoiceId || invoice.invoiceId}
      </Col>
      <Col xs="4" className={styles.Name}>
        {invoice.company || invoice.name}
      </Col>
      <Col xs="2" className="text-right font-weight-bold">
        {formatMoney(invoice.total, '€', 2, '.', ',')}
      </Col>
      <Col className={styles.due}>
        {invoice.due
          ? moment(invoice.due).calendar()
          : moment(invoice.updatedAt).calendar()}
      </Col>
      <Col className="text-right">
        <a
          className="btn btn-secondary"
          href={`/files/${invoice.file}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Download
        </a>
      </Col>
    </Row>
  </Container>
);

export default InvoiceRowDetails;
