import { without } from 'lodash';

export const locale = 'fr';
export const localeFormatTweaks = {
  en: {
    calendar: {
      sameElse: 'dddd Do [at] LT',
    },
  },
  fr: {
    weekdays: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
    calendar: {
      sameElse: 'dddd Do [à] LT',
    },
  },
};
export const timezone = 'Europe/Paris';
// See https://technet.microsoft.com/en-us/library/ee309278(office.12).aspx
export const allowedUploadTypes = {
  'application/msword': 'msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'msword',
  'application/vnd.ms-excel': 'mxexcel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'msexcel',
  'application/vnd.ms-powerpoint': 'mspowerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'mspowerpoint',
  'text/plain': 'text',
  'text/richtext': 'text',
};

export const dialects = ['en-us', 'en-uk'];

export const titles = ['Female', 'Male'];

export const billTos = ['Me', 'Company'];

export const discounts = ['None', 'EarlyAdopter'];
export const specificities = ['general', 'specialist'];

export const tax = 0.2;
export const taxStatuses = {
  FrenchMicroEntrepreneur: {
    tax: false,
    exemptionText: 'TVA non applicable, art. 293 B du CGI',
  },
  FrenchBusiness: {
    tax: true,
  },
  EUBusiness: {
    tax: false,
  },
  NonEUIndividualOrCompany: {
    tax: false,
  },
};
export const eu = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB', // For now....
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];
export const coupleOfLanguagesStatus = ['VALIDATED', 'PENDING', 'REMOVED'];

export const canBeExcludedFromTax = without(eu, 'FR');
