import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ActionLink from './ActionLink';
import { logout } from '../actions';

import style from './Header.module.scss';
import { Burger } from '../style/common';

const Header = ({ location, doLogout, history }) => {
  const match = /\/(\w*)\/?/.exec(location.pathname)[1];

  const header = match ? match.charAt(0).toUpperCase() + match.slice(1) : null;

  return (
    <div className={style.Header}>
      <div className={style.NavigationHeader}>
        <a
          href="#main-menu"
          className={style.MenuToggle}
          role="button"
          id="main-menu-toggle"
          aria-expanded="false"
          aria-controls="main-menu"
          aria-label="Open main menu"
        >
          <Burger aria-hidden="true" />{' '}
        </a>
        <Link to="/"  className={`icon-my-brian ${style.IconLogo}`} />
      </div>
      <div>{!!header && <FormattedMessage id={header} />}</div>

      <ActionLink className={style.Right} onClick={doLogout}>
        <FormattedMessage id="Logout" />
      </ActionLink>
    </div>
  );
};

const dispatchToProps = (dispatch, ownProps) => ({
  doLogout: () => dispatch(logout({ browserHistory: ownProps.history })),
});

export default withRouter(connect(null, dispatchToProps)(Header));
