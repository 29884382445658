import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import OrderLink from './OrderLink';
import { getCancelledOrders } from './selectors';
import Card from '../components/Card';

const OrdersPageSubPageCancelled = ({ cancelledOrders }) => {
  return (
    <Card title="Order.Status.Cancelled" fullWidth>
      <>
        {cancelledOrders &&
          cancelledOrders.map((order) => (
            <OrderLink key={order._id} order={order} />
          ))}
      </>
    </Card>
  );
};

OrdersPageSubPageCancelled.propTypes = {
  cancelledOrders: PropTypes.array.isRequired,
};

OrdersPageSubPageCancelled.defaultProps = {};

const stateToProps = (state) => ({
  cancelledOrders: getCancelledOrders(state),
});

export default connect(stateToProps)(OrdersPageSubPageCancelled);
