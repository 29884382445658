import PropTypes from 'prop-types';

export default PropTypes.shape({
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  words: PropTypes.number,
  comment: PropTypes.string,
  type: PropTypes.string.isRequired,
  version: PropTypes.number,
});
