import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import TextField from '../components/formfields/TextField';
import Speciality from './Speciality';
import { OnChange } from 'react-final-form-listeners';

const SpecialitiesList = ({
  specialities,
  settingsSpecialities,
  handeCreateSpe,
  handleRemoveSpe,
  adminLang,
}) => {
  const validSpecialities = specialities.filter((spe) => {
    return (
      settingsSpecialities.findIndex(
        (settingSpe) => settingSpe.key === spe.key
      ) !== -1
    );
  });

  const speInitialState = {
    key: '',
    speciality: {
      fr: '',
      en: '',
    },
    factor: 1,
    status: '',
  };
  const [newSpe, setNewSpe] = useState(speInitialState);
  const [currentSpe, setCurrentSpe] = useState(speInitialState);

  // Update Modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => {
    setModalIsOpen(false);
    setNewSpe(speInitialState);
  };

  const openUpdateModal = (speciality) => {
    const settingsSpe = settingsSpecialities.find(
      (spe) => spe.key === speciality.key
    );
    const speToUpdate = {
      key: speciality.key,
      speciality: {
        fr: settingsSpe.speciality.fr,
        en: settingsSpe.speciality.en,
      },
      factor: speciality.factor,
      status: settingsSpe.status,
    };
    setNewSpe(speToUpdate);
    setCurrentSpe(speToUpdate);
    setModalIsOpen(true);
  };

  /**
   * Handle submit speciality and reinit newSpe
   */
  const submitSpe = (action, spe) => {
    if (action === 'update') {
      setNewSpe({ ...newSpe, status: 'VALIDATED' });
      setModalIsOpen(false);
      handeCreateSpe(newSpe);
    } else if (action === 'remove') {
      handleRemoveSpe(spe);
    }
    setNewSpe(speInitialState);
  };

  const required = (value) => (value ? undefined : 'Forms.Required');
  // Check if french or english name already exist, display error
  const alreadyExistFR = (value, meta) => {
    return alreadyExist('fr', meta);
  };
  const alreadyExistEN = (value, meta) => {
    return alreadyExist('en', meta);
  };
  const alreadyExist = (lang, meta) => {
    if (
      meta[lang] &&
      settingsSpecialities
        ?.filter(
          (spe) =>
            spe.speciality[lang].toLowerCase() !==
            currentSpe?.speciality[lang].toLowerCase()
        )
        .find(
          (spe) =>
            spe.speciality[lang].toLowerCase() === meta[lang].toLowerCase()
        )
    ) {
      return 'Forms.Already.Speciality';
    }
  };

  return (
    <Container>
      <Row>
        <Col className="p-0">
          <Button onClick={() => setModalIsOpen(true)}>
            <FormattedMessage id="Admin.Settings.AddSpeciality" />
          </Button>
        </Col>
      </Row>

      {!validSpecialities || validSpecialities.length === 0 ? (
        <Row>
          <FormattedMessage id="Admin.Settings.NoSpecialities" />
        </Row>
      ) : (
        <>
          {validSpecialities.map((speciality) => (
            <Row key={speciality._id}>
              <Col>
                <Speciality
                  key={speciality.key}
                  speciality={speciality}
                  specialityName={
                    settingsSpecialities
                      ? settingsSpecialities.find(
                          (spe) => spe.key === speciality.key
                        )?.speciality[adminLang]
                      : null
                  }
                  openModal={() => openUpdateModal(speciality)}
                  removefromSpeciality={submitSpe}
                />
              </Col>
            </Row>
          ))}
        </>
      )}

      <Modal isOpen={modalIsOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          {newSpe.speciality ? (
            <FormattedMessage id="Admin.Settings.UpdateSpeciality" />
          ) : (
            <FormattedMessage id="Admin.Settings.AddSpeciality" />
          )}
        </ModalHeader>
        <Form
          onSubmit={(spe) => submitSpe('update', spe)}
          initialValues={newSpe.speciality}
        >
          {({ handleSubmit, submitting, hasValidationErrors }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Container>
                  <Row className="align-items-center">
                    <Col>
                      <OnChange name="fr">
                        {(value) => {
                          setNewSpe({
                            ...newSpe,
                            speciality: { ...newSpe.speciality, fr: value },
                          });
                        }}
                      </OnChange>

                      <Field
                        component={TextField}
                        name="fr"
                        label="Admin.Setting.SpecialityFR"
                        validate={(required, alreadyExistFR)}
                        showErrorBeforeTouched
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col>
                      <OnChange name="en">
                        {(value) => {
                          setNewSpe({
                            ...newSpe,
                            speciality: { ...newSpe.speciality, en: value },
                          });
                        }}
                      </OnChange>
                      <Field
                        component={TextField}
                        name="en"
                        label="Admin.Setting.SpecialityEN"
                        validate={(required, alreadyExistEN)}
                        showErrorBeforeTouched
                      />
                    </Col>
                  </Row>

                  <Row className="align-items-center">
                    <Col>
                      <FormattedMessage id="Admin.Translators.AddFactor" /> :
                    </Col>
                    <Col>
                      <input
                        type="number"
                        step="0.01"
                        min="0.00"
                        value={newSpe.factor}
                        label="Admin.Translators.AddFactor"
                        className="form-control"
                        onChange={(e) =>
                          setNewSpe({
                            ...newSpe,
                            factor: e.target.value,
                          })
                        }
                      ></input>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Container>
                  <Row xs="2">
                    <Col>
                      <Button color="secondary" onClick={closeModal}>
                        <FormattedMessage id="Admin.Cancel" />
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={submitting || hasValidationErrors}
                      >
                        <FormattedMessage id="Forms.Submit" />
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </ModalFooter>
            </form>
          )}
        </Form>
      </Modal>
    </Container>
  );
};

export default SpecialitiesList;
