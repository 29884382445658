import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FlagKit from 'react-flagkit';
import latinamerica from '../img/latinamerica.png';
import neutral from '../img/neutral.png';
import style from './Flag.module.scss';
/**
 * Overrides for where language code does not match up with country code
 */
const flagOverrides = {
  EN: 'GB',
  UK: 'GB',
  HANS: 'CN',
  HANT: 'CN',
  JA: 'JP',
  BEL: 'BE',
  EL: 'GR',
  ZH: 'CN',
  SQ: 'AL',
  SV: 'SE',
  KA: 'GE',
  CS: "CZ",
  BRA: "BR",
  DA: "DK",
  HI: "IN"
};
const Flag = ({ country, className, includeText }) => {
  if (country) {
    let countryFlag = country.split('-').pop().toUpperCase();
    if (flagOverrides[countryFlag]) {
      countryFlag = flagOverrides[countryFlag];
    }

    return (
      <div className={`flag ${className ? className : ''}`}>
        {countryFlag === 'LATINAMERICA' && (
          <img alt="flag" width={30} src={latinamerica} />
        )}
        {countryFlag === 'XX' && <img alt="flag" width={30} src={neutral} />}

        {countryFlag !== 'LATINAMERICA' && countryFlag !== 'XX' && (
          <FlagKit country={countryFlag} size={30} />
        )}
        {includeText && (
          <span className={style.spanText}>
            <FormattedMessage id={`Language.${country}`} />
          </span>
        )}
      </div>
    );
  }
  return null;
};

Flag.propTypes = {
  country: PropTypes.string,
  className: PropTypes.string,
  includeText: PropTypes.bool,
};

Flag.defaultProps = {
  country: null,
  className: '',
  includeText: true,
};

export default Flag;
