import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getClients } from '../selectors';
import Card from '../components/Card';
import { AccountPropType } from './../common/proptypes';
import AccountLink from '../account/AccountLink';
import { Container, Row, Col } from 'reactstrap';

const ClientsPage = ({ clients }) => {
  return (
    <Container>
      <Row>
        <Col>
          <Card title="Admin.Clients" fullWidth>
            {clients.map((account, i) => (
              <Row key={i}>
                <Col xs="12">
                  <AccountLink key={account._id} account={account} />
                </Col>
              </Row>
            ))}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ClientsPage.propTypes = {
  clients: PropTypes.arrayOf(AccountPropType).isRequired,
};

const stateToProps = (state) => ({
  clients: getClients(state),
});

export default connect(stateToProps)(ClientsPage);
