import React from 'react';
import PropTypes from 'prop-types';

import Slider from 'rc-slider';
import { FormGroup, Label } from 'reactstrap';

import 'rc-slider/assets/index.css';
import { useIntl } from 'react-intl';

const TimePickerField = ({
  input,
  marks,
  max,
  meta: {
    data: { warning },
  },
  onBeforeChange,
  onAfterChange,
  label,
}) => {
  const intl = useIntl();
  const translatedLabel = label ? intl.formatMessage({ id: label }) : undefined;
  return (
    <FormGroup>
      <div id={input.name} className="field">
        {translatedLabel && <Label>{translatedLabel}</Label>}
        <Slider
          marks={marks}
          max={max}
          included={false}
          onBeforeChange={onBeforeChange}
          onAfterChange={onAfterChange}
          {...input}
        />
        {warning}
      </div>
    </FormGroup>
  );
};

TimePickerField.propTypes = {
  input: PropTypes.object.isRequired,
  marks: PropTypes.object,
  max: PropTypes.number,
  meta: PropTypes.object,
  tooltip: PropTypes.object,
};

export default TimePickerField;
