import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';
import FieldHolder from './FieldHolder';

const ButtonGroupField = (props) => {
  const { input, options } = props;
  return (
    <FieldHolder {...props}>
      <ButtonGroup style={{ flexWrap: 'wrap' }}>
        {options.map(({ value, text }) => (
          <Button
            key={value}
            className={`button ${input.value === value && 'active'}`}
            style={{ magin: '2px' }}
            onClick={(e) => {
              e.preventDefault();
              input.onChange(value);
            }}
          >
            {text}
          </Button>
        ))}
      </ButtonGroup>
    </FieldHolder>
  );
};

ButtonGroupField.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

export default ButtonGroupField;
