import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { get } from "lodash";
import { MessagePropType, AccountPropType } from "./../common/proptypes";

import { notifyViewingChat } from "./../common/redux/actions";
import {
  getMessages,
  getIsMessageSaving,
  getIsMessageLoading,
  getMessagesForOrder,
} from "./selectors";
import { feathersServices } from "../feathers";
import ChatMessage from "./ChatMessage";

import styles from "./Chat.module.scss";

const Chat = ({
  orderId,
  recipient,
  messages,
  isSaving,
  isLoading,
  doNotifyViewingChat,
  doSendMessage,
}) => {
  const intl = useIntl();
  const end = useRef(null);
  const [text, setText] = useState();

  useEffect(() => {
    doNotifyViewingChat(recipient ? recipient._id : null);
    return () => {
      doNotifyViewingChat(null);
    };
  }, [recipient, doNotifyViewingChat]);

  useEffect(() => {
    end.current.scrollIntoView();
  }, [messages]);

  const doSend = () => {
    const message = {
      recipient,
      text,
      order: null,
    };
    if (message.text) {
      setText("");
      doSendMessage(message);
    }
  };

  const anyMessages = get(messages, "length", isSaving);

  const translatedPlaceholder = intl.formatMessage({ id: "Chat.Text" });
  return (
    <div className={styles.Container}>
      <div className={styles.Flux}>
        {anyMessages ? (
          messages.map((message) => (
            <ChatMessage
              key={message._id}
              text={message.text}
              myMessage={!message.sender}
              senderName={
                message.sender ? message.senderDetails.givenName : "Admin"
              }
              senderPhoto={message.sender ? message.senderDetails.picture : ""}
              time={message.createdAt}
            />
          ))
        ) : (
          <p>
            <FormattedMessage id={isLoading ? "Loading" : "Chat.NoMessages"} />
          </p>
        )}
        <span ref={end} />
      </div>
      {recipient && (
        <TextareaAutosize
          id="chat"
          className={styles.Chat}
          minRows={1}
          maxRows={3}
          type="text"
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              if (e.ctrlKey || e.metaKey || e.shiftKey) {
                setText(`${e.target.value}\n`);
              } else {
                e.preventDefault();
                doSend();
              }
            }
          }}
          value={text}
          placeholder={translatedPlaceholder}
        />
      )}
    </div>
  );
};

Chat.propTypes = {
  messages: PropTypes.arrayOf(MessagePropType),
  orderId: PropTypes.string,
  recipient: AccountPropType,
  isSaving: PropTypes.bool,
  isLoading: PropTypes.bool,
  doSendMessage: PropTypes.func.isRequired,
  doNotifyViewingChat: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  orderId: null,
  recipient: null,
  messages: null,
  isSaving: false,
  isLoading: false,
};

const stateToProps = (state, ownProps) => ({
  messages: ownProps.orderId
    ? getMessagesForOrder(state, ownProps.orderId)
    : getMessages(state, ownProps.recipient._id),
  isLoading: getIsMessageLoading(state),
  isSaving: getIsMessageSaving(state),
});

const actionsToProps = (dispatch) => ({
  // loadMessages: order => dispatch(feathersServices.messages.find({ query: { order } })),
  doSendMessage: (message) =>
    dispatch(feathersServices.messages.create(message)),
  doNotifyViewingChat: (id) => dispatch(notifyViewingChat(id)),
});

export default connect(stateToProps, actionsToProps)(Chat);
