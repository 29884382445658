import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";
import { connect } from "react-redux";
import { AccountPropType } from "./../common/proptypes";
import { FormattedMessage } from "react-intl";
import "react-toggle/style.css";

import { setAvailability } from "../actions";

import AccountLink from "./AccountLink";

const TranslatorDetails = ({ translator, doSetAvailability }) => {
  const pendingCouple = translator.canTranslate
    .filter((couple) => couple.status === "PENDING")
    .map((couple) => <li key={couple.couple}> {couple.couple}</li>);

  return (
    <tr className="text-center">
      <td className="text-left">
        <AccountLink account={translator} />
      </td>
      <td className="align-middle">
        {translator.online ? (
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green" }} />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        )}
      </td>
      <td className="align-middle">
        {translator.pushTokens.length ? (
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green" }} />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        )}
      </td>
      <td className="align-middle">
        <Toggle onChange={doSetAvailability} checked={translator.available} />
      </td>
      <td className="align-middle">
        {pendingCouple.length > 0 ? (
          <ul className="list-unstyled">{pendingCouple}</ul>
        ) : (
          <FormattedMessage id="Admin.None" />
        )}
      </td>
    </tr>
  );
};

TranslatorDetails.propTypes = {
  translator: AccountPropType.isRequired,
  doSetAvailability: PropTypes.func.isRequired,
};

const actionsToProps = (dispatch, ownProps) => ({
  doSetAvailability: (e) => {
    dispatch(
      setAvailability({
        id: ownProps.translator._id,
        available: e.target.checked,
      })
    );
  },
});

export default connect(null, actionsToProps)(TranslatorDetails);
