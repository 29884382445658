import { isEqual, uniqWith } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getProfile } from '../../account/selectors';
import { settings } from '../../selectors';
import ActionLink from '../ActionLink';
import ButtonGroupField from './ButtonGroupField';
import style from './LanguagePicker.module.scss';
import SelectField from './SelectField';

const requiredLanguage = (value) =>
  value ? undefined : 'Order.Source.Warning';
const required = (value) => (value ? undefined : 'Order.Text.Error');

const sourceOptions = ({
  availableLanguagesCouples,
  webSiteLanguage,
  languagesList,
}) => {
  const table = availableLanguagesCouples
    .filter(
      (lang) => lang.supportedDestinations && lang.supportedDestinations.length
    )
    .map((lang) => ({
      value: lang.iso6391Name,
      obj: languagesList[webSiteLanguage].filter(
        (l) => l.key === lang.iso6391Name
      ),
    }))
    .map((lang) => ({
      value: lang.value,
      text: lang.obj[0].name,
      order: lang.obj[0].order,
    }))
    .sort((a, b) => (a.order < b.order ? -1 : 1));
  return uniqWith(table, isEqual);
};

const destinationOptions = ({
  selectedLanguage,
  availableLanguagesCouples,
  languagesList,
  webSiteLanguage,
}) => {
  let supportedDestinations = [];
  if (selectedLanguage) {
    let table = availableLanguagesCouples
      .filter((lang) => lang.iso6391Name === selectedLanguage)
      .map((lang) => ({
        value: lang.supportedDestinations,
        obj: languagesList[webSiteLanguage].filter(
          (l) => l.key === lang.supportedDestinations
        ),
      }))
      .map((lang) => ({
        value: lang.value,
        text: lang.obj[0].name,
        order: lang.obj[0].order,
      }))
      .sort((a, b) => (a.order < b.order ? -1 : 1));
    return uniqWith(table, isEqual);
  }
  return supportedDestinations;
};

const LanguagePicker = ({
  availableLanguagesCouples,
  languagesList,
  webSiteLanguage,
  selectedLanguage,
}) => {
  const [changeLanguage, setChangeLanguage] = useState(!selectedLanguage);
  const destinations = destinationOptions({
    selectedLanguage,
    availableLanguagesCouples,
    languagesList,
    webSiteLanguage,
  });

  return (
    <div className="section-lang">
      {changeLanguage ? (
        <Field
          component={SelectField}
          name="selectedLanguage"
          label="Language.Source"
          options={sourceOptions({
            availableLanguagesCouples,
            webSiteLanguage,
            languagesList,
          })}
          validate={requiredLanguage}
          showErrorBeforeTouched
        />
      ) : (
        <div className={style.SourceLanguageDetection}>
          <FormattedMessage
            id="Language.Detection"
            values={{
              language: (
                <FormattedMessage id={`Language.${selectedLanguage}`} />
              ),
              click: (
                <ActionLink onClick={() => setChangeLanguage(true)}>
                  <FormattedMessage id="ClickHere" />
                </ActionLink>
              ),
            }}
          />
        </div>
      )}

      {!!destinations.length &&
        (destinations.length > 0 && (
          <Field
            component={ButtonGroupField}
            name="destinationLanguage"
            label="Language.Destination"
            options={destinations}
            showPlaceholder={false}
            validate={required}
          />
        ))}
    </div>
  );
};

LanguagePicker.propTypes = {
  availableLanguagesCouples: PropTypes.arrayOf(
    PropTypes.shape({
      iso6391Name: PropTypes.string.isRequired,
      supportedDestinations: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedLanguage: PropTypes.string,
};

LanguagePicker.defaultProps = {
  selectedLanguage: null,
};

const mapStateToProps = (state) => ({
  availableLanguagesCouples: settings(state).availableLanguagesCouples,
  webSiteLanguage: getProfile(state) ? getProfile(state).lang : 'fr',
  languagesList: settings(state).languagesList,
});

export default connect(mapStateToProps)(LanguagePicker);
